import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import learn from '../../Assets/Learn button.png'
import play from '../../Assets/play button.png'
import program from '../../Assets/program button.png'
import CryptoJS from 'crypto-js'
import renderImage from '../../source/importImg'
import SMSlider from '../ReusableComponents/SelectModeSlider/SMSlider'
import SelectionStyle from './Selection.module.css'
import cacheAssets from '../../utils/cacheAssets'
import renderPrgImage from '../../source/programImg'
import packageData from '../../../package.json'
import { ColorRing } from 'react-loader-spinner'
import {
    PCVersionCode,
    sendAndWaitForAck,
    sendBytes,
    utf16ToChar,
} from '../ReusableComponents/ByteTransfer'
import DialogModal from '../ReusableComponents/DialogModal/DialogModal'
import packageJson from '../../../package.json'
import { compareVersion } from '../../utils/verionControl'
import getVersion from '../ReusableComponents/ByteTransfer/getVersion'
import MainHeader from '../ReusableComponents/Header/MainHeader'
import { width } from '../../helpers/Sizes'
import { ESPLoaderPcv1 } from '../FirmwareJSPCv1/ESPLoaderPCv1'
import { TransportPCv1 } from '../FirmwareJSPCv1/webserial'
import { webSerialConnect, workerConnect } from '../../redux/actions'
// import { getFlashFiles,handleUpdate } from './FirmwareUpdate'
import { deviceLinks, fetchVersion, getFlashFilesPCv1 } from './FlashFiles'
import { openSerialReadComPort } from '../WebSerialConnection/SerialUSBConnect'
import { ESPLoader } from '../FirmwareJS/ESPLoader'
import { Transport } from '../FirmwareJS/webserial'
var device
let deviceData = {
    name: '',
    fileLink: '',
    flashFileData: '',
    version: '',
    flashFile: '',
    address: '',
}
const baudrate = 460800
var intervalID
function Selection(props) {
    let history = useHistory()
    const version = sessionStorage.getItem('deviceVersion')
    const [versionDetails, setVersionDetails] = useState({
        deviceVersion: '',
        latestVersion: '',
    })
    const [screenConsole, setScreenConsole] = useState('')
    const [uploading, setUploading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    const [isDisabled, setIsDisabled] = useState(false)
    const [showInstructions, setShowInstructions] = useState(false)
    const [show_tost_text, setShow_tost_text] = useState({
        isShow: false,
        text: '',
    })
    // const [isShow_comingSoon_text, setIsShow_comingSoon_text] = useState(false)
    const [selectedDevice, setSelectedDevice] = useState({
        name: '',
        fileLink: '',
        flashFileData: '',
        version: '',
        flashFile: '',
        address: '',
    })
    const [showDialogModal, setShowDialogModal] = useState({
        show: false,
        link: '',
        text1: '',
        text2: '',
        text3: '',
    })
    const [slideDirection, setSlideDirection] = useState(
        sessionStorage.getItem('slideDirection')
    )
    // const [selectedOptionDevice, setSelectedOptionDevice] = useState({
    //     value: 'PeeCeeB',
    //     label: 'PeeCee B',
    // })
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const deviceVersion = sessionStorage.getItem('deviceVersion')

    const gobackUrl = () => {
        history.push('/deviceSelection')
    }
    const advanceSettingUrl = () => {
        if (connectedDevice == 'Ace' && version[0] == '0') {
            history.push('/advanceSettingv0.1')
        } else if (connectedDevice == 'Humanoid') {
            history.push('/advanceSettingZingv0.1')
        } else {
            history.push('/advanceSettingv1.0')
        }
    }

    const [isHelp, setHelp] = useState(false)

    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    //set the dialogModel for microPython firmware present
    const handelShowDialogModel = (link) => {
        setShowDialogModal({
            show: true,
            link: link, //blockBaseLink[blockBaseLinkKey],
            text1: `This feature is not supported for the Micropython firmware.`,
            text2: `If you want to experience the full capabilities of this feature, please upload the latest device specific Plode firmware from `,
            text3: 'Do you still wish to continue?',
        })
    }

    const playBtn = () => {
        const microPython = sessionStorage.getItem('microPython')
        if (props.isConnecting) return

        if (['Klaw', 'Toki'].includes(connectedDevice)) {
            setShow_tost_text({
                isShow: true,
                text: ' The feature is not currently available for this device.Coming soon...',
            })
            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                1500
            )
            return
        }

        if (microPython == 'true') {
            handelShowDialogModel('/introduction')
            return
        }
        history.push('/introduction')
        sessionStorage.removeItem('slideDirection')
    }

    async function writePort(data) {
        await sendBytes(data, props.webSerial.port)
    }

    const programBtn = () => {
        if (props.isConnecting) return
        localStorage.setItem('programMode', 'program')
        sessionStorage.removeItem('slideDirection')
        history.push('/code')
    }
    const learnBtn = () => {
        if (props.isConnecting) return
        window.location.href =
            'https://www.tinkerbunker.com/courses/play-computer-robotics/'
        // history.push('/Learn')
    }

    const appModeBtn = () => {
        const microPython = sessionStorage.getItem('microPython')
        if (props.isConnecting) return

        if (microPython == 'true') {
            handelShowDialogModel('/appMode/selection')
            return
        }
        history.push('/appMode/selection')
        sessionStorage.removeItem('slideDirection')
    }
    const dataBtn = () => {
        const microPython = sessionStorage.getItem('microPython')
        if (props.isConnecting || ['Klaw', 'Toki'].includes(connectedDevice)) {
            setShow_tost_text({
                isShow: true,
                text: ' The feature is not currently available for this device.Coming soon...',
            })
            setTimeout(
                () =>
                    setShow_tost_text({
                        isShow: false,
                        text: '',
                    }),
                2000
            )
            return
        }

        if (microPython == 'true') {
            handelShowDialogModel('/graphSensor')
            return
        }
        history.push('/graphSensor')
        sessionStorage.setItem('GraphData', true)
        sessionStorage.removeItem('slideDirection')
    }

    const [showFeedback, setShowFeedback] = useState(false)
    const [feedbackMsg, setFeedbackMsg] = useState('All good!')

    // console.log('deviceVersion', deviceVersion)
    //////////////////////////////////////////////////////////////////////////////
    ////////////////////////////// Version Validation ////////////////////////////
    useEffect(async () => {
        const connectedDevice = sessionStorage.getItem('connectedDevice')
        // if (sessionStorage.getItem('zingVersion') == undefined)
        //     sessionStorage.setItem('zingVersion', '0.1.10')
        if (!props.webSerial.isConnected) return
        const verPopUpCount = sessionStorage.getItem('verPopUpCount')

        if (verPopUpCount == 1) return

        if (connectedDevice == 'Humanoid') {
            let latest

            if (
                sessionStorage.getItem('deviceVersion')?.startsWith('1') ||
                sessionStorage.getItem('deviceVersion')?.startsWith('2')
            ) {
                setScreenConsole(`Zing${deviceVersion} connected`)
                let { latestVersion } = await fetchVersion(
                    'https://embedded.plode.org/version-list',
                    'Zing',
                    sessionStorage.getItem('deviceVersion')?.startsWith('1')
                        ? '1.0'
                        : '2.0'
                )
                latest = latestVersion
                setVersionDetails({
                    deviceVersion: deviceVersion,
                    latestVersion: latest,
                })
                console.log('check', latest, deviceVersion, versionDetails)
            } else {
                setScreenConsole(`Zing${deviceVersion} connected`)
                let { latestVersion } = await fetchVersion(
                    'https://embedded.plode.org/version-list',
                    'Zing',
                    '0.1'
                )
                latest = latestVersion
                setVersionDetails({
                    deviceVersion: deviceVersion,
                    latestVersion: latest,
                })
            }

            if (compareVersion(deviceVersion, latest) == 1)
                setFeedbackMsg('UPDATE_ZING')
            else return

            setShowInstructions(true)
            setShowFeedback(true)
            console.log('feedback', feedbackMsg)
            sessionStorage.setItem('verPopUpCount', 1)
            return
            // }, 1000)
        } else if (connectedDevice == 'Ace') {
            if (!deviceVersion) return

            // For PeeCee 1.0 firmware check

            let { latestVersion, location } = await fetchVersion(
                'https://embedded.plode.org/version-list',
                'Playcomputer',
                deviceVersion[0] == '1' ? '1.0' : '0.1'
            )
            // For PeeCee 1.0 firmware check
            // else if (sessionStorage.getItem('deviceVersion').includes('0.1'))
            //     const {version} = await fetchVersion(
            //         'https://embedded.plode.org/devices','Playcomputer','0.1'
            //     )
            if (!location) {
                setVersionDetails({
                    deviceVersion: deviceVersion,
                    latestVersion: latestVersion,
                })
                return
            }

            console.log('latest', latestVersion, Object.keys(location)[0])
            let deviceName = Object.keys(location)[0]
            deviceData = {
                name: deviceVersion[0] == '1' ? 'PeeCeeB' : 'PeeCeeA',
                fileLink: `https://embedded.plode.org/bins/production/${deviceName}/PC${latestVersion}/${deviceName}.bin`,
                version: latestVersion,
                flashFileData: [],
                flashFile: getFlashFilesPCv1,
                address: location[deviceName],
            }

            setSelectedDevice(deviceData)

            setScreenConsole(`PC${deviceVersion} connected`)
            // latestVersion = latestVersion.replace(/[a-zA-Z]/g, '')
            setVersionDetails({
                deviceVersion: deviceVersion,
                latestVersion: latestVersion,
            })

            console.log(
                'pc version',
                deviceVersion,
                latestVersion,
                deviceVersion < latestVersion
            )
            if (
                compareVersion(
                    deviceVersion.replace(/[a-zA-Z]/g, ''),
                    latestVersion.replace(/[a-zA-Z]/g, '')
                ) == 1
            ) {
                setFeedbackMsg('UPDATE_PC')
                if (deviceVersion.includes('0.1')) setShowInstructions(true)
                setShowFeedback(true)
                sessionStorage.setItem('verPopUpCount', 1)
            } else {
                setFeedbackMsg('')
                setShowFeedback(false)
            }

            return
        }
    }, [])

    // useEffect(() => {
    //     sessionStorage.removeItem('verPopUpCount')
    // }, [props.webSerial.isConnected])

    //if no device is connected
    useEffect(() => {
        if (sessionStorage.getItem('connectedDevice') == 'Unknown')
            history.push('/deviceSelect')
        else if (!connectedDevice && !props.webSerial.isConnected) {
            history.push('/deviceSelection')
        }
    }, [])
    //////////////////////////////////////////////////////////////////////////////
    // console.log('feedbackMsg', feedbackMsg)
    useEffect(() => {
        let assetsToBeCached = [
            renderImage('PlayCard_Svg'),
            renderImage('RemoteCard_Svg'),
            renderImage('SpeechCard_Svg'),
            renderImage('MusicCard_Svg'),
            renderImage('CameraCard_Svg'),
            renderPrgImage('projectbased'),
            renderPrgImage('flowchartbasedgroupbutton'),
            renderPrgImage('blockbased'),
            renderPrgImage('Cgroupbutton'),
            renderPrgImage('pythoncodingbutton'),

            // GraphData
            renderPrgImage('Activemic'),
            renderPrgImage('mic'),
            renderPrgImage('Activetemp'),
            renderPrgImage('temp'),
            renderPrgImage('Activetemp'),
            renderPrgImage('temp'),
            renderPrgImage('Activebatt'),
            renderPrgImage('batt'),
            renderPrgImage('ActivetouchZero'),
            renderPrgImage('touchZero'),
            renderPrgImage('ActivetouchOne'),
            renderPrgImage('touchOne'),
            renderPrgImage('ActivetouchTwo'),
            renderPrgImage('touchTwo'),
            renderPrgImage('ActivetouchThree'),
            renderPrgImage('touchThree'),
            renderPrgImage('Activetouchdistance'),
            renderPrgImage('touchdistance'),
            renderPrgImage('Activegesture'),
            renderPrgImage('gesture'),
            renderPrgImage('Activecolor'),
            renderPrgImage('color'),
            renderPrgImage('Activelight'),
            renderPrgImage('light'),
            renderPrgImage('ActiveacceX'),
            renderPrgImage('acceX'),
            renderPrgImage('ActiveacceY'),
            renderPrgImage('acceY'),
            renderPrgImage('ActiveacceZ'),
            renderPrgImage('acceZ'),
            renderPrgImage('PortPopUp'),
            renderPrgImage('orangeBtn'),
            renderPrgImage('whiteBtn'),
            renderPrgImage('Colon'),
            renderPrgImage('graphTimeH'),
            renderPrgImage('timepopUp'),
            renderPrgImage('LineGraph'),
            renderPrgImage('BarGraph'),
            renderPrgImage('lineGraphH'),
            renderPrgImage('barGraphH'),
            renderPrgImage('MeterGraph'),
            renderPrgImage('smallleftComponentBar'),
            renderPrgImage('CloseGraphSideBar'),
            renderPrgImage('play'),
            renderPrgImage('pause'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    const handleUpdate = async (cmd) => {
        if (cmd === 'ok') {
            setShowInstructions(false)
            return
        }
        if (cmd === 'update') {
            if (
                sessionStorage.getItem('deviceVersion').includes('0.1') &&
                sessionStorage.getItem('connectedDevice') == 'Ace'
            ) {
                window.open('http://firmware.plode.org/')
                return
            }
            //getting flash files

            setUploading(true)
            setIsDisabled(true)

            let deviceSelected = deviceLinks.find((device) => {
                if (connectedDevice == 'Ace')
                    return sessionStorage
                        .getItem('deviceVersion')
                        .includes(device.version)
                else
                    return (
                        device.version ==
                        sessionStorage.getItem('deviceVersion')
                    )
            })
            if (connectedDevice == 'Ace') deviceSelected = deviceData
            let { name, fileLink, flashFile, flashFileData, address } =
                deviceSelected

            if (!props.webSerial.isConnected) {
                //configure for zing not done
                try {
                    device = await navigator.serial.requestPort({
                        filters: [
                            { usbVendorId: 0x1a86, usbProductId: 0x7523 },
                            { usbVendorId: 0x303a, usbProductId: 0x1001 },
                            { usbVendorId: 0x1a86, usbProductId: 0x8040 },
                        ],
                    })

                    if (device == undefined) return
                    await props.webSerialConnect({
                        isConnected: true,
                        port: device,
                    })

                    // await new Promise((resolve) => setTimeout(resolve, 3000))
                } catch (e) {
                    setScreenConsole(`Error: ${e.message}`)
                    console.log('error', e)
                }
            } else {
                //close the ports already opened
                if (name == 'PeeCeeB') props.worker.terminate()
                else {
                    // console.log(props.webSerial.port)
                    // debugger
                    await props.webSerial.port.close()

                    // console.log(props.webSerial.port)
                    // debugger
                }
                if (intervalID) clearInterval(intervalID)
                setScreenConsole(`ports closed successfully`)

                //open ports again
                var devices = await navigator.serial.getPorts({
                    filters: [
                        { usbVendorId: 0x1a86, usbProductId: 0x7523 },
                        { usbVendorId: 0x303a, usbProductId: 0x1001 },
                        { usbVendorId: 0x1a86, usbProductId: 0x8040 },
                    ],
                })
                device = devices[0]
                // console.log('devicesss', device, props)
                if (!device) return

                await props.webSerialConnect({
                    port: device,
                    isConnected: true,
                })
            }
            console.log(deviceSelected)
            let transport
            let esploader
            setScreenConsole('Trying to connect ...')
            if (name == 'PeeCeeB') {
                transport = new TransportPCv1(device)
                esploader = new ESPLoaderPcv1(transport, baudrate)
            } else {
                transport = new Transport(device)
                esploader = new ESPLoader(transport, baudrate)
            }

            console.log('Trying to connect ...')

            const chipId = await esploader.main_fn()
            setScreenConsole(`Device connected - ${chipId}`)
            console.log('esploader', esploader)

            await flashFile(fileLink, deviceData, address)
            console.log('device data', deviceData, flashFileData)
            await new Promise((resolve) => setTimeout(resolve, 3000))
            // Erasing Flash
            if (
                !deviceVersion?.startsWith('1') ||
                sessionStorage.getItem('connectedDevice') == 'humanoid'
            ) {
                console.log('Erasing chip (this might take a while) ... ')
                setScreenConsole('Erasing chip (this might take a while) ... ')
                try {
                    await esploader.erase_flash()
                    setScreenConsole('Chip erase completed successfully')
                } catch (e) {
                    console.log(e, 'flash error')
                    setScreenConsole('error in erase')
                }
                await new Promise((resolve) => setTimeout(resolve, 3000))
                console.log('Chip erase completed successfully')
            }

            try {
                console.log(deviceData.flashFileData)
                // writing flash files
                await esploader.write_flash({
                    fileArray: deviceData.flashFileData,
                    flash_size: 'keep',
                    reportProgress: (fileIndex, written, total) => {
                        console.log('bytes send', written, total)
                        let progress = Math.round((written / total) * 100)
                        console.log(`Updating firmware - ${progress}%`)
                        setUploadProgress(progress)
                        setScreenConsole(`Updating firmware - ${progress}%`)
                    },
                    calculateMD5Hash: (image) =>
                        CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
                })
                if (name == 'PeeCeeB') await esploader.hard_reset()

                console.log('Device flashed successfully', device)
                setScreenConsole('Device flashed successfully')
                device.close()

                console.log('device', device)

                // opening ports for Plode
                if (name == 'PeeCeeB') {
                    const worker = new Worker('/serialWorker.js', {
                        type: 'module',
                    })
                    // let version = deviceData.version.replace(/[a-zA-Z]/g, '')
                    console.log(deviceData.version)
                    sessionStorage.setItem('deviceVersion', deviceData.version)
                    await props.workerConnect(worker)

                    window.location.reload()
                    worker.postMessage({
                        type: 'connected',
                    })
                } else {
                    let res = await openSerialReadComPort(
                        props.webSerial.port,
                        props.worker
                    )
                    if (res !== undefined && res.isPortOpen)
                        await props.webSerialConnect({
                            port: res.port,
                            isConnected: true,
                        })
                    sessionStorage.setItem(
                        'deviceVersion',
                        device.version.replace(/[a-zA-Z]/g, '')
                    )
                    window.location.reload()
                }
                sessionStorage.setItem('uploading', 'completed')
            } catch (e) {
                console.log(`Error: ${e.message}`)
                sessionStorage.setItem('uploading', 'midway')
                setScreenConsole(`Error: ${e.message}`)
            } finally {
                // setDisableUpdate(false)
            }

            setIsDisabled(false)
            setUploading(false)
            return
        }
    }
    const handleDialogModalClose = () => {
        if (sessionStorage.getItem('uploading') == 'midway')
            history.push('/deviceSelection')
        else {
        }
        setShowFeedback(false)
    }
    let datas = true
    let style = datas ? SelectionStyle.Play_Body : SelectionStyle.Play_Body0
    // Example usage:

    function getTitle(connectedDevice, deviceVersion) {
        if (connectedDevice === 'Humanoid') {
            return deviceVersion === '0.1.10' ? 'Zing Alpha' : 'Zing Beta'
        } else if (connectedDevice === 'Ace') {
            return deviceVersion[0] === '0' ? 'PeeCee Alpha' : 'PeeCee Beta'
        } else if (connectedDevice === 'Roboki') {
            return 'Roboki'
        } else if (connectedDevice === 'Hexapod') {
            return 'Crawl-e'
        } else if (connectedDevice === 'Klaw') {
            return 'Klaw-b'
        } else if (connectedDevice) {
            return connectedDevice
        } else {
            // Handle cases where connectedDevice is neither 'Humanoid' nor 'Ace'
            return 'PeeCee Beta'
        }
    }

    //in open the file, dialog model shows option generat
    const dialogModelOption = () => {
        let optionArr = []

        optionArr.push({
            text: 'YES',
            do: () => {
                sessionStorage.removeItem('slideDirection')
                if (
                    [
                        '/introduction',
                        '/graphSensor',
                        '/appMode/selection',
                    ].includes(showDialogModal.link)
                ) {
                    history.push(showDialogModal.link)
                } else {
                    window.location.assign(showDialogModal.link)
                }
                if (showDialogModal.link == '/graphSensor') {
                    sessionStorage.setItem('GraphData', true)
                }
            },
        })

        optionArr.push({
            text: 'NO',
            do: () => {
                setShowDialogModal({
                    show: false,
                    link: '',
                    text1: '',
                    text2: '',
                    text3: '',
                })
            },
        })
        return optionArr
    }

    return (
        <>
            <DialogModal
                selectionShow={showFeedback}
                text={
                    (feedbackMsg === 'UPDATE_PC' ||
                        feedbackMsg === 'UPDATE_ZING') &&
                    (showInstructions ? (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <p
                                style={{
                                    transform: 'translateY(20%)',
                                    fontSize: '2vh',
                                }}
                            >
                                Firmware needs to update, please disconnect your
                                device and put it on BOOT mode and reconnect
                                again
                            </p>
                        </div>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <p
                                style={{
                                    // transform: 'translateY(-27%)',
                                    fontSize: '2vh',
                                }}
                            >
                                There is a new version of firmware available for
                                Play Computer. Kindly update the firmware
                            </p>
                            <p
                                style={{
                                    transform: 'translateY(-27%)',
                                    fontSize: '2vh',
                                    width: '100%',
                                }}
                            >
                                <span style={{ marginRight: '4%' }}>
                                    deviceVersion :{' '}
                                    {versionDetails.deviceVersion}
                                </span>
                                <span>
                                    LatestVersion:{' '}
                                    {versionDetails.latestVersion}
                                </span>
                            </p>

                            <div
                                style={{
                                    width: '100%',
                                    height: '90px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    position: 'relative',
                                }}
                            >
                                <textarea
                                    className={SelectionStyle.console_log}
                                    name="console"
                                    value={screenConsole}
                                    disabled
                                ></textarea>
                            </div>
                        </div>
                    ))
                }
                showCloseBtn={!uploading}
                handleDialogModalClose={handleDialogModalClose}
                optionsToSelect={[
                    {
                        text: showInstructions ? 'OK' : 'UPDATE NOW',
                        disabled: isDisabled,
                        do: showInstructions
                            ? () => handleUpdate('ok')
                            : () => handleUpdate('update'),
                        //disable method
                    },
                ]}
            />

            <DialogModal
                show={showDialogModal.show}
                text={
                    <>
                        <p>{showDialogModal.text1}</p>
                        <p>
                            {showDialogModal.text2}
                            <a
                                href="https://firmware.plode.org"
                                target="_blank"
                            >
                                {'here.'}
                            </a>
                        </p>
                        <p>{showDialogModal.text3}</p>
                    </>
                }
                showCloseBtn={false}
                optionsToSelect={dialogModelOption()}
            />

            <div className={SelectionStyle.Main_Select}>
                <div className={SelectionStyle.Select_Headers}>
                    <MainHeader
                        title={getTitle(connectedDevice, deviceVersion)}
                        titlePosition="left"
                        help_btnPosition="right"
                        helper={<SMSlider />}
                        isGoBack={false}
                        goBack={() => {
                            if (!props.webSerial.isConnected) {
                                setSlideDirection(true)
                                setShowFeedback(false)
                                sessionStorage.setItem('slideDirection', true)
                                console.log('check')
                                history.push('/kitSelection')
                            }
                        }}
                        showAccountSetting={true}
                    />
                </div>
                <div></div>

                <div
                    id="scrollContainer"
                    className={`${
                        slideDirection === 'true' ? 'slide-right' : 'slide-left'
                    } ${style}`}
                    onWheel={(event) => {
                        document.getElementById('scrollContainer').scrollLeft +=
                            event.deltaY
                    }}
                >
                    <div
                        id={SelectionStyle.play}
                        style={{
                            backgroundImage: `url(${renderPrgImage(
                                'playButton'
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            width: datas ? '54vh' : null,
                            height: datas ? '50vh' : null,
                            // opacity: props.isConnecting ? 0.3 : 1,
                            opacity:
                                props.isConnecting ||
                                ['Toki'].includes(connectedDevice)
                                    ? 0.5
                                    : 1,
                        }}
                    >
                        {' '}
                        <div
                            className={SelectionStyle.sub_body}
                            style={{
                                top: datas ? '44%' : '45%',
                                cursor: ['Toki'].includes(connectedDevice)
                                    ? 'default'
                                    : 'pointer',
                            }}
                            onClick={playBtn}
                        >
                            <div className={SelectionStyle.text}>
                                <p>Play</p>
                            </div>
                        </div>
                    </div>

                    <div
                        id={SelectionStyle.learn}
                        style={{
                            backgroundImage: `url(${renderPrgImage(
                                'learnButton'
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            width: datas ? '54vh' : null,
                            opacity: props.isConnecting ? 0.3 : 1,
                            height: datas ? '50vh' : null,
                        }}
                    >
                        <div
                            className={SelectionStyle.sub_body}
                            style={{
                                top: datas ? '44%' : '45%',
                            }}
                            onClick={learnBtn}
                        >
                            <div className={SelectionStyle.text}>
                                <p>Learn</p>
                            </div>
                        </div>
                    </div>

                    <div
                        id={SelectionStyle.data}
                        style={{
                            backgroundImage: `url(${renderPrgImage(
                                'dataButton'
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            width: datas ? '54vh' : null,
                            height: datas ? '50vh' : null,
                            opacity:
                                props.isConnecting ||
                                ['Klaw', 'Toki'].includes(connectedDevice)
                                    ? 0.3
                                    : 1,
                        }}
                    >
                        <div
                            className={SelectionStyle.sub_body}
                            style={{
                                top: datas ? '44%' : '45%',
                                cursor: ['Klaw'].includes(connectedDevice)
                                    ? 'default'
                                    : 'pointer',
                            }}
                            onClick={dataBtn}
                        >
                            <div className={SelectionStyle.text}>
                                <p>Data</p>
                            </div>
                        </div>
                    </div>

                    <div
                        id={SelectionStyle.code}
                        style={{
                            backgroundImage: `url(${renderPrgImage(
                                'codeButton'
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',

                            width: datas ? '54vh' : null,
                            opacity: props.isConnecting ? 0.3 : 1,
                            height: datas ? '50vh' : null,
                        }}
                    >
                        <div
                            className={SelectionStyle.sub_body}
                            style={{
                                top: datas ? '44%' : '45%',
                            }}
                            onClick={programBtn}
                        >
                            {' '}
                            <div className={SelectionStyle.text}>
                                <p>Code</p>
                            </div>
                        </div>
                    </div>

                    <div
                        id={SelectionStyle.app}
                        style={{
                            backgroundImage: `url(${renderPrgImage(
                                'appButton'
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',

                            width: datas ? '54vh' : null,

                            height: datas ? '50vh' : null,
                            // opacity:
                            //     props.isConnecting ||
                            //     ['Hexapod'].includes(connectedDevice)
                            //         ? 0.3
                            //         : 1,
                        }}
                    >
                        <div
                            className={SelectionStyle.sub_body}
                            style={{
                                top: datas ? '44%' : '45%',
                                // cursor: ['Hexapod'].includes(connectedDevice)
                                //     ? 'default'
                                //     : 'pointer',
                            }}
                            onClick={appModeBtn}
                        >
                            <div className={SelectionStyle.text}>
                                <p>App Mode</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div></div>
            </div>

            <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                <ColorRing
                    visible={props.isConnecting}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={[
                        '#e15b64',
                        '#f47e60',
                        '#f8b26a',
                        '#abbd81',
                        '#849b87',
                    ]}
                />
            </div>

            {show_tost_text.isShow && (
                <p className="comingSoon_text">{show_tost_text.text}</p>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        webSerial: state.webSerial,
        worker: state.worker,
        isConnecting: state.isConnecting,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
        workerConnect: (data) => {
            dispatch(workerConnect(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Selection)
