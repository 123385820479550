import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router'
import renderImage from '../../../../../../../source/importImg'
import '../KlawArmPlay/KlawArm.css'
import { sendBytes } from '../../../../../../../Components/ReusableComponents/ByteTransfer/byteTransfer'
import Slider from '../../../../../../ReusableComponents/Slider/Slider'
import VerticalSlider from './Verticalslider'
import HorizontalSlider from './HorizontalSlider'

let initalRender = true
let endpointOut_ = 2
let loop = false
let servoArrayIndex = {
    B_R: 2,
    SR1_UP: 3,
    SR2_UP: 4,
    SR3_UP: 5,
    pick: 6,
    B_L: 2,
    SR1_DN: 3,
    SR2_DN: 4,
    SR3_DN: 5,
    drop: 6,
}
// let defaultServoValue = [
//     'R'.charCodeAt(0), //0
//     'B'.charCodeAt(0), //1
//     0, //2  base motor
//     0, //3  servo 1
//     0, //4  servo 2
//     0, //5  servo 3
//     0, //6  griper motor
//     0, //7   servo 6
//     0, //8  dc motor 1
//     0, //9  dc motor 2
// ]
let defaultServoValue = [
    'R'.charCodeAt(0),
    'B'.charCodeAt(0),
    '90',
    '90',
    '90', //300 - 150,
    '90',
    '36',
    '0',
    '0',
    '0',
]
let defaultServoValue1 = [
    'R'.charCodeAt(0),
    'B'.charCodeAt(0),
    90,
    90,
    90,
    90,
    90,
    0,
    0,
    0,
]

function KlawArmPlay(props) {
    const history = useHistory()
    const [continuous, setContinuous] = useState(false)
    const [id, setID] = useState(null)
    const [isEnabled, setIsEnabled] = useState(true)
    const [servoValues, setServoValues] = useState(defaultServoValue)
    const [servoValues1, setServoValues1] = useState(defaultServoValue1)
    const gobackUrl = () => {
        history.goBack()
    }

    // const [servoValues, setFreq] = useState(0)

    // useEffect(() => {
    //     if (continuous) {
    //         const time = setInterval(() => {
    //             actionBytes(id)
    //         }, 100)
    //         sessionStorage.setItem('readSetIntervalId', JSON.stringify(time))
    //     } else {
    //         clearInterval(
    //             JSON.parse(sessionStorage.getItem('readSetIntervalId'))
    //         )
    //     }
    // }, [continuous])

    useEffect(() => {
        const messageHandler = (e) => {
            if (e.data.type === 'read') {
                let combinedResult = e.data.value
                if (
                    combinedResult.includes('Rdone') ||
                    combinedResult.includes('RDONE')
                ) {
                    console.log('write check 3', combinedResult, props)
                    // return combinedResult
                    setIsEnabled(true)
                    // setActionState({ ...ORIGINAL_ACTION_STATE_KLAW })
                    // clearTimeout(waiting)
                }
            }
        }
        props.worker.addEventListener('message', messageHandler)

        // return worker.removeEventListener('message', messageHandler)
    }, [])
    const actionBytes = (id) => {
        const bytes = Array(8).fill(0)
        // bytes.unshift('J'.charCodeAt(), 'S'.charCodeAt())
        bytes.unshift('R'.charCodeAt(), 'B'.charCodeAt())
        switch (id) {
            case 'B_R': {
                bytes[2] = 'd'.charCodeAt()
                break
            }
            case 'SR1_UP': {
                bytes[3] = 'u'.charCodeAt()
                break
            }
            //in klaw  servo 2 is connect opposite in hardware side, so here the send bytes opposite,
            //when click on up button send d, and click on down send u
            case 'SR2_UP': {
                bytes[4] = 'd'.charCodeAt()
                break
            }
            case 'SR3_UP': {
                bytes[5] = 'u'.charCodeAt()
                break
            }
            // case 'GB_R': {
            //     bytes[6] = 'R'.charCodeAt()
            //     break
            // }
            case 'pick': {
                bytes[6] = 'u'.charCodeAt() //u
                break
            }
            case 'B_L': {
                bytes[2] = 'u'.charCodeAt()
                break
            }
            case 'SR1_DN': {
                bytes[3] = 'd'.charCodeAt()
                break
            }
            case 'SR2_DN': {
                bytes[4] = 'u'.charCodeAt()
                break
            }
            case 'SR3_DN': {
                bytes[5] = 'd'.charCodeAt()
                break
            }
            // case 'GB_L': {
            //     bytes[6] = 'L'.charCodeAt()
            //     break
            // }
            case 'drop': {
                bytes[6] = 'd'.charCodeAt() //d
                break
            }
        }
        writePort(bytes)
    }

    //all servo value convert between 0 to 180
    const convertByte = (byte) => {
        let newByte = []
        for (let i = 0; i < 10; i++) {
            if (i == 0 || i == 1) {
                newByte.push(byte[i])
            } else {
                newByte.push(byte[i])
            }
        }
        return newByte
    }

    const onMouseUpHandel = () => {
        writePort(convertByte(servoValues))
    }
    const handelSliderChange = (servo, value) => {
        switch (servo) {
            case 'base': {
                let newServoValues = [...servoValues]
                // let newServoValues1 = [...servoValues1]
                newServoValues[2] = value
                // newServoValues1[2] = Math.round(value * 0.6)
                // writePort(newServoValues1)
                setServoValues(newServoValues)
                // setServoValues1(newServoValues1)
                break
            }

            case 'servo1': {
                let newServoValues = [...servoValues]
                newServoValues[3] = value
                // writePort(convertByte(newServoValues))

                // let newServoValues1 = [...servoValues1]
                // newServoValues1[3] = Math.round(value * 0.6)
                // writePort(newServoValues1)
                // setServoValues(newServoValues1)
                setServoValues(newServoValues)
                break
            }

            case 'servo2': {
                let newServoValues = [...servoValues]
                newServoValues[4] = value
                // writePort(convertByte(newServoValues))

                // let newServoValues1 = [...servoValues1]
                // newServoValues1[4] = Math.round(value * 0.6)
                // writePort(newServoValues1)
                // setServoValues(newServoValues1)
                setServoValues(newServoValues)
                break
            }

            case 'servo3': {
                let newServoValues = [...servoValues]
                newServoValues[5] = value
                // writePort(convertByte(newServoValues))

                // let newServoValues1 = [...servoValues1]
                // newServoValues1[5] = Math.round(value * 0.6)
                // writePort(newServoValues1)
                // setServoValues(newServoValues1)
                setServoValues(newServoValues)
                break
            }

            case 'grip': {
                let newServoValues = [...servoValues]
                newServoValues[6] = value
                // writePort(convertByte(newServoValues))

                // let newServoValues1 = [...servoValues1]
                // newServoValues1[6] = Math.round(value * 0.6)
                // writePort(newServoValues1)
                // setServoValues(newServoValues1)
                setServoValues(newServoValues)
                break
            }
        }
    }

    // useEffect()

    const generateBytesAddition = (index) => {
        setServoValues((prevServoValues) => {
            console.log('prev servo values:', prevServoValues)
            const newServoValues = [...prevServoValues]
            newServoValues[index] = 'u'.charCodeAt(0)
            if (index === 3) {
                newServoValues[4] = 0
                newServoValues[5] = 0
            } else if (index === 4) {
                newServoValues[3] = 0
                newServoValues[5] = 0
            } else if (index === 5) {
                newServoValues[3] = 0
                newServoValues[4] = 0
            }
            writePort(newServoValues)
            return newServoValues
        })
    }
    const generateBytesSubtraction = (index) => {
        setServoValues((prevServoValues) => {
            console.log('prev servo values:', prevServoValues)
            const newServoValues = [...prevServoValues]
            newServoValues[index] = 'd'.charCodeAt(0)

            // newServoValues[index] - 1 < 0 ? 0 : newServoValues[index] - 1
            if (index === 3) {
                newServoValues[4] = 0
                newServoValues[5] = 0
            } else if (index === 4) {
                newServoValues[3] = 0
                newServoValues[5] = 0
            } else if (index === 5) {
                newServoValues[3] = 0
                newServoValues[4] = 0
            }
            writePort(newServoValues)
            return newServoValues
        })
    }

    //Klaw action Start
    const klawActionStart = async (id) => {
        console.log('GGGGGGGG', id)
        let operation
        if (['B_R', 'SR1_UP', 'SR2_UP', 'SR3_UP', 'pick'].includes(id)) {
            operation = generateBytesAddition
            console.log('operation bytes:', operation)
        } else {
            operation = generateBytesSubtraction
        }
        let index = servoArrayIndex[id]
        operation(index)
        const sendingInterval = setInterval(() => {
            operation(index)
        }, 1000)
        sessionStorage.setItem(
            'readSetIntervalId',
            JSON.stringify(sendingInterval)
        )
    }
    const klawActionStop = (id) => {
        clearInterval(JSON.parse(sessionStorage.getItem('readSetIntervalId')))
    }

    const writePort = async (data) => {
        console.log(data)

        if (props.webSerial.isConnected && props.webSerial.port !== undefined) {
            console.log('write check 2', data)
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        }
    }

    return (
        <div>
            <div className="Arm_Body_Klaw">
                {/* Left  */}
                <div className="Arm_Left_Klaw">
                    <img src={renderImage('NewKlaw_3x')} />
                    <div className="label-grip">
                        <div className="grip"></div>
                        <p style={{ textAlign: 'left' }}>Grip</p>
                    </div>
                    <div className="label-servo3">
                        <div className="servo3"></div>
                        <p style={{ textAlign: 'right' }}>Servo3</p>
                    </div>
                    <div className="label-servo2">
                        <div className="servo2"></div>
                        <p style={{ textAlign: 'right' }}>Servo2</p>
                    </div>
                    <div className="label-servo1">
                        <div className="servo1"></div>
                        <p>Servo1</p>
                    </div>
                    <div className="label-base">
                        <div className="base"></div>
                        <p>Base</p>
                    </div>
                </div>

                {/* Main  */}
                {/* <div className="Arm_Main_Klaw">
                    <div className="Main_Img_Klaw">
                        <img
                            id="SR1_UP"
                            src={renderImage(
                                id === 'SR1_UP' ? 'upActive' : 'upInActive'
                            )}
                            onMouseDown={(event) => {
                                // klawActionStart('SR1_UP')
                                actionBytes('SR1_UP')
                                setID(event.target.id)
                                // setContinuous(true)
                            }}
                            onMouseUp={() => {
                                writePort(defaultServoValue)
                                setID(null)

                                // setContinuous(false)
                                // klawActionStop('SR1_UP')
                            }}
                        />
                    </div>
                    <div className="Main_Img_Klaw">
                        <img
                            id="SR2_UP"
                            src={renderImage(
                                id === 'SR2_UP' ? 'upActive' : 'upInActive'
                            )}
                            onMouseDown={(event) => {
                                actionBytes('SR2_UP')
                                setID(event.target.id)
                                // klawActionStart('SR2_UP')
                                // setContinuous(true)
                            }}
                            onMouseUp={() => {
                                writePort(defaultServoValue)
                                setID(null)
                                // klawActionStop('SR2_UP')
                                // setContinuous(false)
                            }}
                        />
                    </div>
                    <div className="Main_Img_Klaw">
                        <img
                            id="SR3_UP"
                            src={renderImage(
                                id === 'SR3_UP' ? 'upActive' : 'upInActive'
                            )}
                            onMouseDown={(event) => {
                                actionBytes('SR3_UP')
                                setID(event.target.id)
                                // klawActionStart('SR3_UP')
                                // setContinuous(true)
                            }}
                            onMouseUp={() => {
                                writePort(defaultServoValue)
                                setID(null)
                                // klawActionStop('SR3_UP')
                                // setContinuous(false)
                            }}
                        />
                    </div>
                    <div className="Main_Img_Txt">
                        <img
                            id="SR1_DN"
                            src={renderImage(
                                id === 'SR1_DN' ? 'downActive' : 'downInActive'
                            )}
                            onMouseDown={(event) => {
                                actionBytes('SR1_DN')
                                setID(event.target.id)
                                // klawActionStart('SR1_DN')

                                // setContinuous(true)
                            }}
                            onMouseUp={() => {
                                writePort(defaultServoValue)
                                setID(null)
                                // klawActionStop('SR1_DN')
                                // setContinuous(false)
                            }}
                        />
                        <p style={{ display: 'block', whiteSpace: 'nowrap' }}>
                            Servo 1
                        </p>
                    </div>
                    <div className="Main_Img_Txt">
                        <img
                            id="SR2_DN"
                            src={renderImage(
                                id === 'SR2_DN' ? 'downActive' : 'downInActive'
                            )}
                            onMouseDown={(event) => {
                                actionBytes('SR2_DN')
                                setID(event.target.id)
                                // klawActionStart('SR2_DN')
                                // setContinuous(true)
                            }}
                            onMouseUp={() => {
                                writePort(defaultServoValue)
                                setID(null)
                                // klawActionStop('SR2_DN')
                                // setContinuous(false)
                            }}
                        />
                        <p style={{ display: 'block', whiteSpace: 'nowrap' }}>
                            Servo 2
                        </p>
                    </div>
                    <div className="Main_Img_Txt">
                        <img
                            id="SR3_DN"
                            src={renderImage(
                                id === 'SR3_DN' ? 'downActive' : 'downInActive'
                            )}
                            onMouseDown={(event) => {
                                actionBytes('SR3_DN')
                                setID(event.target.id)
                                // klawActionStart('SR3_DN')
                                // setContinuous(true)
                            }}
                            onMouseUp={() => {
                                writePort(defaultServoValue)
                                setID(null)
                                // klawActionStop('SR3_DN')
                                // setContinuous(false)
                            }}
                        />
                        <p style={{ display: 'block', whiteSpace: 'nowrap' }}>
                            Servo 3
                        </p>
                    </div>
                </div> */}

                <div
                    style={{
                        position: 'relative',
                        top: '-5%',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: '50%',
                    }}
                >
                    <div
                        className="Arm_Main_Klaw"
                        // style={{
                        //     width: '100%',
                        //     border: '1px solid red',
                        //     display: 'flex',
                        //     justifyContent: 'center',
                        // }}
                    >
                        <div style={{}}>
                            <VerticalSlider
                                servoKiy="servo1"
                                value={servoValues[3]}
                                min={48}
                                max={108}
                                direction="up-down"
                                handelSliderChange={handelSliderChange}
                                onMouseUpHandel={onMouseUpHandel}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    marginTop: '20px',
                                }}
                            >
                                Servo 1
                            </p>
                        </div>
                        <div style={{}}>
                            <VerticalSlider
                                servoKiy="servo2"
                                value={servoValues[4]}
                                // min={30}
                                // max={168}
                                min={30} //here the servo motor is connect in oppsite direction
                                max={168}
                                // direction="up-down"
                                handelSliderChange={handelSliderChange}
                                onMouseUpHandel={onMouseUpHandel}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    marginTop: '20px',
                                }}
                            >
                                Servo 2
                            </p>
                        </div>
                        <div style={{}}>
                            <VerticalSlider
                                servoKiy="servo3"
                                value={servoValues[5]}
                                min={30}
                                max={150}
                                direction="up-down"
                                handelSliderChange={handelSliderChange}
                                onMouseUpHandel={onMouseUpHandel}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    whiteSpace: 'nowrap',
                                    marginTop: '20px',
                                }}
                            >
                                Servo 3
                            </p>
                        </div>
                    </div>

                    {/* Right  */}
                    <div className="Arm_Right_Main">
                        {/* <div className="Arm_Right_Klaw">
                        <div className="Main_Img_Klaw">
                            <img
                                id="pick"
                                src={renderImage('pick')}
                                onMouseDown={(event) => {
                                    actionBytes('pick')
                                    setID(event.target.id)
                                    // klawActionStart('pick')
                                    // setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    // setContinuous(false)
                                    writePort(defaultServoValue)
                                    setID(null)
                                    // klawActionStop('pick')
                                }}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    margin: 0,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Pick
                            </p>
                        </div>
                        <div className="Main_Img_Klaw">
                            <img
                                id="drop"
                                src={renderImage('drop')}
                                onMouseDown={(event) => {
                                    actionBytes('drop')
                                    setID(event.target.id)
                                    // klawActionStart('drop')
                                    // setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    writePort(defaultServoValue)
                                    setID(null)
                                    // klawActionStop('drop')
                                    // setContinuous(false)
                                }}
                            />
                            <p
                                style={{
                                    display: 'block',
                                    margin: 0,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Drop
                            </p>
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                id="B_R"
                                style={{
                                    // marginLeft: '30%',
                                    cursor: 'pointer',
                                }}
                                src={renderImage(
                                    id === 'B_R' ? 'leftActive' : 'leftInActive'
                                )}
                                onMouseDown={(event) => {
                                    actionBytes('B_R')
                                    setID(event.target.id)
                                    // klawActionStart('B_R')
                                    // setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    writePort(defaultServoValue)
                                    setID(null)
                                    // klawActionStop('B_R')
                                    // setContinuous(false)
                                }}
                            />
                        </div>
                        <div className="Main_Img_Txt">
                            <img
                                style={{
                                    // marginLeft: '10%',
                                    cursor: 'pointer',
                                }}
                                id="B_L"
                                src={renderImage(
                                    id === 'B_L'
                                        ? 'rightActive'
                                        : 'rightInActive'
                                )}
                                onMouseDown={(event) => {
                                    actionBytes('B_L')
                                    setID(event.target.id)
                                    // klawActionStart('B_L')
                                    // setContinuous(true)
                                }}
                                onMouseUp={() => {
                                    writePort(defaultServoValue)
                                    setID(null)
                                    // klawActionStop('B_L')
                                    // setContinuous(false)
                                }}
                            />
                        </div>
                    </div> */}
                        <div
                            style={{
                                width: '100%',
                                // border: '1px solid red',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>Grip Servo</p>
                            <HorizontalSlider
                                servoKiy="grip"
                                value={servoValues[6]}
                                min={36}
                                max={96}
                                handelSliderChange={handelSliderChange}
                                onMouseUpHandel={onMouseUpHandel}
                            />
                            <div
                                // className="base-servo"
                                style={{
                                    display: 'flex',
                                    width: '200px',
                                    justifyContent: 'space-between',
                                    marginTop: '30px',
                                }}
                            >
                                <p style={{ marginLeft: '20px' }}>Drop</p>
                                <p>Pick</p>
                            </div>
                        </div>

                        <div
                            style={{
                                width: '100%',
                                // border: '1px solid red',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <HorizontalSlider
                                direction={'right-left'}
                                servoKiy="base"
                                value={servoValues[2]}
                                min={0}
                                max={180}
                                handelSliderChange={handelSliderChange}
                                onMouseUpHandel={onMouseUpHandel}
                            />
                            <p
                                className="base-servo"
                                style={{ marginTop: '50px' }}
                            >
                                Base Servo
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// export default RemoteSection;

const mapStateToProps = (state) => {
    return state
}

export default connect(mapStateToProps)(KlawArmPlay)
