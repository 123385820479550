const generatCommonActionState = () => {
    let COMMONACTIONSTATE = {}
    for (let i = 1; i <= 18; i++) {
        COMMONACTIONSTATE[i] = { isSelected: false }
    }
    COMMONACTIONSTATE['H'] = { isSelected: false } //for Head servo
    COMMONACTIONSTATE['T'] = { isSelected: true } //for time
    return COMMONACTIONSTATE
}
var defaultCreateActionStorage = {
    allActions: [],
    commonActionState: generatCommonActionState(),
}

if (sessionStorage.getItem('createActionState') != null) {
    var createActionState = sessionStorage.getItem('createActionState')
    createActionState = JSON.parse(createActionState)
} else {
    var createActionState = structuredClone(
        JSON.parse(JSON.stringify(defaultCreateActionStorage))
    )
}

const createActionState_Reducer = (state = createActionState, action) => {
    switch (action.type) {
        case 'CREATE_ACTION_STATE': {
            console.log('CREATE_ACTION_STATE', action.payload)
            let payload = action.payload
            var data = { ...state, ...payload }
            sessionStorage.setItem('createActionState', JSON.stringify(data))
            return data
        }
        case 'COMMON_ACTION_STATE': {
            console.log('COMMON_ACTION_STATE', action.payload)
            let payload = action.payload
            var data = { ...state, commonActionState: payload }
            sessionStorage.setItem('createActionState', JSON.stringify(data))
            return data
        }
        case 'ALL_ACTIONS': {
            console.log('ALL_ACTIONS', action.payload)
            let payload = action.payload
            var data = { ...state, allActions: payload }
            sessionStorage.setItem('createActionState', JSON.stringify(data))
            return data
        }

        case 'REMOVE_CREATE_ACTION_STATE': {
            console.log('REMOVE_CREATE_ACTION_STATE')

            var data = JSON.parse(JSON.stringify(defaultCreateActionStorage))
            sessionStorage.setItem('createActionState', JSON.stringify(data))
            return data
        }
        default: {
            sessionStorage.setItem('createActionState', JSON.stringify(state))
            return state
        }
    }
}

export default createActionState_Reducer
