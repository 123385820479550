function Ports(port) {
    var Device = sessionStorage.getItem('connectedDevice')
    var shield = sessionStorage.getItem('shield')
    var armShield = sessionStorage.getItem('armShield')
    let peeCeeATR = sessionStorage.getItem('peeCeeATR')
    // let version = sessionStorage.getItem('zingVersion') || '0.0.0'
    let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    let connectedDevice = sessionStorage.getItem('connectedDevice') || '0.0.0'
    var data

    const isDeviceVersion12 =
        (['1', '2'].includes(
            (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
        ) &&
            ['Ace', 'Humanoid', 'Hexapod'].includes(
                sessionStorage.getItem('connectedDevice')
            )) ||
        ['Roboki', 'Toki'].includes(sessionStorage.getItem('connectedDevice'))

    const dual_splitter_port1 = isDeviceVersion12 ? [32, 10] : [22, 7]
    const dual_splitter_port2 = isDeviceVersion12 ? [87, 15] : [75, 12]

    if (Device == 'Ace' && shield == 'true') {
        if (
            sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            ['Ace'].includes(connectedDevice)
        ) {
            data = {
                // A1: [110, 260],
                // A2: [110, 295],
                // B1: [288, 263],
                // B2: [288, 298],
                // C1: [148, 327],
                // C2: [182, 327],
                // D1: [250, 327],
                // D2: [218, 327],
                A1: [120, 263],
                A2: [120, 299],
                B1: [301, 266],
                B2: [301, 303],
                C1: [158, 331],
                C2: [192, 333],
                D1: [263, 330],
                D2: [228, 332],

                E1: [29, 85],
                E2: [95, 85],
                F1: [27, 83],
                F2: [92, 83],
                M1: [104, 354],
                M2: [104, 354],
                M3: [226, 354],
                M4: [226, 354],
                servo_motor1: [134, 348],
                servo_motor2: [196, 348],
                A: [60, 172],
                B: [60, 224],
                C: [284, 172],
                D: [284, 224],
                E: [61, 320],
                F: [282, 320],
                G: [250, 400],
                H: [325, 280],
                P0: [210, 280],
                P1: [230, 280],
                P2: [250, 280],
                S1: [150, 350],
                S2: [200, 350],
                SS: [205, 330],

                MOTOR1: [185, 280],
                SPK: [175, 305],
            }
        } else {
            data = {
                A1: [110, 260],
                A2: [110, 295],
                B1: [288, 263],
                B2: [288, 298],
                C1: [148, 327],
                C2: [182, 327],
                D1: [250, 327],
                D2: [217, 327],
                E1: [19, 80],
                E2: [85, 80],
                F1: [19, 80],
                F2: [85, 80],
                M1: [140, 400],
                M3: [258, 400],
                M2: [118, 410],
                M4: [118, 410],
                servo_motor1: [150, 410],
                servo_motor2: [268, 410],
                // 'G1': [19, 80],
                // 'G2': [85, 80],
                // 'H1': [19, 80],
                // 'H2': [85, 80],
                // 'I1': [19, 80],
                // 'I2': [85, 80],
                B: [110, 157],
                A: [110, 117],
                C: [288, 117],
                D: [288, 157],
                E: [110, 362],
                F: [288, 362],
                G: [250, 400],
                H: [325, 280],
                // I: [190, 43],
                // STPM: [50, 10],
                // MOTOR1: [142, 393],
                // MOTOR2: [250, 393],
                P0: [210, 280],
                P1: [230, 280],
                P2: [250, 280],
                S1: [150, 350],
                S2: [200, 350],
                SS: [205, 330],

                MOTOR1: [185, 280],
                SPK: [175, 305],
            }
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4

            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Ace' && armShield == 'true') {
        data = {
            A: [90, 125],
            B: [90, 175],
            C: [320, 125],
            D: [320, 175],
            E: [110, 362],
            F: [288, 362],
            G: [250, 400],
            H: [325, 280],
            A1: [110, 260],
            A2: [110, 295],
            B1: [288, 263],
            B2: [288, 298],
            C1: [148, 327],
            C2: [182, 327],
            D1: [217, 327],
            D2: [250, 327],
            E1: [19, 80],
            E2: [85, 80],
            F1: [19, 80],
            F2: [85, 80],
            M1: [157, 165],
            M3: [242, 167],
            M2: [118, 410],
            M4: [118, 410],

            P0: [210, 280],
            P1: [230, 280],
            P2: [250, 280],
            S1: [150, 350],
            S2: [200, 350],
            SS: [205, 330],

            MOTOR1: [185, 280],
            SPK: [175, 305],
            servo_motor1: [150, 410],
            servo_motor2: [268, 410],
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
        }
    } else if (Device == 'Ace' && peeCeeATR == 'true') {
        if (
            sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            ['Ace'].includes(connectedDevice)
        ) {
            data = {
                // A1: [35, 8],
                // A2: [80, 13],
                // B1: [24, 5],
                // B2: [80, 10],
                // C1: [24, 5],
                // C2: [80, 10],
                // D1: [24, 5],
                // D2: [80, 10],
                // E1: [19, 80],
                // E2: [85, 80],
                // F1: [19, 80],
                // F2: [85, 80],
                A1: [35, 8],
                A2: [90, 16],
                B1: [34, 8],
                B2: [90, 16],
                C1: [34, 8],
                C2: [90, 16],
                D1: [34, 8],
                D2: [90, 16],
                E1: [19, 80],
                E2: [85, 80],
                F1: [19, 80],
                F2: [85, 80],
                M1: [78, 248],
                M2: [78, 248],
                M3: [134, 340],
                M4: [134, 340],
                servo_motor1: [134, 348],
                servo_motor2: [196, 348],
                A: [94, 142],
                B: [94, 178],
                C: [251, 142],
                D: [251, 178],
                E: [61, 320],
                F: [282, 320],
                G: [210, 340],
                H: [270, 248],
                P0: [210, 280],
                P1: [230, 280],
                P2: [250, 280],
                S1: [220, 300],
                S2: [125, 300],
                SS: [205, 330],

                MOTOR1: [185, 280],
                SPK: [175, 305],
            }
        } else {
            data = {
                A1: [22, 7],
                A2: [75, 12],
                B1: [22, 7],
                B2: [75, 12],
                C1: [22, 7],
                C2: [75, 12],
                D1: [22, 7],
                D2: [75, 12],
                E1: [0, 0],
                E2: [0, 0],
                F1: [0, 0],
                F2: [0, 0],
                M1: [85, 280],
                M2: [85, 280],
                M3: [160, 400],
                M4: [160, 400],
                servo_motor1: [150, 410],
                servo_motor2: [268, 410],
                // G1: [250, 400],
                // G2: [250, 400],
                // H1: [325, 280],
                // H2: [325, 280],
                // 'I1': [19, 80],
                // 'I2': [85, 80],
                B: [92, 165],
                A: [92, 120],
                C: [318, 120],
                D: [318, 165],
                E: [-10000, -100000],
                F: [-10000, -10000],
                G: [250, 400],
                H: [325, 280],
                // I: [190, 43],
                // STPM: [50, 10],
                // MOTOR1: [142, 393],
                // MOTOR2: [250, 393],
                P0: [210, 280],
                P1: [230, 280],
                P2: [250, 280],
                S1: [260, 350],
                S2: [150, 350],
                SS: [205, 330],

                MOTOR1: [185, 280],
                SPK: [175, 305],
            }
        }

        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4
            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Ace') {
        if (
            sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            ['Ace'].includes(connectedDevice)
        ) {
            data = {
                A1: [32, 10],
                A2: [87, 15],
                B1: [32, 10],
                B2: [87, 15],
                C1: [32, 10],
                C2: [87, 15],
                D1: [32, 10],
                D2: [87, 15],
                E1: [0, 0],
                E2: [0, 0],
                F1: [0, 0],
                F2: [0, 0],
                M1: [157, 165],
                M3: [242, 167],
                M2: [118, 410],
                M4: [118, 410],
                servo_motor1: [150, 410],
                servo_motor2: [268, 410],
                B: [40, 213],
                A: [40, 166],
                C: [245, 166],
                D: [245, 213],
                E: [-10000, -100000],
                F: [-10000, -10000],
                G: [250, 400],
                H: [325, 280],
                P0: [210, 280],
                P1: [230, 280],
                P2: [250, 280],
                S1: [150, 350],
                S2: [200, 350],
                SS: [205, 330],

                MOTOR1: [185, 280],
                SPK: [175, 305],
            }
        } else {
            data = {
                A1: [22, 7],
                A2: [75, 12],
                B1: [22, 7],
                B2: [75, 12],
                C1: [22, 7],
                C2: [75, 12],
                D1: [22, 7],
                D2: [75, 12],
                E1: [0, 0],
                E2: [0, 0],
                F1: [0, 0],
                F2: [0, 0],
                M1: [100, 400],
                M2: [150, 300],
                M3: [200, 300],
                M4: [250, 400],
                servo_motor1: [150, 410],
                servo_motor2: [268, 410],
                // 'G1': [19, 80],
                // 'G2': [85, 80],
                // 'H1': [19, 80],
                // 'H2': [85, 80],
                // 'I1': [19, 80],
                // 'I2': [85, 80],
                B: [13, 208],
                A: [13, 150],
                C: [278, 150],
                D: [278, 208],
                E: [-10000, -100000],
                F: [-10000, -10000],
                G: [250, 400],
                H: [325, 280],
                // I: [190, 43],
                // STPM: [50, 10],
                // MOTOR1: [142, 393],
                // MOTOR2: [250, 393],
                P0: [210, 280],
                P1: [230, 280],
                P2: [250, 280],
                S1: [150, 350],
                S2: [200, 350],
                SS: [205, 330],

                MOTOR1: [185, 280],
                SPK: [175, 305],
            }
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4
            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Roboki') {
        data = {
            A1: [32, 10],
            A2: [87, 15],
            B1: [32, 10],
            B2: [87, 15],
            C1: [32, 10],
            C2: [87, 15],
            D1: [32, 10],
            D2: [87, 15],
            E1: [0, 0],
            E2: [0, 0],
            F1: [0, 0],
            F2: [0, 0],
            // M1: [157, 165],
            // M3: [242, 167],
            // M2: [118, 410],
            // M4: [118, 410],
            M1: [71, 200],
            M2: [150, 300],
            M3: [210, 200],
            M4: [250, 400],
            // servo_motor1: [150, 410],
            // servo_motor2: [268, 410],
            servo_motor1: [71, 158],
            servo_motor2: [210, 158],
            // B: [40, 213],
            B: [192, 25],
            // A: [40, 166],
            A: [92, 25],
            C: [245, 166],
            D: [245, 213],
            E: [-10000, -100000],
            F: [-10000, -10000],
            G: [250, 400],
            H: [325, 280],
            P0: [210, 280],
            P1: [230, 280],
            P2: [250, 280],
            S1: [150, 350],
            S2: [200, 350],
            SS: [205, 330],

            MOTOR1: [185, 280],
            SPK: [175, 305],
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4
            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Humanoid') {
        data = {
            A1: dual_splitter_port1,
            A2: dual_splitter_port2,
            B1: dual_splitter_port1,
            B2: dual_splitter_port2,
            C1: dual_splitter_port1,
            C2: dual_splitter_port2,
            D1: dual_splitter_port1,
            D2: dual_splitter_port2,
            E1: [0, 0],
            E2: [0, 0],
            F1: [0, 0],
            F2: [0, 0],
            M1: [157, 165],
            M3: [242, 167],
            M2: [118, 410],
            M4: [118, 410],
            servo_motor1: [150, 410],
            servo_motor2: [268, 410],
            // 'G1': [19, 80],
            // 'G2': [85, 80],
            // 'H1': [19, 80],
            // 'H2': [85, 80],
            // 'I1': [19, 80],
            // 'I2': [85, 80],
            B: [7, 65],
            A: [251, 15],
            C: [9, 15],
            D: [255, 65],
            E: [-10000, -100000],
            F: [-10000, -10000],
            G: [250, 400],
            H: [325, 280],
            // I: [190, 43],
            // STPM: [50, 10],
            MOTOR1: [142, 393],
            // MOTOR2: [250, 393],
            P0: [220, 295],
            P1: [240, 295],
            P2: [260, 295],
            S1: [150, 350],
            S2: [200, 350],
            SS: [190, 400],
            SPK: [150, 175],
        }
        if (deviceVersion.startsWith('0')) {
            data = { ...data, A: [7, 15], C: [255, 15] }
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4

            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Hexapod') {
        data = {
            A1: [32, 10],
            A2: [87, 15],
            B1: [32, 10],
            B2: [87, 15],
            C1: [32, 10],
            C2: [87, 15],
            D1: [32, 10],
            D2: [87, 15],
            E1: [0, 0],
            E2: [0, 0],
            F1: [0, 0],
            F2: [0, 0],
            O1: [110, 260],
            O2: [110, 295],
            M1: [157, 165],
            M3: [242, 167],
            M2: [118, 410],
            M4: [118, 410],
            servo_motor1: [150, 410],
            servo_motor2: [268, 410],
            // 'G1': [19, 80],
            // 'G2': [85, 80],
            // 'H1': [19, 80],
            // 'H2': [85, 80],
            // 'I1': [19, 80],
            // 'I2': [85, 80],
            B: [92, 85],
            A: [92, 125],
            C: [403, 125],
            D: [403, 85],
            E: [-10000, -100000],
            F: [-10000, -10000],
            G: [110, 190],
            H: [190, 43],
            // I: [190, 43],
            // STPM: [50, 10],
            MOTOR1: [142, 393],
            // MOTOR2: [250, 393],
            P0: [220, 295],
            P1: [240, 295],
            P2: [260, 295],
            S1: [150, 350],
            S2: [200, 350],
            SS: [190, 400],
            serial_servo1: [-35, 175],
            serial_servo2: [230, 175],
            SPK: [150, 175],
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4

            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'serial_servo1':
                return data.serial_servo1
            case 'serial_servo2':
                return data.serial_servo2
            case 'PW1':
                return data.PW1
            case 'PW2':
                return data.PW2
            case 'PW3':
                return data.PW3
            case 'PW4':
                return data.PW4
            case 'PW5':
                return data.PW5
            case 'PW6':
                return data.PW6
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Klaw') {
        data = {
            A1: [32, 10],
            A2: [87, 15],
            B1: [32, 10],
            B2: [87, 15],
            C1: [32, 10],
            C2: [87, 15],
            D1: [32, 10],
            D2: [87, 15],
            E1: [0, 0],
            E2: [0, 0],
            F1: [33, 45],
            F2: [358, 45],
            O1: [56, 117],
            O2: [350, 117],
            M1: [50, 150],
            M3: [530, 150],
            M2: [118, 410],
            M4: [118, 410],
            servo_motor1: [150, 410],
            servo_motor2: [268, 410],
            // 'G1': [19, 80],
            // 'G2': [85, 80],
            // 'H1': [19, 80],
            // 'H2': [85, 80],
            // 'I1': [19, 80],
            // 'I2': [85, 80],
            B: [301, 237],
            A: [34, 236],
            C: [239, -10],
            D: [239, 29],
            E: [-10000, -100000],
            F: [-10000, -10000],
            // G: [110, 190],
            // H: [190, 43],
            // I: [190, 43],
            // STPM: [50, 10],
            MOTOR1: [142, 393],
            // MOTOR2: [250, 393],
            P0: [220, 295],
            P1: [240, 295],
            P2: [260, 295],
            S1: [150, 350],
            S2: [200, 350],
            serial_servo1: [64, 163],
            serial_servo2: [344, 163],
            PW1: [80, 110],
            PW2: [122, 110],
            PW3: [162, 110],
            PW4: [418, 110],
            PW5: [457, 110],
            PW6: [395, 110],
            SPK: [150, 175],
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'O1':
                return data.O1
            case 'O2':
                return data.O2
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4

            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'serial_servo1':
                return data.serial_servo1
            case 'serial_servo2':
                return data.serial_servo2
            case 'PW1':
                return data.PW1
            case 'PW2':
                return data.PW2
            case 'PW3':
                return data.PW3
            case 'PW4':
                return data.PW4
            case 'PW5':
                return data.PW5
            case 'PW6':
                return data.PW6
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else if (Device == 'Toki') {
        data = {
            // B: [283, 65],
            // // A: [40, 166],
            // A: [5, 65],
            A1: [88, 123],
            A2: [197, 123],
            B1: [197, 57],
            B2: [87, 15],
            C1: [88, 167],
            C2: [197, 167],
            D1: [119, 170],
            D2: [87, 15],
            E1: [0, 0],
            E2: [0, 0],
            F1: [0, 0],
            F2: [0, 0],
            M1: [97, 252],
            M2: [150, 300],
            M3: [189, 252],
            M4: [250, 400],
            servo_motor1: [88, 90],
            servo_motor2: [197, 90],
            B: [283, 70],
            A: [2, 70],
            C: [245, 166],
            D: [245, 213],
            E: [-10000, -100000],
            F: [-10000, -10000],
            G: [250, 400],
            H: [325, 280],
            P0: [210, 280],
            P1: [230, 280],
            P2: [250, 280],
            S1: [77, 100],
            S2: [200, 100],
            SS: [205, 330],
            MOTOR1: [185, 280],
            SPK: [175, 305],
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4
            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    } else {
        data = {
            A1: [110, 260],
            A2: [110, 295],
            B1: [288, 263],
            B2: [288, 298],
            C1: [148, 327],
            C2: [182, 327],
            D1: [250, 327],
            D2: [217, 327],
            E1: [19, 80],
            E2: [85, 80],
            F1: [19, 80],
            F2: [85, 80],
            M1: [140, 400],
            M3: [258, 400],
            M2: [118, 410],
            M4: [118, 410],
            servo_motor1: [150, 410],
            servo_motor2: [268, 410],
            // 'G1': [19, 80],
            // 'G2': [85, 80],
            // 'H1': [19, 80],
            // 'H2': [85, 80],
            // 'I1': [19, 80],
            // 'I2': [85, 80],
            B: [110, 157],
            A: [110, 117],
            C: [288, 117],
            D: [288, 157],
            E: [110, 362],
            F: [288, 362],
            G: [250, 400],
            H: [325, 280],
            // I: [190, 43],
            // STPM: [50, 10],
            // MOTOR1: [142, 393],
            // MOTOR2: [250, 393],
            P0: [210, 280],
            P1: [230, 280],
            P2: [250, 280],
            S1: [150, 350],
            S2: [200, 350],
            SS: [205, 330],

            MOTOR1: [185, 280],
            SPK: [175, 305],
        }
        switch (port) {
            case 'A':
                return data.A
            case 'A1':
                return data.A1
            case 'A2':
                return data.A2
            case 'B':
                return data.B
            case 'B1':
                return data.B1
            case 'B2':
                return data.B2
            case 'C':
                return data.C
            case 'C1':
                return data.C1
            case 'C2':
                return data.C2
            case 'D':
                return data.D
            case 'D1':
                return data.D1
            case 'D2':
                return data.D2
            case 'E':
                return data.E
            case 'E1':
                return data.E1
            case 'E2':
                return data.E2
            case 'F':
                return data.F
            case 'F1':
                return data.F1
            case 'F2':
                return data.F2
            case 'G':
                return data.G
            case 'H':
                return data.H
            case 'M1':
                return data.M1
            case 'M2':
                return data.M2
            case 'M3':
                return data.M3
            case 'M4':
                return data.M4

            case 'servo_motor1':
                return data.servo_motor1
            case 'servo_motor2':
                return data.servo_motor2
            case 'SPK':
                return data.SPK
            case 'P0':
                return data.P0
            case 'P1':
                return data.P1
            case 'P2':
                return data.P2
            case 'S1':
                return data.S1
            case 'S2':
                return data.S2
            case 'SS':
                return data.SS
            case 'MOTOR1':
                return data.MOTOR1
        }
    }
}
export default Ports
