import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    deviceDetails,
    deviceMismatch,
    graphPlotStorage,
    selecteInternalAcessories,
    showModal,
    webSerialConnect,
} from '../../../redux/actions'

import selectImg from '../../../Assets/img/select bar@2x.png'
import inputImg from '../../../Assets/img/assemble bar@2x.png'
import digitalImg from '../../../Assets/img/code bar@2x.png'
import flowchartImg from '../../../Assets/img/simulate bar@2x.png'

import secondaryImg from '../../../Assets/Bisoft_UI/Buttons/Inactive with shadow/save - secondary@2x.png'
import style from '../DialogModal/DialogModal.module.css'
import strokeImg from '../../../Assets/Bisoft_UI/Buttons/Inactive with shadow/help@2x.png'
import file from '../../../Assets/Bisoft_UI/Buttons/Inactive - without shadow/file.png'
import renderPrgImage from '../../../source/programImg'
import saveButton from '../../../Assets/Bisoft_UI/AppMode/save.png'
import renderImage from '../../../source/importImg'
import alignImage from '../../../Assets/flowchart/button 52x52 - stroke.png'
import { useHistory } from 'react-router'
import {
    connectSerialDevice,
    connectSerialDeviceInitial,
    connectSerialDeviceIntermediate,
    connectUSBDevice,
} from '../../WebSerialConnection/SerialUSBConnect'
import { portsData } from '../../Assembly/GraphPlotter/GraphPlotStorage'
import './ProgramHeader.css'
import { useState } from 'react'
import { saveAs } from 'file-saver'
import DialogModal from '../DialogModal/DialogModal'
import DeviceMissMatchPopupCom from './DeviceMissMatchPopupCom'
function ProgramHeader({
    worker,
    webSerial,
    webSerialConnect,
    data,
    showHelp,
    handleHelp,
    showSave,
    handleSave,
    showClear,
    handleClear,
    showFlowchartAlign,
    handleFlowchartAlign,
    showGraph,
    showModal,
    graph,
    viewActions,
    actionsList,
    device,
    deviceMismatch,
    deviceDetail,
    DeselectedComponent,
    concept,
    graphPlotStorage,
    graphPlotStorageReset,
}) {
    var GraphData = JSON.parse(sessionStorage.getItem('GraphData'))
    let SELECTBARS = {
        0: (
            <img
                src={selectImg}
                style={
                    GraphData == true
                        ? {
                              height: '100%',
                              width: '16%',
                              position: 'relative',
                              right: '46vw',
                          }
                        : {
                              height: '100%',
                              width: '17%',
                              position: 'relative',
                              right: '43vw',
                          }
                }
            />
        ),
        1: (
            <img
                src={inputImg}
                style={
                    GraphData == true
                        ? {
                              height: '100%',
                              width: '29%',
                              position: 'relative',
                              right: '42vw',
                          }
                        : {
                              height: '100%',
                              width: '29%',
                              position: 'relative',
                              right: '38vw',
                          }
                }
            />
        ),
        2: (
            <img
                src={digitalImg}
                style={
                    GraphData == true
                        ? {
                              height: '100%',
                              width: '40%',
                              position: 'relative',
                              right: '38vw',
                          }
                        : {
                              height: '100%',
                              width: '40%',
                              position: 'relative',
                              right: '31vw',
                          }
                }
            />
        ),
        3: (
            <img
                src={flowchartImg}
                style={
                    GraphData == true
                        ? {
                              height: '100%',
                              width: '52%',
                              position: 'relative',
                              right: '33vw',
                          }
                        : {
                              height: '100%',
                              width: '52%',
                              position: 'relative',
                              right: '25vw',
                          }
                }
            />
        ),

        4: (
            <img
                src={flowchartImg}
                style={
                    GraphData == true
                        ? {
                              height: '100%',
                              width: '55%',
                              position: 'relative',
                              right: '25vw',
                          }
                        : null
                }
            />
        ),
    }
    // const [fileName, setFileName] = useState('')
    const [filePopup, setFilePopUp] = useState(false)
    // const [isDeviceMismatch, setIsDeviceMismatch] = useState(false)
    // const [showErrorMsg, setShowErrorMsg] = useState(false)
    const [showDialogBoxes, setShowDialogBoxes] = useState({
        showErrorMsg: false,
        isDeviceMismatch: false,
        filePopup: false,
    })
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    let history = useHistory()
    var newFileName = ''

    let deviceName = ''
    switch (connectedDevice) {
        case 'Hexapod':
            deviceName = 'Crawl-e'
            break
        default:
            deviceName = 'Zing'
    }

    const handleUSB = async () => {
        if (!webSerial.isConnected) {
            let res = await connectSerialDeviceIntermediate(
                { isAutomatic: false },
                worker
            )

            if (res !== undefined) {
                if (!res.isPortOpen) {
                    deviceMismatch({
                        deviceMismatched: res.matchedObj_with_Version,
                        isMemoryPresent: res.isMemoryPresent,
                    })
                    setShowDialogBoxes({
                        ...showDialogBoxes,
                        isDeviceMismatch: true,
                    })
                } else {
                    deviceMismatch({
                        deviceMismatched: {},
                        isMemoryPresent: res.isMemoryPresent,
                    })
                }
            }

            if (res) {
                webSerialConnect({ port: res.port })
            } else {
                setShowDialogBoxes({ ...showDialogBoxes, showErrorMsg: true })
            }
        }
    }

    //for mismatch of device while connecting
    const handleDevice = async (option) => {
        if (option === 'no') {
            let port = webSerial.port
            //is this needed??
            // await openSerialReadComPort(port,worker)
        } else {
            shouldErase()
            history.push('/Selection')
            let mismatchedDevice = device.deviceMismatched
            sessionStorage.setItem('connectedDevice', mismatchedDevice.id)
            // if (['Ace','Hexapod'].includes(mismatchedDevice.id)) {
            sessionStorage.setItem('deviceVersion', mismatchedDevice.version)
            deviceDetail({
                connectedDevice: mismatchedDevice.id,
                deviceVersion: mismatchedDevice.version,
            })
            // } else {
            //     sessionStorage.setItem('zingVersion', mismatchedDevice.version)
            //     deviceDetail({
            //         connectedDevice: mismatchedDevice.id,
            //         zingVersion: mismatchedDevice.version,
            //         deviceVersion: '0.0.0',
            //     })
            // }
        }
        setShowDialogBoxes({ ...showDialogBoxes, isDeviceMismatch: false })
        deviceMismatch({ deviceMismatched: {} })
    }

    const shouldErase = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        let recoveryData = JSON.parse(localStorage.getItem('recoveryData'))
        if (
            localStorage.getItem('recoveryData') !== null &&
            programMode in recoveryData &&
            connectedDevice in recoveryData[programMode] &&
            version in recoveryData[programMode][connectedDevice]
        ) {
            if (Object.keys(recoveryData).length === 1) {
                if (Object.keys(recoveryData[programMode]).length === 1) {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        localStorage.removeItem('recoveryData')
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                        localStorage.setItem(
                            'recoveryData',
                            JSON.stringify(recoveryData)
                        )
                    }
                } else {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        delete recoveryData[programMode][connectedDevice]
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(recoveryData)
                    )
                }
            } else {
                if (Object.keys(recoveryData[programMode]).length === 1) {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        delete recoveryData[programMode]
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(recoveryData)
                    )
                } else {
                    if (
                        Object.keys(recoveryData[programMode][connectedDevice])
                            .length === 1
                    ) {
                        delete recoveryData[programMode][connectedDevice]
                    } else {
                        delete recoveryData[programMode][connectedDevice][
                            version
                        ]
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(recoveryData)
                    )
                }
            }
        }
        var arr = Object.keys(sessionStorage)
        for (const i of arr) {
            if (
                i != 'connectedDevice' &&
                i != 'Hardware' &&
                i != 'userData' &&
                i != 'concept' &&
                i != 'webSerialPortList' &&
                i != 'user' &&
                i != 'deviceVersion' &&
                i != 'programMode' &&
                i != 'isWindowOnFocus' &&
                i != 'slideDirection' &&
                i != 'coverflowActive'
            ) {
                //arr.push(i);
                sessionStorage.removeItem(i)
                // console.log(i, "sess");
            }
        }
        sessionStorage.setItem('pip', false)
        sessionStorage.setItem('name', 'null')
        sessionStorage.setItem('GraphData', false)
        sessionStorage.setItem('shield', 'false')
        sessionStorage.setItem('peeCeeATR', 'false')
        sessionStorage.setItem('arm_shield', 'false')
        sessionStorage.setItem('slideDirection', true)
        concept.Index = []
        concept.counter = []
        DeselectedComponent({
            Four_in_one_sensor: {
                isDistanceSensors: false,
                isGestureSensor: false,
                isLightSensor: false,
                isColorSensor: false,
            },
            isFour_in_one_sensor: false,

            // input
            isTouchZero: false,
            isTouchOne: false,
            isTouchTwo: false,
            isTouchThree: false,
            // output
            isTouchZeroOutput: false,
            isTouchOneOutput: false,
            isTouchTwoOutput: false,
            isTouchThreeOutput: false,

            isMic: false,
            isTemperature: false,
            isBattery: false,
            isAccelerometer: false,

            isLeftEye: false,
            isRightEye: false,
            isEYES: false,
            isbuzzer: false,

            isSmileOne: false,
            isSmileTwo: false,
            isSmileThree: false,
            isSmileFour: false,
            //humanoid
            isBattery: false,
            isUltrasonic: false,
            isAccelerometer: false,
            isGyroX: false,
            isGyroY: false,
            isGyroZ: false,
            isAccelerometerX: false,
            isUltrasonic: false,
            isAccelerometerY: false,
            isAccelerometerZ: false,
            // output
            isLeftRGB: false,
            isRightRGB: false,
            isMp3: false,
            isHeadServo: false,
            isIndivdualServo: false,
            isActionGroup: false,
            isM1: false,
            isM2: false,
            isSimpleServo: false,
        })
        DeselectedComponent({
            portsData: [
                {
                    key: 'A',
                    ports: [
                        {
                            portName: 'A1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#E25432',
                        },
                        {
                            portName: 'A2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#B0D745',
                        },
                    ],
                },

                {
                    key: 'B',
                    ports: [
                        {
                            portName: 'B1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#23DE93',
                        },
                        {
                            portName: 'B2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#3A3A3A',
                        },
                    ],
                },

                {
                    key: 'C',
                    ports: [
                        {
                            portName: 'C1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#0ACEF7',
                        },
                        {
                            portName: 'C2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#5061A8',
                        },
                    ],
                },

                {
                    key: 'D',
                    ports: [
                        {
                            portName: 'D1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#0E3C11',
                        },
                        {
                            portName: 'D2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#DD1624',
                        },
                    ],
                },

                {
                    key: 'E',
                    ports: [
                        {
                            portName: 'E1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#F79B47',
                        },
                        {
                            portName: 'E2',
                            isActive: false,
                            isDigital: false,
                            isColor: '#F666D0',
                        },
                    ],
                },

                {
                    key: 'F',
                    ports: [
                        {
                            portName: 'F1',
                            isActive: false,
                            isDigital: false,
                            isColor: '#3D5B53',
                        },
                        {
                            portName: 'F2',
                            isActive: false,
                            isDigital: false,
                            isColor: 'black',
                        },
                    ],
                },
            ],
        })
        graphPlotStorageReset({
            time: { h: 0, m: 0, s: 0, ms: 0 },
            Four_in_one_sensor: {
                isDistanceSensors: false,
                isGestureSensor: false,
                isLightSensor: false,
                isColorSensor: false,
            },

            // input
            isTouchZero: false,
            isTouchOne: false,
            isTouchTwo: false,
            isTouchThree: false,
            isMic: false,
            isTemperature: false,
            isBattery: false,
            isAccelerometer: false,
            isAccelerometerX: false,
            isAccelerometerY: false,
            isAccelerometerZ: false,
            isGyroX: false,
            isGyroY: false,
            isGyroZ: false,
            isUltrasonic: false,
            portsData,
        })
        portsData[0] = {
            key: 'A',
            ports: [
                {
                    portName: 'A1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#E25432',
                },
                {
                    portName: 'A2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#B0D745',
                },
            ],
        }

        portsData[1] = {
            key: 'B',
            ports: [
                {
                    portName: 'B1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#23DE93',
                },
                {
                    portName: 'B2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#3A3A3A',
                },
            ],
        }

        portsData[2] = {
            key: 'C',
            ports: [
                {
                    portName: 'C1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#0ACEF7',
                },
                {
                    portName: 'C2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#5061A8',
                },
            ],
        }

        portsData[3] = {
            key: 'D',
            ports: [
                {
                    portName: 'D1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#0E3C11',
                },
                {
                    portName: 'D2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#DD1624',
                },
            ],
        }

        portsData[4] = {
            key: 'E',
            ports: [
                {
                    portName: 'E1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#F79B47',
                },
                {
                    portName: 'E2',
                    isActive: false,
                    isDigital: false,
                    isColor: '#F666D0',
                },
            ],
        }

        portsData[5] = {
            key: 'F',
            ports: [
                {
                    portName: 'F1',
                    isActive: false,
                    isDigital: false,
                    isColor: '#3D5B53',
                },
                {
                    portName: 'F2',
                    isActive: false,
                    isDigital: false,
                    isColor: 'black',
                },
            ],
        }
    }
    const handleError = async () => {
        setShowDialogBoxes({ ...showDialogBoxes, showErrorMsg: false })
        window.location.reload()
    }

    //when device is disconnect set the mismatch empty
    useEffect(() => {
        if (sessionStorage.getItem('isDeviceConnected') == 'false') {
            deviceMismatch({ deviceMismatched: {} })
        }
    }, [webSerial.isConnected])
    useEffect(() => {
        if (device) {
            let deviceD = Object.keys(device.deviceMismatched)
            if (deviceD.length > 1) {
                if (showDialogBoxes.isDeviceMismatch == false) {
                    setShowDialogBoxes({
                        ...showDialogBoxes,
                        isDeviceMismatch: true,
                    })
                }
            }
        }
    }, [device.deviceMismatched])

    const saveFile = () => {
        sessionStorage.setItem('isSave', JSON.stringify(true))
        if (graph) {
            console.log(graph, 'hello')
            const csvHeader = Object.keys(graph[0]).join(',')
            const csvRows = graph
                .map((obj) => Object.values(obj).join(','))
                .join('\n')
            const csvString = `${csvHeader}\n${csvRows}`
            const blob = new Blob([csvString], { type: 'text/csv' })
            if (newFileName.length !== 0) saveAs(blob, `${newFileName}.csv`)
            return
        }
        const fileData = JSON.stringify(sessionStorage)
        const blob = new Blob([fileData], { type: 'text/plain' })
        if (newFileName.length !== 0) saveAs(blob, `${newFileName}.pld`)
    }

    const RenderFileName = () => {
        console.log('onClock')
        const [fileName, setFileName] = useState('')
        let inputHandler = (event) => {
            setFileName(event.target.value)
            newFileName = event.target.value
        }
        console.log('newfile', newFileName)

        return (
            <>
                {showDialogBoxes.filePopup && (
                    <div className={style.container}>
                        <div className={style.box}>
                            <img
                                className={style.closeBtn}
                                style={{ width: '2.5rem', top: '-60px' }}
                                src={renderImage('clos')}
                                onClick={() => {
                                    setShowDialogBoxes({
                                        ...showDialogBoxes,
                                        filePopup: false,
                                    })
                                }}
                            />
                            <input
                                placeholder="Enter the file name"
                                style={{
                                    width: '15vw',
                                    height: '4.5vh',
                                    backgroundColor: 'lightgrey',
                                    textAlign: 'center',
                                    position: 'absolute',
                                    borderRadius: '5px',
                                    border: 'none',
                                    top: '47%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                                type="text"
                                value={fileName}
                                autoFocus={true}
                                onChange={inputHandler}
                            />{' '}
                            <br></br>
                            <img
                                src={saveButton}
                                draggable="false"
                                style={{
                                    cursor: 'pointer',
                                    width: '8vw',
                                    position: 'absolute',
                                    top: '54%',
                                    left: '50%',
                                    transform: 'translate(-50%,-50%)',
                                }}
                                onClick={() => {
                                    setShowDialogBoxes({
                                        ...showDialogBoxes,
                                        filePopup: false,
                                    })
                                    saveFile()
                                }}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }
    const handleGraph = () => {
        history.push('/graphPlotter')
    }

    return (
        <>
            <DialogModal
                mismatchShow={showDialogBoxes.isDeviceMismatch}
                text={<DeviceMissMatchPopupCom />}
                showCloseBtn={true}
                handleDialogModalClose={() =>
                    setShowDialogBoxes({
                        ...showDialogBoxes,
                        isDeviceMismatch: false,
                    })
                }
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => handleDevice('yes'),
                    },
                    // {
                    //     text: 'NO',
                    //     do: () => handleDevice('no'),
                    // },
                ]}
            />
            <DialogModal
                mismatchShow={
                    connectedDevice == 'Humanoid' &&
                    deviceVersion?.startsWith('2') &&
                    webSerial.isConnected &&
                    !showDialogBoxes.isDeviceMismatch &&
                    !device.isMemoryPresent
                }
                text={`There is no memory card inserted in ${deviceName}. \nkindly insert one to use the application further.`}
                showCloseBtn={false}
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: () => history.push('/kitSelection'),
                    },
                ]}
            />
            <DialogModal
                showError={showDialogBoxes.showErrorMsg}
                text={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2.5vh',
                            }}
                        >
                            Error in connecting the device!
                        </p>
                        <p
                            style={{
                                transform: 'translateY(20%)',
                                fontSize: '2vh',
                            }}
                        >
                            Please disconnect the device and try connecting
                            again.
                        </p>
                    </div>
                }
                showCloseBtn={true}
                handleDialogModalClose={() =>
                    setShowDialogBoxes({
                        ...showDialogBoxes,
                        showErrorMsg: false,
                    })
                }
                optionsToSelect={[
                    {
                        text: 'OK',
                        do: handleError,
                    },
                ]}
            />

            <div className="HeaderContainer">
                <RenderFileName />
                <div
                    className="flowchart-navbarContainer navbarContainer"
                    style={{ zIndex: '1000' }}
                >
                    {SELECTBARS[data.selected]}
                    <div className="flowchart-navbar_content navbar_content">
                        {data.options.map((option, index) => (
                            <div
                                key={index}
                                className={`flowchart-navbar_new navbar_new ${
                                    data.selected <= index - 1
                                        ? 'notActive'
                                        : 'isActive'
                                }`}
                            >
                                {option}
                            </div>
                        ))}
                    </div>

                    <div
                        className="flowchart-navbar-Action navbar-Action"
                        style={{ alignItems: 'center' }}
                    >
                        {showGraph && (
                            <img
                                className="flowchart-iconBtnSize iconBtnSize"
                                style={{
                                    width: '61px',
                                    height: '61px',
                                    marginRight: '10px',
                                    marginTop: '-13px',
                                }}
                                src={renderPrgImage('graphbtn')}
                                onClick={handleGraph}
                            ></img>
                        )}

                        {showSave && (
                            <div
                                className="help_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    className="flowchart-iconBtnSize iconBtnSize"
                                    style={{
                                        width: '61px',
                                        height: '61px',
                                        marginRight: '10px',
                                        marginTop: '-13px',
                                    }}
                                    src={secondaryImg}
                                    onClick={() => {
                                        setShowDialogBoxes({
                                            ...showDialogBoxes,
                                            filePopup: true,
                                        })
                                    }}
                                ></img>
                                <span className="tooltiptext">
                                    {' '}
                                    <p>Save</p>
                                </span>
                            </div>
                        )}
                        {showFlowchartAlign && (
                            <div
                                className="help_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    style={{
                                        width: '61px',
                                        height: '61px',
                                        marginRight: '10px',
                                        marginTop: '6px',
                                    }}
                                    src={alignImage}
                                    onClick={handleFlowchartAlign}
                                ></img>
                                <span
                                    className="tooltiptext"
                                    style={{ top: '14.5vh' }}
                                >
                                    {' '}
                                    <p>Align</p>
                                </span>
                            </div>
                        )}
                        {showClear && (
                            <div
                                className="help_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    className="iconBtnSize"
                                    src={renderPrgImage('clearProgram')}
                                    onClick={handleClear}
                                    style={{
                                        width: '60px',
                                        height: '60px',
                                        marginRight: '10px',
                                        marginTop: '-5px',
                                        transform: 'scale(0.8)',
                                    }}
                                ></img>
                                <span
                                    className="tooltiptext"
                                    style={{ top: '13.5vh' }}
                                >
                                    {' '}
                                    <p>Clear</p>
                                </span>
                            </div>
                        )}
                        {showHelp && (
                            <div
                                className="help_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    className="flowchart-iconBtnSize iconBtnSize"
                                    style={{
                                        width: '61px',
                                        height: '61px',
                                        marginRight: '10px',
                                        marginTop: '-13px',
                                    }}
                                    src={strokeImg}
                                    onClick={handleHelp}
                                ></img>
                                <span className="tooltiptext">
                                    {' '}
                                    <p>Help</p>
                                </span>
                            </div>
                        )}
                        {viewActions && (
                            <div
                                className="file_open"
                                style={{
                                    position: 'relative',
                                    right: '0vw',
                                    top: '10px',
                                }}
                            >
                                <img
                                    // className="flowchart-iconBtnSize iconBtnSize"
                                    style={{
                                        width: '61px',
                                        height: '61px',
                                        marginRight: '10px',
                                        marginTop: '-13px',
                                    }}
                                    src={file}
                                    onClick={() => {
                                        actionsList(true)
                                    }}
                                ></img>
                                <span className="tooltiptext">
                                    {' '}
                                    <p>Open Save Actions</p>
                                </span>
                            </div>
                        )}

                        {webSerial.isConnected ? (
                            <div
                                className="web_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    src={renderPrgImage('usbON')}
                                    onClick={handleUSB}
                                    style={{ height: '9vh' }}
                                />
                                <span className="tooltiptext">
                                    {' '}
                                    <p>
                                        Device <br />
                                        connectivity
                                    </p>
                                </span>
                            </div>
                        ) : (
                            <div
                                className="web_button"
                                style={{ position: 'relative' }}
                            >
                                <img
                                    src={renderPrgImage('usbOFF')}
                                    onClick={handleUSB}
                                    style={{ height: '9vh' }}
                                />
                                <span className="tooltiptext">
                                    {' '}
                                    <p>
                                        Device <br /> connectivity
                                    </p>
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },

        showModal: (data) => {
            dispatch(showModal(data))
        },
        deviceMismatch: (data) => {
            dispatch(deviceMismatch(data))
        },
        deviceDetail: (data) => {
            dispatch(deviceDetails(data))
        },
        DeselectedComponent: (data) => {
            dispatch(selecteInternalAcessories(data))
        },
        graphPlotStorageReset: (data) => {
            dispatch(graphPlotStorage(data))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProgramHeader)
