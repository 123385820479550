import { useState } from 'react'
import './VerticalSlider.css'

function HorizontalSlider(props) {
    const {
        servoKiy,
        min,
        max,
        value,
        handelSliderChange,
        onMouseUpHandel,
        direction,
    } = props
    // /height: '0px'
    return (
        <div style={{ position: 'relative' }}>
            <input
                style={direction == 'right-left' ? { direction: 'rtl' } : {}}
                className="horizontal_Slider"
                type="range"
                value={value}
                min={min}
                max={max}
                onChange={(e) => {
                    handelSliderChange(servoKiy, e.target.value)
                }}
                onMouseUp={() => {
                    setTimeout(() => {
                        onMouseUpHandel()
                    }, 200)
                }}
            />
            <div className="horizontal_slider__track" />
        </div>
    )
}

export default HorizontalSlider
