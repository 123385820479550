const connectedDevice = ['Hexapod'].includes(
    sessionStorage.getItem('connectedDevice')
)
    ? sessionStorage.getItem('connectedDevice').toUpperCase()
    : 'HUMANOID'

const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'

const DEFAULTSERVOVALUES = {
    HUMANOID0: {
        1: 500,
        2: 387,
        3: 500,
        4: 593,
        5: 500,
        6: 575,
        7: 800,
        8: 724,
        9: 500,
        10: 612,
        11: 500,
        12: 406,
        13: 500,
        14: 425,
        15: 200,
        16: 275,
        H: 0,

        T: 1000,
    },
    HUMANOID1: {
        1: 2048,
        2: 1585,
        3: 2048,
        4: 2428,
        5: 2048,
        6: 2355,
        7: 3276,
        8: 2965,
        9: 2048,
        10: 2506,
        11: 2048,
        12: 1662,
        13: 2048,
        14: 1740,
        15: 819,
        16: 1126,
        H: 90,

        T: 1000,
    },
    HUMANOID2: {
        1: 2048,
        2: 1585,
        3: 2048,
        4: 2428,
        5: 2048,
        6: 2355,
        7: 3276,
        8: 2965,
        9: 2048,
        10: 2506,
        11: 2048,
        12: 1662,
        13: 2048,
        14: 1740,
        15: 819,
        16: 1126,
        H: 90,

        T: 1000,
    },
    HEXAPOD1: {
        1: 2048,
        2: 1277,
        3: 819,
        4: 2048,
        5: 1277,
        6: 819,
        7: 2048,
        8: 1277,
        9: 819,
        10: 2048,
        11: 2818,
        12: 3276,
        13: 2048,
        14: 2818,
        15: 3276,
        16: 2048,
        17: 2818,
        18: 3276,
        T: 1000,
    },
    HEXAPOD2: {
        1: 2048,
        2: 1277,
        3: 819,
        4: 2048,
        5: 1277,
        6: 819,
        7: 2048,
        8: 1277,
        9: 819,
        10: 2048,
        11: 2818,
        12: 3276,
        13: 2048,
        14: 2818,
        15: 3276,
        16: 2048,
        17: 2818,
        18: 3276,
        T: 1000,
    },
    KLAW1: {
        // 1: 500,
        // 2: 500,
        1: 150,
        2: 150,
        3: 150,
        4: 150,
        5: 160,
    },
}
// export const DEFAULTPWMVALUES = {
//     1: 1000,
//     2: 1000,
//     3: 1000,
//     4: 1000,
//     5: 1000,
//     6: 1000,
// }

const allServos = {
    HUMANOID: ['T', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 'H'],
    HEXAPOD: [
        'T',
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
    ],
    KLAW: [],
}
const minMaxZing = {
    accXmin: ['2', '1'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    )
        ? -32768
        : -30000,
    accXmax: ['2', '1'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    )
        ? 32768
        : 30000,

    gyroXmin: ['2', '1'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    )
        ? -2500
        : -30000,
    gyroXmax: ['2', '1'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    )
        ? 2500
        : 30000,
    headUltraSonicMax: ['2', '1'].includes(
        (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
    )
        ? 400
        : 400,
}
const SINGLEACTIONSTATE = {}
const COMMONACTIONSTATE = {}

allServos[connectedDevice].map((servoId) => {
    SINGLEACTIONSTATE[servoId] = {
        isEnabled: false,
        value: DEFAULTSERVOVALUES[connectedDevice + deviceVersion[0]][servoId],
        ...(servoId === 'H' && {
            speed: 10,
        }),
    }

    COMMONACTIONSTATE[servoId] = { isSelected: false }

    if (servoId == 'T') COMMONACTIONSTATE[servoId].isSelected = true
})
console.log(SINGLEACTIONSTATE)

export const CREATEACTIONSTATE = {
    commonActionState: COMMONACTIONSTATE,
    allActions: [], //{ id: '9d9d', data: { ...SINGLEACTIONSTATE } }
}

export { SINGLEACTIONSTATE, DEFAULTSERVOVALUES, minMaxZing, COMMONACTIONSTATE }

export const ZING_AG_DATA = {
    1: { slot: 1, name: 'new_action_group1', isEmpty: true, data: null },
    2: { slot: 2, name: 'new_action_group2', isEmpty: true, data: null },
    3: { slot: 3, name: 'new_action_group3', isEmpty: true, data: null },
    4: { slot: 4, name: 'new_action_group4', isEmpty: true, data: null },
    5: { slot: 5, name: 'new_action_group5', isEmpty: true, data: null },
}

export const MINARRAYFORZINGACTION = [
    1600, 400, 400, 100, 1600, 500, 500, 0, 1200, 500, 500, 800, 500, 500, 500,
    0,
]

export const MAXARRAYFORZINGACTION = [
    2900, 3600, 3600, 3300, 3500, 3600, 3600, 4095, 2400, 3700, 3700, 4000,
    2500, 3500, 3500, 4095,
]

export const MINARRAYFORHEXAPODACTION = [
    400, 440, 300, 500, 440, 320, 360, 440, 420, 360, 400, 0, 360, 360, 0, 360,
    360, 0,
]

export const MAXARRAYFORHEXAPODACTION = [
    3700, 3670, 4095, 3700, 3730, 4095, 3670, 3730, 4095, 3700, 3580, 3760,
    3620, 3610, 3760, 3670, 3570, 3660,
]
