// import StickyBox from 'react-sticky-box'
import { connect } from 'react-redux'
import horizontalReadBodyStyle from './HorizontalreadBody.module.css'
import './hh.css'
import './table.css'
import { useEffect, useRef, useState } from 'react'
import { SINGLEACTIONSTATE } from '../defaultData'
// <link rel="stylesheet" href="styles.css" /> <script src="script.js"></script>
function HorizontalReadBody(props) {
    const {
        worker,
        servoDetailsPopupOpen,
        createActionState,
        timerValue,
        isEnableReadServo,
        setCreateActionState,
        setAllActions,
    } = props
    const [horizontalAllActions, setHorizontalAllActions] = useState(
        createActionState.allActions
    )
    const [storeAllActions, setStoreAllActions] = useState([])

    const connectedDevice = sessionStorage.getItem('connectedDevice')

    const tableRef = useRef(null)

    const rowServoNames = Object.keys(createActionState.commonActionState)

    //this function work when new cloumn add to the table it allwes scroll to right end
    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollLeft = tableRef.current.scrollWidth
        }
    }, [horizontalAllActions])

    //red the servo value from zing
    useEffect(() => {
        //read data from serial worker
        const servoRead = (e) => {
            if (e.data.type === 'servoRead') {
                let servoesValueArr = []
                if (connectedDevice === 'Hexapod') {
                    servoesValueArr = e.data.value.splice(4, 36)
                } else {
                    servoesValueArr = e.data.value.splice(4, 32)
                }

                let allActions = [...horizontalAllActions]
                let time = 0
                if (allActions.length > 0) {
                    time = allActions.at(-1).time + timerValue
                }

                let newAction = {
                    // id: Math.random().toString(16).slice(2, 6),
                    id: Math.random().toString().slice(2, 17),
                    data: JSON.parse(JSON.stringify(SINGLEACTIONSTATE)),
                    time: time,
                }

                for (let i = 0; i < servoesValueArr.length; i += 2) {
                    newAction.data[(i + 2) / 2].isEnabled = true
                    newAction.data[(i + 2) / 2].value =
                        (servoesValueArr[i + 1] << 8) | servoesValueArr[i]
                }

                //set the time for each action
                newAction.data['T'].isEnabled = true
                newAction.data['T'].value = timerValue

                allActions.push(newAction)
                if (allActions.length > 150) {
                    let extractActions = allActions.splice(0, 50) //150, 50
                    setStoreAllActions([...storeAllActions, ...extractActions])
                }
                setHorizontalAllActions(allActions)
            }
        }
        worker.addEventListener('message', servoRead)
        return () => worker.removeEventListener('message', servoRead)
    }, [horizontalAllActions])

    //when click on servo stop add redux.
    useEffect(() => {
        if (!isEnableReadServo) {
            setAllActions([...storeAllActions, ...horizontalAllActions])
            setStoreAllActions([...storeAllActions, ...horizontalAllActions])

            setStoreAllActions([])
        }
    }, [isEnableReadServo])
    //when change on the redux re rander the table.
    useEffect(() => {
        setHorizontalAllActions([...createActionState.allActions])
    }, [createActionState.allActions])

    // Initialize T accumulation for showing the time
    let accumulatedTValue = 0

    return (
        <div className={horizontalReadBodyStyle.horizontalBody}>
            {/* ----------------this method is populat the table used dive---------------------- */}
            {/* <div id="app1">
                <div id="container1">
                    <div className="columnStyle column1st">
                        <div className="cell1">Servo1</div>
                        <div className="cell1">Servo2</div>
                        <div className="cell1">Servo3</div>
                        <div className="cell1">Servo4</div>
                        <div className="cell1">Servo5</div>
                        <div className="cell1">Servo6</div>
                        <div className="cell1">Servo7</div>
                        <div className="cell1">Servo8</div>
                        <div className="cell1">Servo9</div>
                        <div className="cell1">Servo10</div>
                        <div className="cell1 rowlast"></div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell">
                            <p className="cell_p">5000</p>
                        </div>
                        <div className="cell rowlast">action1</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action2</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action3</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action4</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action4</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action4</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action4</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9iiiiiii</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action8</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9iiiiiii</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action8</div>
                    </div>
                    <div className="columnStyle ">
                        <div className="cell">M1</div>
                        <div className="cell">M2</div>
                        <div className="cell">M3</div>
                        <div className="cell">M4</div>
                        <div className="cell">M5</div>
                        <div className="cell">M6</div>
                        <div className="cell">M7</div>
                        <div className="cell">M8</div>
                        <div className="cell">M9iiiiiii</div>
                        <div className="cell">M10</div>
                        <div className="cell rowlast">action8</div>
                    </div>
                </div>
                
            </div> */}

            <div ref={tableRef} id="table-scroll" className="table-scroll">
                <table id="main-table" className="main-table">
                    {/* <thead>
                        <tr>
                            <th scope="col">Header 1</th>
                            <th scope="col">Header 2</th>
                            <th scope="col">Header 3 with longer content</th>
                            <th scope="col">Header 4 text</th>
                            <th scope="col">Header 5</th>
                            <th scope="col">Header 6</th>
                            <th scope="col">Header 7</th>
                            <th scope="col">Header 8</th>
                        </tr>
                    </thead> */}

                    <tbody>
                        {rowServoNames.map((key) => {
                            if (key == 'T') {
                                return
                            }
                            return (
                                <tr key={key}>
                                    <th>
                                        <p
                                            style={{
                                                width: '80px',
                                                marginBottom: '0px',
                                            }}
                                        >
                                            {'Servo ' + key}
                                        </p>
                                    </th>

                                    {horizontalAllActions?.map(
                                        (action, actionNumber) => (
                                            <td key={action.id + key}>
                                                <p
                                                    className="cell_p"
                                                    onClick={() =>
                                                        servoDetailsPopupOpen(
                                                            // accumulatedTValue,
                                                            key,
                                                            actionNumber
                                                        )
                                                    }
                                                >
                                                    {action.data[key]?.value}
                                                </p>
                                                {/* give the defalt value of the servo */}
                                            </td>
                                        )
                                    )}
                                </tr>
                            )
                        })}
                        {/* ------------------------------------------------------- */}
                        {/* {transposedArray.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, colIndex) => {
                                    if (colIndex == 0) {
                                        return (
                                            <th key={colIndex}>
                                                <p
                                                    style={{
                                                        width: '80px',
                                                        marginBottom: '0px',
                                                    }}
                                                >
                                                    {cell}
                                                </p>
                                            </th>
                                        )
                                    } else {
                                        return (
                                            <td key={colIndex}>
                                                {' '}
                                                <p
                                                    className="cell_p"
                                                    onClick={
                                                        servoDetailsPopupOpen
                                                    }
                                                >
                                                    {cell}
                                                </p>
                                            </td>
                                        )
                                    }
                                })}
                            </tr>
                        ))} */}

                        {/* <tr>
                                            <th>Servo 14</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 15</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 16</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 17</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 18</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 19</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 20</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr>

                                        <tr>
                                            <th>Servo 21</th>
                                            <td>Cell content</td>
                                            <td>Cell content longer</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                            <td>Cell content</td>
                                        </tr> */}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>
                                <p
                                    style={{
                                        marginBottom: '0px',
                                        width: '80px',
                                    }}
                                >
                                    Time <br />
                                    Milisecond
                                </p>
                            </th>
                            {horizontalAllActions.map((action, ind) => {
                                // accumulatedTValue += action.data['T']?.value
                                // console.log(action)
                                return (
                                    <td key={action.id + 'T'}>{action.time}</td>
                                )
                            })}
                        </tr>
                        {/* ------------------------------------------- */}
                        {/* <tr>
                            {servoValueArr.map((ele, columInd) => {
                                if (columInd == 0) {
                                    return <th>Time</th>
                                } else {
                                    return <td>{500 * (columInd - 1)}</td>
                                }
                            })}
                        </tr> */}
                    </tfoot>
                </table>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        setAllActions: (data) => {
            dispatch({ type: 'ALL_ACTIONS', payload: data })
        },
        setCreateActionState: (data) => {
            dispatch({ type: 'CREATE_ACTION_STATE', payload: data })
        },
        setDefaultCreateActionState: () => {
            dispatch({ type: 'REMOVE_CREATE_ACTION_STATE' })
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(HorizontalReadBody)
