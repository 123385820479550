import React, {
    useState,
    useLayoutEffect,
    useEffect,
    useRef,
    version,
} from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import Popup from './Popup'
import './myflowchart.css'
import Panel1 from '../logic/pannel/'
import { connect } from 'react-redux'
import { v4 } from 'uuid'
import { CustomDragLayer } from './CustomDragLayer.js'
import Slider from 'react-slick'
import closeImg from '../../../../Assets/img/close.png'
import flowchartBg from '../../../../Assets/Bisoft_UI/Help screen/Program/flowcharthelp.png'
import strokeImg from '../../../../Assets/Bisoft_UI/Buttons/Inactive with shadow/help@2x.png'

import _ from 'lodash'
import ReactFlow, {
    ReactFlowProvider,
    addEdge,
    removeElements,
    Handle,
    getOutgoers,
} from 'react-flow-renderer'

import start from '../../../../Assets/flowchart/start.png'
import cursor from '../../../../Assets/flowchart/cursor.cur'
import fxvariable from '../../../../Assets/With name/Group 5544@2x.png'
import wait from '../../../../Assets/flowchart/wait.png'
import condition2 from '../../../../Assets/With name/Group 5545@2x.png'
import loop from '../../../../Assets/flowchart/repeat.png'
import repeat from '../../../../Assets/flowchart/loop.png'
import end from '../../../../Assets/flowchart/stopButton.png'
import Sidebar from './Sidebar'
import { useLocalStorage } from '../../../LocalStorage/LocalStorage'
import './dnd.css'
import '../../style.css'

import renderPrgImage from '../../../../source/programImg'
import flowchartImg from '../../../../Assets/img/simulate bar@2x.png'
import secondaryImg from '../../../../Assets/img/save - secondary.png'

import getBytes from '../../../Simulate/BytesGeneration/convertBytes'
import { webSerialConnect } from '../../../../redux/actions/index'
import { useDrop, useDrag } from 'react-dnd-latest'
import clearImage from '../../../../Assets/flowchart/clearProgram _inA.png'
import alignImage from '../../../../Assets/flowchart/button 52x52 - stroke.png'
import { sendBytes } from '../../../ReusableComponents/ByteTransfer'
import {
    continousPABytesReadToggle,
    readBytes,
} from '../../../ReusableComponents/ByteTransfer/byteTransfer'
import session from 'redux-persist/lib/storage/session'

import DialogModal from '../../../ReusableComponents/DialogModal/DialogModal'
import { PORTDATA } from '../../../Humanoid/defaultData'

import leftArrow from '../../../../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../../../../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import ProgramHeader from '../../../ReusableComponents/Header/ProgramHeader'
import StartPanel from '../logic/pannel/start/StartPanel'
import cacheAssets from '../../../../utils/cacheAssets'
import unicodeToChar from '../../../../utils/unicodeToChar'
import { generateDeviceDataKey } from '../../../ReusableComponents/ByteTransfer/utils'

let modal
const onDragOver = (event) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
}

let id = parseInt(sessionStorage.getItem('flowchart-elements-id')) || 6

const getId = () => `${id++}`

var Panel = Panel1
function SampleNextArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0 0 0 32px',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div>
            <div
                className={'slick-arrow'}
                style={{
                    ...style,
                    // right: "-290px",
                    // height: '25%',
                    zIndex: '1000',
                    // right: '-17%',
                    left: '100%',
                    width: '25%',
                    cursor: 'default',
                }}
            >
                <img
                    src={rightArrow}
                    style={{ width: '30%', cursor: 'grab' }}
                    onClick={onClick}
                ></img>
            </div>
        </div>
    )
}

function SamplePrevArrow(props) {
    const { className, onClick } = props
    var style = {
        fontSize: '0',
        lineHeight: '0',
        position: 'absolute',
        top: '55%',
        display: 'block',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        color: 'transparent',
        border: 'none',
        outline: 'none',
        background: 'transparent',
    }
    return (
        <div
            className={'slick-arrow'}
            style={{
                ...style,
                left: '16%',
                display: 'block',
                width: '25%',
                zIndex: '1000',
                cursor: 'default',
            }}
        >
            <img
                src={leftArrow}
                style={{ width: '30%', cursor: 'grab', zIndex: '1000' }}
                onClick={onClick}
            ></img>
        </div>
    )
}

let prevElement = []

sessionStorage.setItem('planeOffset', null)
let element
const DnDFlow = (props) => {
    const imageRef = useRef(null)
    let microPython
    try {
        microPython = JSON.parse(sessionStorage.getItem('microPython'))
    } catch (e) {
        microPython = false
    }

    const text = (type, _id) => {
        if (type == 'start') {
            // console.log("start");

            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${start})`,
                            bottom: '38px',
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'if') {
            // console.log("if");
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${condition2})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                    <Handle
                        type="source"
                        position="bottom"
                        style={{ left: 90, top: 35 }}
                        id="d"
                    />
                    <Handle
                        position="left"
                        style={{ left: 140, top: 10 }}
                        id="rYes"
                    />
                    <Handle
                        type="source"
                        position="left"
                        style={{ left: 140, top: 30 }}
                        id="rNo"
                    />
                </>
            )
        }
        if (type == 'wait') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${wait})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                    <Handle
                        position="bottom"
                        style={{ left: 90, top: 35 }}
                        id="d"
                    />
                </>
            )
        }
        if (type == 'output') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${fxvariable})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                    <Handle
                        type="source"
                        position="bottom"
                        style={{ left: 90, top: 35 }}
                        id="d"
                    />
                </>
            )
        }
        if (type == 'loop') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${loop})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                    <Handle
                        type="source"
                        position="bottom"
                        style={{ left: 90, top: 35 }}
                        id="d"
                    />
                    <Handle
                        type="source"
                        position="right"
                        style={{ left: 140, top: 20 }}
                        id="r"
                    />
                </>
            )
        }
        if (type == 'end/repeat') {
            return (
                <>
                    <div
                        ref={imageRef}
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${repeat})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'end') {
            return (
                <>
                    <div
                        ref={imageRef}
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${end})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'end1') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${end})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
    }

    const text1 = (type, _id) => {
        if (type == 'start') {
            // console.log("start");

            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${start})`,
                            bottom: '38px',
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'if') {
            // console.log("if");
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${condition2})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'wait') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${wait})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'output') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${fxvariable})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'loop') {
            return (
                <>
                    <div
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${loop})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'end/repeat') {
            return (
                <>
                    <div
                        ref={imageRef}
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${repeat})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'end') {
            return (
                <>
                    <div
                        ref={imageRef}
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${end})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
        if (type == 'endl') {
            return (
                <>
                    <div
                        ref={imageRef}
                        className="Image-render"
                        style={{
                            backgroundImage: `url(${end})`,
                        }}
                        id="image-render"
                        key={v4()}
                    ></div>
                </>
            )
        }
    }
    const history = useHistory()
    const [feedbackMsg, setFeedBackMsg] = useState('')
    const [reactFlowInstance, setReactFlowInstance] = useState()

    const [passEnd, setPassEnd] = useState()
    const [showPopup, setShowPopUp] = useState(false)
    const [updateFWpopUp, SetUpdateFWpopUp] = useState(false)
    const [data, setData] = useLocalStorage('element_data')
    const [modalType, setModalType] = useState('')
    const [elements, setElements] = useState(() => {
        if (JSON.parse(sessionStorage.getItem('flowchart-elements')) !== null) {
            const a = JSON.parse(sessionStorage.getItem('flowchart-elements'))
            for (let i in a) {
                if (a[i].id === '0') {
                    let pushingElement = {
                        id: '0',
                        position: a[i].position,
                        type: a[i].type,
                        data: {
                            elType: a[i].data.elType,
                            specificElType: a[i].data.specificElType,
                            label: text(`${a[i].data.specificElType}`, '0'),
                            data_id: [0],
                        },
                    }
                    prevElement[0] = pushingElement
                }
                if (a[i].id != '0' && a[i].id.search('reactflow') == -1) {
                    let pushingElement = {
                        id: a[i].id,
                        position: a[i].position,
                        type: a[i].type,
                        data: {
                            elType: a[i].data.elType,
                            specificElType: a[i].data.specificElType,
                            label: text(`${a[i].data.specificElType}`, a[i].id),
                            data_id: a[i].data.data_id,
                        },
                    }
                    prevElement.push(pushingElement)
                } else if (
                    a[i].id.search('reactflow') !== -1 &&
                    a[i].id != '0'
                ) {
                    let pushingElement = {
                        source: a[i].source,
                        sourceHandle: a[i].sourceHandle,
                        target: a[i].target,
                        targetHandle: a[i].targetHandle,
                        id: a[i].id,
                    }
                    prevElement.push(pushingElement)
                }
            }
            return prevElement
        } else {
            prevElement = []
            let pushingElement = {
                id: '0',

                position: { x: 500, y: 65 },
                type: `input`,
                data: {
                    label: text1(`start`, 0),
                    elType: 'node',
                    specificElType: `start`,
                    data_id: [0],
                },
            }
            prevElement.push(pushingElement)
            return prevElement
        }
    })
    const [endIf_loop, setendIf_loop] = useState(false)
    const [clearProgramModal, setClearProgramModal] = useState(false)
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const deviceVersion = sessionStorage.getItem('deviceVersion')
    function click(x, y) {
        var ev = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
            screenX: x,
            screenY: y,
        })

        var el = document.elementFromPoint(x, y)

        el.dispatchEvent(ev)
    }
    const workerCondition =
        connectedDevice == 'Ace' && deviceVersion.startsWith('1')
            ? true
            : ['Hexapod', 'Humanoid'].includes(connectedDevice) &&
              deviceVersion.startsWith('2')
            ? true
            : false
    useLayoutEffect(() => {
        return () => {
            prevElement = elements
        }
    })
    useEffect(() => {
        prevElement = prevElement.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
        )
        setElements(prevElement)
    }, [])
    useEffect(() => {
        const initialStartButtonState = [
            { name: 'Bicounter1', id: 'Bic1', isEnabled: false },
            { name: 'Bicounter2', id: 'Bic2', isEnabled: false },
            { name: 'BT TX', id: 'BtTx', isEnabled: false },
            { name: 'Biflag1', id: 'Bif1', isEnabled: false },
            { name: 'Biflag2', id: 'Bif2', isEnabled: false },
            { name: 'BT RX', id: 'BtRx', isEnabled: false },
            { name: 'BT Remote', id: 'BtRte', isEnabled: false },
            { name: 'USB TX', id: 'UsbTx', isEnabled: false },
            { name: 'USB RX', id: 'UsbRx', isEnabled: false },
        ]

        const isStartButtonStatePresent =
            sessionStorage.getItem('startButtonState') || false
        if (isStartButtonStatePresent) return
        sessionStorage.setItem(
            'startButtonState',
            JSON.stringify(initialStartButtonState)
        )
    }, [])

    useEffect(() => {
        if (endIf_loop == true) {
            console.log('gsk end repeat enter@@', elements)
            setElements((els) =>
                els.map((el) => {
                    if (
                        el.id ===
                        `${elements[Object.keys(elements).length - 2].id}`
                    ) {
                        console.log('gsk end repeat enter@@@@@@@@@@@@@@@@')
                        // it's important that you create a new object here
                        // in order to notify react flow about the change
                        console.log('@@done')
                        el.data.specificElType = 'end1'

                        el.data.label = text(`end`, params.target)
                    }

                    return el
                })
            )
            setendIf_loop(false)
        }

        console.log('rerendering!!!!!')
        let storeElements = _.cloneDeep(elements)
        for (let i = 0; i < storeElements.length; i++) {
            if (
                storeElements[i].data != null &&
                storeElements[i].data !== undefined
            ) {
                if (
                    storeElements[i].data.label != null &&
                    storeElements[i].data.label !== undefined
                ) {
                    delete storeElements[i].data.label
                }
            }
        }
        console.log(storeElements)
        storeElements = storeElements.filter(
            (item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
        )
        sessionStorage.setItem(
            'flowchart-elements',
            JSON.stringify(storeElements)
        )
        if (parseInt(sessionStorage.getItem('flowchart-elements-id')) > id) {
            id = parseInt(sessionStorage.getItem('flowchart-elements-id'))
        } else {
            sessionStorage.setItem('flowchart-elements-id', JSON.stringify(id))
        }
    })

    useEffect(() => {
        element = elements
    }, [elements])

    const FWupdatePopUp = (value) => {
        SetUpdateFWpopUp(value)

        setTimeout(() => {
            SetUpdateFWpopUp(false)
        }, 10000)
    }

    const onConnect = async (params, top, down) => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        if (params.sourceHandle == undefined) params.sourceHandle = 'd'

        if (top === undefined && down === undefined) {
            await element.map((e) => {
                if (e.id === params.source) top = e
                if (e.id === params.target) down = e
            })
        }

        var index1 = await element.findIndex(
            (e) =>
                e.source === params.source &&
                e.sourceHandle === params.sourceHandle
        )
        var index2 = await element.findIndex(
            (e) =>
                e.target === params.target &&
                e.targetHandle === params.targetHandle
        )

        if (index1 == -1 && index2 == -1 && params.target != params.source) {
            if (await connectMinning(down, top)) return
            await setElements((elements) => addEdge(params, elements))

            let rev = await nodeGetReverse(
                [elements[0]],
                elements.filter((e) => e.id === params.source)[0]
            )

            let revValue = await elements.filter(
                (e) => e.id === params.target
            )[0]
            if (
                params.sourceHandle == 'rYes' ||
                params.sourceHandle == 'rNo' ||
                params.sourceHandle == 'r'
            )
                rev = 'false'

            if (
                rev == 'false' &&
                revValue == undefined &&
                down.data.specificElType == 'end/repeat'
            ) {
                setendIf_loop(true)

                setTimeout(() => {
                    console.log('gsk end repeat enter', rev, down, elements)
                }, 1000)
            } else if (
                rev == 'false' &&
                revValue.data.specificElType == 'end/repeat'
            ) {
                await setElements((els) =>
                    els.map((el) => {
                        if (el.id === `${params.target}`) {
                            el.data.specificElType = 'end1'

                            el.data.label = text(`end`, params.target)
                        }
                        return el
                    })
                )
            }
            click(260, 200)
        }
    }
    //circular bug fixing code
    const connectMinning = async (ele, data) => {
        let a = []
        await nodeGet([ele], a)
        await nodeAddIfNo(a)
        await subprogramRecursive(a)
        let y = await connectMineSet(a, data)

        console.log(a, y, 'mine')
        return y
    }
    const connectMineSet = async (a, data) => {
        let y = false
        for (let i in a) {
            if (a[i].id == data.id) return true
            if (
                a[i].data.specificElType === 'if' ||
                a[i].data.specificElType === 'ifNo' ||
                a[i].data.specificElType === 'loop'
            )
                y = await connectMineSet(a[i].subprogram, data)
            if (y) return y
        }
        return y
    }

    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els))
    const onLoad = (_reactFlowInstance) =>
        setReactFlowInstance(_reactFlowInstance)
    const flag = 0

    const handler = async (e, chk) => {
        sessionStorage.setItem('endStatus' + e, chk)
        console.log(imageRef)
        imageRef.current.style.backgroundImage = `url(${
            chk == 'end' ? end : repeat
        })`
        await setElements((els) =>
            els.map((el) => {
                if (el.id === `${e}`) {
                    el.data.specificElType = chk == 'end' ? 'end' : 'end/repeat'
                    el.data.label = (
                        <>
                            <div
                                className="Image-render end"
                                ref={imageRef}
                                style={{
                                    backgroundImage: `url(${
                                        chk == 'end' ? end : repeat
                                    })`,
                                }}
                                id="image-render"
                                key={e}
                            ></div>
                        </>
                    )
                }
                return el
            })
        )
    }
    //function to check if the droped node should be loop or end
    const checkOnDrop = async (e) => {
        const connect_line = JSON.parse(
            sessionStorage.getItem('application/reactflow/connect')
        )
        console.log(
            connect_line == -1,
            e != 'end/repeat',
            connect_line,
            'XXXXXXXXXXXX',
            e
        )
        if (connect_line == -1 && e == 'end/repeat') return true
        console.log('XXXXXXXXXXXX11111', e)
        if (connect_line == -1 || e != 'end/repeat') return false
        if (connect_line.source === '0') return true
        console.log('XXXXXXXXXXXX22222', e)
        if (
            connect_line.sourceHandle == 'rYes' ||
            connect_line.sourceHandle == 'rNo' ||
            connect_line.sourceHandle == 'r'
        )
            return false
        console.log('XXXXXXXXXXXX333', e)
        const filteredArray = elements.filter((obj) =>
            obj.hasOwnProperty('type')
        )
        const element = elements.filter(
            (e) => e.targetHandle === undefined || e.sourceHandle === 'd'
        )
        let ckeck = true
        let n = await getOutgoers(filteredArray[0], element)
        if (n.length == 0) return false
        for (let i in filteredArray) {
            ckeck = n[0]

            n = await getOutgoers(n[0], element)

            if (n.length == 0) break
        }
        console.log(ckeck, connect_line, 'gsk is cool')
        if (ckeck.id == connect_line.source) return true
        return false
    }
    // shashank onDrop
    const onDrop = async (event) => {
        try {
            event.preventDefault()
            if (reactFlowInstance) {
                const type = event.dataTransfer.getData('application/reactflow')
                if (type == null || type == '') return
                const position = reactFlowInstance.project({
                    x: event.clientX - 230,
                    y: event.clientY - 110,
                })
                var nodeType
                if (type == 'start') nodeType = 'input'
                else if (type == 'end/repeat') nodeType = 'output'
                else nodeType = 'output'
                let newNode
                if (type == 'if' || type == 'loop') {
                    newNode = await {
                        id: `${getId()}`,
                        subprogram: [],
                        position,
                        type: `${nodeType}`,
                        data: {
                            label: text(`${type}`, id),
                            elType: 'node',
                            specificElType: `${type}`,
                            data_id: [id - 1],
                        },
                    }
                } else if (type == 'end/repeat') {
                    const check = await checkOnDrop(type)

                    console.log('gsk is coool', check)
                    console.log('BBBBBBBBBBBBBB333333333', !check)
                    newNode = await {
                        id: `${getId()}`,
                        position,
                        type: `${nodeType}`,
                        data: {
                            label: text(`${!check ? 'end1' : type}`, id),
                            elType: 'node',
                            specificElType: `${!check ? 'end1' : type}`,
                            data_id: [id - 1],
                        },
                    }
                } else {
                    newNode = await {
                        id: `${getId()}`,
                        position,
                        type: `${nodeType}`,
                        data: {
                            label: text(`${type}`, id),
                            elType: 'node',
                            specificElType: `${type}`,
                            data_id: [id - 1],
                        },
                    }
                }

                /////////////////////////////////////////////
                ///////////// Humanoid code /////////////////
                /////////////////////////////////////////////
                if (
                    connectedDevice == 'Humanoid' ||
                    connectedDevice == 'Hexapod' ||
                    connectedDevice == 'Klaw'
                ) {
                    const portData = JSON.parse(
                        sessionStorage.getItem('humanoidPortData')
                    )
                    const programData = JSON.parse(
                        sessionStorage.getItem('humanoidProgramData')
                    )

                    let stateData = {}
                    Object.entries(portData).map((port) => {
                        stateData[port[0]] = {
                            name: portData[port[0]].name,
                            value: portData[port[0]].value,
                            isActionEnabled: portData[port[0]].isActionEnabled,
                        }

                        if (portData[port[0]].oledState)
                            stateData[port[0]].oledState =
                                portData[port[0]].oledState
                        if (portData[port[0]].rgbState)
                            stateData[port[0]].rgbState =
                                portData[port[0]].rgbState
                    })
                    let node = {
                        id: newNode.id,
                        type,
                        state: stateData,
                    }
                    if (type == 'if' || type == 'loop')
                        node.state = {
                            port: '',
                            condition: 'gt',
                            value: 0,
                            value1: 0,
                        }

                    sessionStorage.setItem(
                        'humanoidProgramData',
                        JSON.stringify({ ...programData, [node.id]: node })
                    )
                }

                /////////////////////////////////////////////
                /////////// Humanoid code ends //////////////
                /////////////////////////////////////////////

                await setElements([...elements, newNode])

                const connect_line = JSON.parse(
                    sessionStorage.getItem('application/reactflow/connect')
                )
                if (connect_line.index != -1) {
                    let connect = {
                        source: connect_line.source,
                        sourceHandle: connect_line.sourceHandle,
                        target: `${newNode.id}`,
                        targetHandle: null,
                    }
                    console.log('planegsk', connect_line, newNode.id, connect)
                    await onConnect(connect, connect_line.source, newNode)
                }
                console.log('node:===>postion===>', newNode.position)
            }
        } catch (e) {}
    }

    //shashank onDrag
    const onNodeDrag = async (event, node) => {
        event.preventDefault()

        if (event.clientX <= 30) {
            var index = await elements.findIndex(
                (e) => e.id === node.id && e.id !== '0'
            )

            if (index != -1) {
                setElements(
                    elements.filter(
                        (e) =>
                            e.id !== node.id &&
                            e.source !== node.id &&
                            e.target != node.id
                    )
                )
                if (elements[index].type == 'input') {
                    setTimeout(function () {
                        document
                            .querySelector('#foo')
                            .classList.remove('myClass')
                    }, 200)
                }
            }
        }

        let sourceHandle = 'd'

        for (let i = 0; i < Object.keys(elements).length; i++) {
            if (elements[i] != null && elements[i] != undefined) {
                if (elements[i].data != null && elements[i].data != undefined) {
                    if (
                        node.position.x <= elements[i].position.x + 60 &&
                        node.position.y <= elements[i].position.y + 40 &&
                        node.position.x >= elements[i].position.x - 60 &&
                        node.position.y >= elements[i].position.y - 40 &&
                        node.id != elements[i].id
                    ) {
                        if (
                            elements[i].id == '1' ||
                            elements[i].id == '2' ||
                            elements[i].id == '3' ||
                            elements[i].id == '4' ||
                            elements[i].id == '5'
                        )
                            break
                        //founded target node at index i
                        //addEdge to target node by checking x and y positions
                        let sourceHandle

                        if (elements[i].data.specificElType == 'loop') {
                            if (
                                node.position.x - elements[i].position.x <=
                                    57 + 5 &&
                                node.position.x - elements[i].position.x >=
                                    57 - 5
                            ) {
                                sourceHandle = await 'r'
                            } else if (
                                node.position.x - elements[i].position.x <= 5 &&
                                node.position.x - elements[i].position.x >= -5
                            ) {
                                sourceHandle = await 'd'
                            }
                            console.log('KHBIBHIKBIKBIK', sourceHandle)
                        }
                        if (elements[i].data.specificElType == 'if') {
                            if (
                                node.position.x - elements[i].position.x <= 5 &&
                                node.position.x - elements[i].position.x >= -5
                            )
                                sourceHandle = 'd'
                            if (
                                node.position.x - elements[i].position.x <=
                                    56 + 5 &&
                                node.position.x - elements[i].position.x >=
                                    56 - 5 &&
                                node.position.y - elements[i].position.y <=
                                    11 + 5 &&
                                node.position.y - elements[i].position.y >=
                                    11 - 5
                            ) {
                                sourceHandle = 'rYes'
                            } else if (
                                node.position.x - elements[i].position.x <=
                                    57 + 5 &&
                                node.position.x - elements[i].position.x >=
                                    57 - 5 &&
                                node.position.y - elements[i].position.y <=
                                    31 + 5 &&
                                node.position.y - elements[i].position.y >=
                                    31 - 5
                            ) {
                                sourceHandle = 'rNo'
                            }
                        }
                        /*     console.log("source : " + elements[i].id);
              console.log("target : " + `${node.id}`); */
                        let connect = {
                            source: elements[i].id,
                            sourceHandle: await sourceHandle,
                            target: `${node.id}`,
                            targetHandle: null,
                        }

                        if (
                            sourceHandle !== undefined ||
                            elements[i].data.specificElType == 'start' ||
                            elements[i].data.specificElType == 'output' ||
                            elements[i].data.specificElType == 'wait' ||
                            elements[i].data.specificElType == 'end/repeat'
                        ) {
                            await onConnect(connect, elements[i], node)
                        }

                        break
                    }
                }
            }
        }
    }

    const onNodeDragStop = async (event, node) => {
        await setElements((els) =>
            els.map((el) => {
                if (el.id === `${node.id}`) {
                    el.position.x = node.position.x
                    el.position.y = node.position.y
                }
                return el
            })
        )
    }

    var toDeleteEdge = null
    var selectedNode = null

    const onElementClick = async (event, element) => {
        if (element.id.search('react') != -1) {
            toDeleteEdge = element.id

            var index = await elements.findIndex((e) => e.id === toDeleteEdge)
            if (index != -1) {
                //edge is double clicked
                setElements(elements.filter((node) => node.id !== toDeleteEdge))
                toDeleteEdge = null
                console.log(toDeleteEdge)
            }

            console.log('to delete edge', toDeleteEdge)
            return
        }
        setModalType(element.data.specificElType)
        modal = element.id
        console.log('element clicked', element)
        if (element.data) {
            selectedNode = element.id
        }
        //if edge
        else {
            toDeleteEdge = element.id
        }
        return element.id
    }

    const onNodeMouseEnter = async (event, node) => {
        if (node.id === '1') {
            let prevElement = [
                {
                    id: getId(),
                    style: { zIndex: `${id}` },
                    position: { x: 30, y: 100 },
                    type: `output`,
                    data: {
                        label: text(`if`, id),
                        elType: 'node',
                        specificElType: `if`,
                    },
                },
                ...elements,
            ]
            await setElements(prevElement)
        } else if (node.id === '2') {
            let prevElement = [
                {
                    id: getId(),
                    style: { zIndex: `${id}` },
                    position: { x: 30, y: 180 },
                    type: `output`,
                    data: {
                        label: text(`loop`, id),
                        elType: 'node',
                        specificElType: `loop`,
                    },
                },
                ...elements,
            ]
            await setElements(prevElement)
        } else if (node.id === '3') {
            let prevElement = [
                {
                    id: getId(),
                    style: { zIndex: `${id}` },
                    position: { x: 30, y: 260 },
                    type: `output`,
                    data: {
                        label: text(`output`, id),
                        elType: 'node',
                        specificElType: `output`,
                    },
                },
                ...elements,
            ]
            setElements(prevElement)
        } else if (node.id === '4') {
            let prevElement = [
                {
                    id: getId(),
                    style: { zIndex: `${id}` },
                    position: { x: 30, y: 340 },
                    type: `output`,
                    data: {
                        label: text(`wait`, id),
                        elType: 'node',
                        specificElType: `wait`,
                    },
                },
                ...elements,
            ]
            await setElements(prevElement)
        } else if (node.id === '5') {
            let prevElement = [
                {
                    id: getId(),
                    style: { zIndex: `${id}` },
                    position: { x: 30, y: 420 },
                    type: `output`,
                    data: {
                        label: text(`end/repeat`, id),
                        elType: 'node',
                        specificElType: `end/repeat`,
                    },
                },
                ...elements,
            ]
            await setElements(prevElement)
        }
    }
    const onNodeMouseLeave = async (event, node) => {
        if (parseInt(node.id) > 5) {
            if (node.position.x === 30)
                setElements(elements.filter((e) => e.id !== node.id))
        }
    }
    const onDoubleClick = async (event, element) => {
        if (modalType === 'end/repeat') {
            Panel = Panel1('end')
            handleShow()
            setPassEnd(modal)
        } else if (modalType === 'wait') {
            Panel = Panel1('wait')
            handleShow()
        } else if (modalType === 'if') {
            Panel = Panel1('condition')
            handleShow()
        } else if (modalType === 'loop') {
            Panel = Panel1('loop')
            handleShow()
        } else if (modalType === 'output') {
            Panel = Panel1('output')
            handleShow()
        } else if (modalType === 'end') {
            Panel = Panel1('end')
            handleShow()
        } else if (modalType === 'start') {
            setShowStartModal(true)
        }

        var index = await elements.findIndex((e) => e.id === toDeleteEdge)
        if (index != -1) {
            //edge is double clicked
            setElements(elements.filter((node) => node.id !== toDeleteEdge))
            toDeleteEdge = null
            console.log(toDeleteEdge)
        }
    }

    const [show, setShow] = useState(false)
    const waitArray = (i) => {
        let a = []
        a.push(sessionStorage.getItem(`milliSec${i}`))
        a.push(sessionStorage.getItem(`sec${i}`))
        a.push(sessionStorage.getItem(`min${i}`))
        a.push(sessionStorage.getItem(`hr${i}`))
        return a
    }
    const loopArray = (i) => {
        return sessionStorage.getItem(`loop${i}`)
    }
    const outputArray = (i) => {
        let a = []
        //DONT ALTER THE ORDER of the array
        a.push(sessionStorage.getItem(`a1${i}`))
        a.push(sessionStorage.getItem(`a2${i}`))
        // if (sessionStorage.getItem(`BMP3`))
        //     a.push(sessionStorage.getItem(`mp3${i}`))
        a.push(sessionStorage.getItem(`b1${i}`))
        a.push(sessionStorage.getItem(`b2${i}`))
        a.push(sessionStorage.getItem(`c1${i}`))
        a.push(sessionStorage.getItem(`c2${i}`))
        a.push(sessionStorage.getItem(`d1${i}`))
        a.push(sessionStorage.getItem(`d2${i}`))
        a.push(sessionStorage.getItem(`t0${i}`))
        a.push(sessionStorage.getItem(`t1${i}`))
        a.push(sessionStorage.getItem(`t2${i}`))
        a.push(sessionStorage.getItem(`eyel${i}`))
        a.push(sessionStorage.getItem(`re${i}`))
        a.push(sessionStorage.getItem(`buzz${i}`))
        a.push(sessionStorage.getItem(`sm1${i}`))
        a.push(sessionStorage.getItem(`sm2${i}`))
        a.push(sessionStorage.getItem(`sm3${i}`))
        a.push(sessionStorage.getItem(`sm4${i}`))
        a.push(sessionStorage.getItem(`a1Chk${i}`))
        a.push(sessionStorage.getItem(`a2Chk${i}`))
        // if (sessionStorage.getItem(`BMP3`))
        //     a.push(sessionStorage.getItem(`mp3Chk${i}`))
        a.push(sessionStorage.getItem(`b1Chk${i}`))
        a.push(sessionStorage.getItem(`b2Chk${i}`))
        a.push(sessionStorage.getItem(`c1Chk${i}`))
        a.push(sessionStorage.getItem(`c2Chk${i}`))
        a.push(sessionStorage.getItem(`d1Chk${i}`))
        a.push(sessionStorage.getItem(`d2Chk${i}`))
        a.push(sessionStorage.getItem(`t0Chk${i}`))
        a.push(sessionStorage.getItem(`t1Chk${i}`))
        a.push(sessionStorage.getItem(`t2Chk${i}`))
        a.push(sessionStorage.getItem(`eyelR${i}`))
        a.push(sessionStorage.getItem(`eyelG${i}`))
        a.push(sessionStorage.getItem(`eyelB${i}`))
        a.push(sessionStorage.getItem(`reR${i}`))
        a.push(sessionStorage.getItem(`reG${i}`))
        a.push(sessionStorage.getItem(`reB${i}`))
        a.push(sessionStorage.getItem(`buzzChk${i}`))
        a.push(sessionStorage.getItem(`s1Chk${i}`))
        a.push(sessionStorage.getItem(`s2Chk${i}`))
        a.push(sessionStorage.getItem(`s3Chk${i}`))
        a.push(sessionStorage.getItem(`s4Chk${i}`))
        if (JSON.parse(sessionStorage.getItem(`valRGB1${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB1${i}`)).r || 0)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB1${i}`)).g || 0)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB1${i}`)).b || 0)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB2${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB2${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB2${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB2${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB3${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB3${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB3${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB3${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB4${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB4${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB4${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB4${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB5${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB5${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB5${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB5${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB6${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB6${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB6${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB6${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB7${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB7${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB7${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB7${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB8${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB8${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB8${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB8${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB9${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB9${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB9${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB9${i}`)).b)
        }
        if (JSON.parse(sessionStorage.getItem(`valRGB10${i}`)) == null) {
            a.push(0)
            a.push(0)
            a.push(0)
        } else {
            a.push(JSON.parse(sessionStorage.getItem(`valRGB10${i}`)).r)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB10${i}`)).g)
            a.push(JSON.parse(sessionStorage.getItem(`valRGB10${i}`)).b)
        }
        a.push(sessionStorage.getItem(`frequencyBuzzCheck${i}`))
        a.push(sessionStorage.getItem(`toneBuzzCheck${i}`))
        a.push(sessionStorage.getItem(`t2Chk${i}`))
        a.push(sessionStorage.getItem(`t3${i}`))

        a.push(sessionStorage.getItem(`m1${i}`))
        a.push(sessionStorage.getItem(`m2${i}`))
        a.push(sessionStorage.getItem(`m3${i}`))
        a.push(sessionStorage.getItem(`m4${i}`))

        console.log(
            'output check completed gsk CALLED',
            a,
            sessionStorage.getItem(`BMP3`)
        )
        return a
    }
    const ifArray = (i) => {
        let a = []
        a.push(sessionStorage.getItem(`ifSelect${i}`))
        a.push(sessionStorage.getItem(`ifValue${i}`))
        a.push(sessionStorage.getItem(`ifValue2${i}`))
        a.push(sessionStorage.getItem(`gt${i}`))
        a.push(sessionStorage.getItem(`lt${i}`))
        a.push(sessionStorage.getItem(`bw${i}`))
        a.push(sessionStorage.getItem(`eq${i}`))
        a.push(sessionStorage.getItem(`ne${i}`))
        return a
    }
    //for humanoid creat if array
    const humanoidIfArray = (i) => {
        let humanoidProgramData = JSON.parse(
            sessionStorage.getItem('humanoidProgramData')
        )
        let humanoidPortData = JSON.parse(
            sessionStorage.getItem('humanoidPortData')
        )
        let a = []
        let nodeState = humanoidProgramData[i].state
        if (!nodeState.port || nodeState.port === 'battery') {
            return ['', 0, 0, true, false, false, false, false]
        }
        let portName = humanoidPortData[nodeState.port].name
        a.push(
            portName.replace(
                portName.charAt(0),
                portName.charAt(0).toLowerCase()
            )
        )
        a.push(nodeState.value)
        a.push(nodeState.value2)
        for (let i = 0; i < 5; i++) {
            a.push('false')
        }
        if (nodeState.condition === 'gt') {
            a[3] = 'true'
        }
        if (nodeState.condition === 'lt') {
            a[4] = 'true'
        }
        if (nodeState.condition === 'bw') {
            a[5] = 'true'
        }
        if (nodeState.condition === 'eq') {
            a[6] = 'true'
        }
        if (nodeState.condition === 'ne') {
            a[7] = 'true'
        }
        return a
    }
    const nodeGet = async (n, a) => {
        console.log('nodedebug   else  ', n[0], n)
        if (Object.keys(n).length === 0) return
        let element = await elements.filter(
            (e) => e.targetHandle === undefined || e.sourceHandle === 'd'
        )

        a.push(n[0])

        n = await getOutgoers(n[0], element)

        await nodeGet(n, a)
    }

    const nodeGetReverse = async (n, m) => {
        console.log('nodedebug   else  ', n[0], n, m, elements)
        if (Object.keys(n).length === 0) {
            console.log('flase++++++++')
            return 'false'
        } else if (n[0].id === m.id) {
            console.log('true++++++++')
            return 'true'
        }

        let element = await elements.filter(
            (e) => e.targetHandle === undefined || e.sourceHandle === 'd'
        )

        n = await getOutgoers(n[0], element)

        return await nodeGetReverse(n, m)
    }

    const portInfo = (n, m) => {
        let obj = null
        let startChar = n[0].toLowerCase()
        let portLowerCase = n.toLowerCase()
        let signalType = 'analog'
        ///////// Donot change the order of the IF conditional ////////
        if (JSON.parse(sessionStorage.getItem(`${n}`))) {
            if (JSON.parse(sessionStorage.getItem(`${portLowerCase}-I/O`))) {
                if (JSON.parse(sessionStorage.getItem(`${n[0]}MP3`)))
                    if (n == 'B1' || n == 'B')
                        obj = {
                            type: 'mp3',
                            index: 0,
                        }
                    else obj = null
                else if (JSON.parse(sessionStorage.getItem(`${n[0]}RGB`)))
                    if (n == 'B1' || n == 'B')
                        return (obj = {
                            type: 'RGB',
                            index: 0,
                        })
                    else obj = null
                else if (JSON.parse(sessionStorage.getItem(`${n[0]}OLED`))) {
                    if (n == 'D1' || n == 'B')
                        return (obj = {
                            type: 'OLED',
                            index: 0,
                        })
                    else if (n == 'DOLED')
                        return (obj = {
                            type: 'OLED',
                            index: 0,
                        })
                    else return (obj = null)
                } else if (JSON.parse(sessionStorage.getItem(`${n[0]}Ultra`))) {
                    if (n == 'A1' || n == 'C1') {
                        return (obj = {
                            type: 'ultrasonic_sensor',
                            index: 0,
                        })
                    } else return (obj = null)
                } else {
                    if (
                        JSON.parse(sessionStorage.getItem(`${n}Servo`)) ||
                        JSON.parse(sessionStorage.getItem(`${n}Servo`)) ||
                        JSON.parse(sessionStorage.getItem(`${n}Servo360`)) ||
                        JSON.parse(sessionStorage.getItem(`${n}Servo360`)) ||
                        JSON.parse(sessionStorage.getItem(`${n}Servo270`)) ||
                        JSON.parse(sessionStorage.getItem(`${n}Servo270`))
                    ) {
                        if (['A', 'B', 'C', 'D', 'E', 'F'].includes(n)) {
                            obj = {
                                type: 'dual_splitter',
                                index: 0,
                            }
                            return obj
                        } else if (
                            [
                                'A1',
                                'B1',
                                'C1',
                                'D1',
                                'E1',
                                'F1',
                                'S1',
                                'A2',
                                'B2',
                                'C2',
                                'D2',
                                'E2',
                                'F2',
                                'S2',
                            ].includes(n) &&
                            (JSON.parse(sessionStorage.getItem(`${n}Servo`)) ||
                                JSON.parse(sessionStorage.getItem(`${n}Servo`)))
                        ) {
                            obj = {
                                type: 'servo_motor',
                                index: 0,
                            }
                            return obj
                        } else if (
                            [
                                'A1',
                                'B1',
                                'C1',
                                'D1',
                                'E1',
                                'F1',
                                'S1',
                                'A2',
                                'B2',
                                'C2',
                                'D2',
                                'E2',
                                'F2',
                                'S2',
                            ].includes(n) &&
                            (JSON.parse(
                                sessionStorage.getItem(`${n}Servo270`)
                            ) ||
                                JSON.parse(
                                    sessionStorage.getItem(`${n}Servo270`)
                                ))
                        ) {
                            obj = {
                                type: 'servo_motor_270',
                                index: 0,
                            }
                            return obj
                        } else if (
                            [
                                'A1',
                                'B1',
                                'C1',
                                'D1',
                                'E1',
                                'F1',
                                'S1',
                                'A2',
                                'B2',
                                'C2',
                                'D2',
                                'E2',
                                'F2',
                                'S2',
                            ].includes(n) &
                            (JSON.parse(
                                sessionStorage.getItem(`${n}Servo360`)
                            ) ||
                                JSON.parse(
                                    sessionStorage.getItem(`${n}Servo360`)
                                ))
                        ) {
                            obj = {
                                type: 'servo_motor_360',
                                index: 0,
                            }
                            return obj
                        } else return (obj = null)
                    } else {
                        if (!['A', 'B', 'C', 'D', 'E', 'F'].includes(n))
                            if (
                                [null, undefined].includes(
                                    sessionStorage.getItem(`${n}DIGI`)
                                ) ||
                                !JSON.parse(sessionStorage.getItem(`${n}DIGI`))
                            ) {
                                return (obj = {
                                    type: 'laser',
                                    index: 0,
                                    signalType: signalType,
                                })
                            } else {
                                if (['M1', 'M2', 'M3', 'M4'].includes(n)) {
                                    return (obj = {
                                        type: 'mini_geared_motor',
                                        index: 0,
                                        signalType: signalType,
                                    })
                                } else {
                                    return (obj = {
                                        type: 'led',
                                        index: 0,
                                        signalType: signalType,
                                    })
                                }
                            }
                    }
                }
            } else {
                ////// input /////
                if (!['A', 'B', 'C', 'D', 'E', 'F'].includes(n))
                    if (
                        [(null, undefined)].includes(
                            sessionStorage.getItem(`${n}DIGI`)
                        ) ||
                        !JSON.parse(sessionStorage.getItem(`${n}DIGI`))
                    ) {
                        if (
                            JSON.parse(sessionStorage.getItem(`${n[0]}Ultra`))
                        ) {
                            if (n == 'A1' || n == 'C1' || n == 'D1') {
                                return (obj = {
                                    type: 'ultrasonic_sensor',
                                    index: 0,
                                })
                            } else return (obj = null)
                        } else {
                            return (obj = {
                                type: 'tact_switch',
                                index: 0,
                                signalType: signalType,
                            })
                        }
                    }
                    // else if (JSON.parse(sessionStorage.getItem(`${n[0]}Ultra`))) {
                    //     if (n == 'A1' || n == 'C1' || 'D1') {
                    //         return (obj = {
                    //             type: 'ultrasonic_sensor',
                    //             index: 0,
                    //         })
                    //     } else return (obj = null)
                    // }
                    else {
                        return (obj = {
                            type: 'pot',
                            index: 0,
                        })
                    }
            }
        } else {
            return (obj = null)
        }
    }
    const nodeAddIfNo = async (n) => {
        for (let i = 0; i < n.length; i++)
            if ((await n[i].data.specificElType) === 'if') {
                let a = {
                    id: await n[i].id,
                    subprogram: [],
                    type: 'output',
                    data: {
                        elType: 'node',
                        specificElType: 'ifNo',
                    },
                }
                await n.splice(i + 1, 0, a)
            }
    }
    let portData = null
    if (['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice)) {
        // if (sessionStorage.getItem('humanoidPortData'))
        try {
            portData = JSON.parse(sessionStorage.getItem('humanoidPortData'))
        } catch (e) {
            console.log(e)
            portData = null
        }
    }

    if (!portData) {
        let PORTDATAKey = ''
        if (['Humanoid', 'Hexapod', 'Klaw'].includes(connectedDevice)) {
            PORTDATAKey = generateDeviceDataKey(connectedDevice, deviceVersion)
        } else {
            PORTDATAKey = 'HUMANOID0'
        }
        portData = PORTDATA[PORTDATAKey]

        // in PORTDATA object present for only Humanoid, Hexapod, and Klaw, if any other device come its set the portData for Humanoid V0.1 port data
        if (portData) {
            portData = PORTDATA['HUMANOID0']
        }
    }
    sessionStorage.setItem('humanoidPortData', JSON.stringify(portData))

    const programData = JSON.parse(
        sessionStorage.getItem('humanoidProgramData')
    )

    let startButtonState = JSON.parse(
        sessionStorage.getItem('startButtonState')
    )

    var params = {
        screen: 'hexa',
        logic: {
            end: {
                type: 'end',
                state: 'repeat',
            },
        },
        components: {
            A1: portInfo('A1'),
            A2: portInfo('A2'),
            B1: portInfo('B1'),
            B2: portInfo('B2'),
            C1: portInfo('C1'),
            C2: portInfo('C2'),
            D1: portInfo('D1'),
            D2: portInfo('D2'),
            E1: portInfo('E1'),
            E2: portInfo('E2'),
            F1: portInfo('F1'),
            F2: portInfo('F2'),
            servo_motor1: portInfo('S1'),
            servo_motor2: portInfo('S2'),
            G1: null,
            G2: null,
            H1: null,
            H2: null,
            I1: null,
            I2: null,
            M1: portInfo('M1'),
            M2: portInfo('M2'),
            M3: portInfo('M3'),
            M4: portInfo('M4'),
            A: portInfo('A'),
            B: portInfo('B'),
            C: portInfo('C'),
            D: portInfo('D'),
            E: portInfo('E'),
            F: portInfo('F'),
            G: null,
            H: null,
            I: null,
            AB: null,
            FG: null,
            ECHI: null,
            STPM: null,
            FOUR_in_ONE_Sensor: null,
            MICROPHONE: null,
            TEMPERATURE: null,
            TouchZero: null,
            TouchOne: null,
            TouchTwo: null,
            TouchThree: null,
            OLEDOne: portInfo('DOLED'),
            OLEDTwo: portInfo('DOLED'),
            OLEDThree: portInfo('DOLED'),
        },

        internalaccessories:
            sessionStorage.getItem('connectedDevice') === 'Ace'
                ? {
                      Four_in_one_sensor: {
                          isDistanceSensors:
                              JSON.parse(
                                  sessionStorage.getItem('isDistanceSensors')
                              ) || false,
                          isGestureSensor:
                              JSON.parse(
                                  sessionStorage.getItem('isGestureSensor')
                              ) || false,
                          isLightSensor:
                              JSON.parse(
                                  sessionStorage.getItem('isLightSensor')
                              ) || false,
                          isColorSensor:
                              JSON.parse(
                                  sessionStorage.getItem('isColorSensor')
                              ) || false,
                      },
                      isFour_in_one_sensor:
                          JSON.parse(
                              sessionStorage.getItem('isDistanceSensors')
                          ) ||
                          JSON.parse(
                              sessionStorage.getItem('isGestureSensor')
                          ) ||
                          JSON.parse(sessionStorage.getItem('isLightSensor')) ||
                          JSON.parse(sessionStorage.getItem('isColorSensor')) ||
                          false,
                      isTouchZero:
                          JSON.parse(sessionStorage.getItem('isTouchZero')) ||
                          false,
                      isTouchOne:
                          JSON.parse(sessionStorage.getItem('isTouchOne')) ||
                          false,
                      isTouchTwo:
                          JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
                          false,
                      isTouchThree:
                          JSON.parse(sessionStorage.getItem('isTouchThree')) ||
                          false,
                      isTouchZeroOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchZeroOutput')
                          ) || false,
                      isTouchOneOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchOneOutput')
                          ) || false,
                      isTouchTwoOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchTwoOutput')
                          ) || false,
                      isTouchThreeOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchThreeOutput')
                          ) || false,
                      isMic:
                          JSON.parse(sessionStorage.getItem('isMic')) || false,
                      isTemperature:
                          JSON.parse(sessionStorage.getItem('isTemperature')) ||
                          false,
                      isLeftEye:
                          JSON.parse(sessionStorage.getItem('isEyeLeft')) ||
                          false,
                      isRightEye:
                          JSON.parse(sessionStorage.getItem('isEyeRight')) ||
                          false,
                      isEYES:
                          JSON.parse(sessionStorage.getItem('isInternalEye')) ||
                          false,
                      isbuzzer:
                          JSON.parse(sessionStorage.getItem('isBuzzer')) ||
                          false,
                      isSmileOne:
                          JSON.parse(sessionStorage.getItem('isSmileOne')) ||
                          false,
                      isSmileTwo:
                          JSON.parse(sessionStorage.getItem('isSmileTwo')) ||
                          false,
                      isSmileThree:
                          JSON.parse(sessionStorage.getItem('isSmileThree')) ||
                          false,
                      isSmileFour:
                          JSON.parse(sessionStorage.getItem('isSmileFour')) ||
                          false,
                      isAccelerometer:
                          JSON.parse(
                              sessionStorage.getItem('isAccelerometer')
                          ) || false,

                      isBic1: startButtonState && startButtonState[0].isEnabled,
                      isBic2: startButtonState && startButtonState[1].isEnabled,
                      isBif1: startButtonState && startButtonState[3].isEnabled,
                      isBif2: startButtonState && startButtonState[4].isEnabled,
                      isBtTx: startButtonState && startButtonState[2].isEnabled,
                      isUsbTx:
                          startButtonState && startButtonState[6].isEnabled,
                  }
                : {
                      Four_in_one_sensor: {
                          isDistanceSensors:
                              JSON.parse(
                                  sessionStorage.getItem('isDistanceSensors')
                              ) || false,
                          isGestureSensor:
                              JSON.parse(
                                  sessionStorage.getItem('isGestureSensor')
                              ) || false,
                          isLightSensor:
                              JSON.parse(
                                  sessionStorage.getItem('isLightSensor')
                              ) || false,
                          isColorSensor:
                              JSON.parse(
                                  sessionStorage.getItem('isColorSensor')
                              ) || false,
                      },
                      isFour_in_one_sensor:
                          JSON.parse(
                              sessionStorage.getItem('isDistanceSensors')
                          ) ||
                          JSON.parse(
                              sessionStorage.getItem('isGestureSensor')
                          ) ||
                          JSON.parse(sessionStorage.getItem('isLightSensor')) ||
                          JSON.parse(sessionStorage.getItem('isColorSensor')) ||
                          false,
                      isTouchZero:
                          JSON.parse(sessionStorage.getItem('isTouchZero')) ||
                          false,
                      isTouchOne:
                          JSON.parse(sessionStorage.getItem('isTouchOne')) ||
                          false,
                      isTouchTwo:
                          JSON.parse(sessionStorage.getItem('isTouchTwo')) ||
                          false,
                      isTouchThree:
                          JSON.parse(sessionStorage.getItem('isTouchThree')) ||
                          false,
                      isTouchZeroOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchZeroOutput')
                          ) || false,
                      isTouchOneOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchOneOutput')
                          ) || false,
                      isTouchTwoOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchTwoOutput')
                          ) || false,
                      isTouchThreeOutput:
                          JSON.parse(
                              sessionStorage.getItem('isTouchThreeOutput')
                          ) || false,
                      isMic:
                          JSON.parse(sessionStorage.getItem('isMic')) || false,
                      isTemperature:
                          JSON.parse(sessionStorage.getItem('isTemperature')) ||
                          false,
                      isLeftEye:
                          JSON.parse(sessionStorage.getItem('isEyeLeft')) ||
                          false,
                      isRightEye:
                          JSON.parse(sessionStorage.getItem('isEyeRight')) ||
                          false,
                      isEYES:
                          JSON.parse(sessionStorage.getItem('isInternalEye')) ||
                          false,
                      isbuzzer:
                          JSON.parse(sessionStorage.getItem('isBuzzer')) ||
                          false,
                      isSmileOne:
                          JSON.parse(sessionStorage.getItem('isSmileOne')) ||
                          false,
                      isSmileTwo:
                          JSON.parse(sessionStorage.getItem('isSmileTwo')) ||
                          false,
                      isSmileThree:
                          JSON.parse(sessionStorage.getItem('isSmileThree')) ||
                          false,
                      isSmileFour:
                          JSON.parse(sessionStorage.getItem('isSmileFour')) ||
                          false,
                      isAccelerometer:
                          JSON.parse(
                              sessionStorage.getItem('isAccelerometer')
                          ) || false,

                      // Humanoid Internal Accessories isActionServo

                      isLeftRGB: portData[0].isPortSelected,
                      isRightRGB: portData[1].isPortSelected,
                      isLeftEye: portData[0].isPortSelected,
                      isRightEye: portData[1].isPortSelected,
                      isMP3: portData[2].isPortSelected,
                      isZingmp3: portData[2].isPortSelected,
                      isServoHead: portData[3].isPortSelected,
                      isActionGroup: portData['4a'].isPortSelected,
                      // isServo: portData['4b'].isPortSelected,
                      isActionServo: portData['4b'].isPortSelected,
                      isHeadServo: portData[3].isPortSelected,

                      isBattery: portData[21].isPortSelected,
                      isUltrasonic: portData[22].isPortSelected,
                      isAccelerometerX: portData[23].isPortSelected,
                      isAccelerometerY: portData[24].isPortSelected,
                      isAccelerometerY: portData[25].isPortSelected,
                      isGyroX: portData[26].isPortSelected,
                      isGyroY: portData[27].isPortSelected,
                      isGyroZ: portData[28].isPortSelected,

                      isBic1: startButtonState && startButtonState[0].isEnabled,
                      isBic2: startButtonState && startButtonState[1].isEnabled,
                      isBif1: startButtonState && startButtonState[3].isEnabled,
                      isBif2: startButtonState && startButtonState[4].isEnabled,
                      isBtTx: startButtonState && startButtonState[2].isEnabled,
                      isUsbTx:
                          startButtonState && startButtonState[6].isEnabled,
                  },
    }
    if (sessionStorage.getItem('connectedDevice') === 'Humanoid') {
        params.internalaccessories.isM1 = portData[39].isPortSelected
        params.internalaccessories.isM2 = portData[40].isPortSelected
    }
    const getActions = (actionId, isRandomActionSelected) => {
        actionId = actionId.toString()
        let oldActionId = actionId
        if (actionId.includes('74')) {
            actionId = '74'
        }
        switch (actionId) {
            case '0':
                return {}
            case '50':
                return { assignAttention: true }
            case '51':
                return { assignForward: true }
            case '52':
                return { assignBackward: true }
            case '53':
                return { assignLeft: true }
            case '54':
                return { assignRight: true }
            case '55':
                return { assignWave: true }
            case '56':
                return { assignBow: true }
            case '57':
                return { assignWakeUpFront: true }
            case '58':
                return { assignWakeUpBack: true }
            case '59':
                return { assignWakeUpBack2: true }
            case '60':
                return { assignSquat: true }
            case '61':
                return { assignBoxForward: true }
            case '62':
                return { assignLeftKick: true }
            case '63':
                return { assignRightKick: true }
            case '64':
                return { assignBreakDance: true }
            case '67':
                return { assignSlideToLeft: true }
            case '68':
                return { assignSlideToRight: true }
            case '69':
                return { assignHookLeft: true }
            case '70':
                return { assignHookRight: true }
            case '71':
                return { assignPushUp: true }
            case '72':
                return { assignSitUP: true }
            case '73':
                return { assignSalute: true }
            case '75':
                return { assignLeftShoot: true }
            case '76':
                return { assignRightShoot: true }
            case '74':
                if (isRandomActionSelected) {
                    return {
                        assignCustomAction: true,
                        assignRandomAction: true,
                        valueCustomAction: 28,
                    }
                }
                let customActionName =
                    props.logic.CUSTOMGROUPACTIONS12[oldActionId].value

                return {
                    assignCustomAction: true,
                    valueCustomAction: customActionName,
                }
        }
    }

    const propertyPanelConversion = async (testSingleD, programSend) => {
        console.log(Object.entries(testSingleD))
        for (let i = 0; i < testSingleD.length; i++) {
            console.log('testSingleD......', testSingleD[i])
            if (testSingleD[i].data.specificElType == 'wait') {
                let a = waitArray(testSingleD[i].id)
                programSend.push({
                    id: testSingleD[i].id,
                    type: 'wait',
                    state: {
                        ms: a[0] || 0,
                        s: a[1] || 0,
                        m: a[2] || 0,
                        h: a[3] || 0,
                    },
                })
            } else if (testSingleD[i].data.specificElType === 'output') {
                let a = outputArray(testSingleD[i].id)
                if (sessionStorage.getItem(`connectedDevice`) === 'Ace')
                    programSend.push({
                        id: testSingleD[i].id,
                        type: 'hardware',
                        state: {
                            assignBic1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `bic1Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBic1:
                                sessionStorage.getItem(
                                    `bic1Val${testSingleD[i].id}`
                                ) || '',
                            assignBic2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `bic2Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBic2:
                                sessionStorage.getItem(
                                    `bic2Val${testSingleD[i].id}`
                                ) || '',
                            assignBif1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `bif1Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBif1:
                                sessionStorage.getItem(
                                    `bif1Val${testSingleD[i].id}`
                                ) || '',
                            assignBif2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `bif2Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBif2:
                                sessionStorage.getItem(
                                    `bif2Val${testSingleD[i].id}`
                                ) || '',
                            assignUsbTx:
                                Boolean(
                                    sessionStorage.getItem(
                                        `usbTxChk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueUsbTx:
                                sessionStorage.getItem(
                                    `usbTxVal${testSingleD[i].id}`
                                ) || '',
                            assignBtTx1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `btTx1Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBtTx1:
                                sessionStorage.getItem(
                                    `btTx1Val${testSingleD[i].id}`
                                ) || '',
                            assignBtTx2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `btTx2Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBtTx2:
                                sessionStorage.getItem(
                                    `btTx2Val${testSingleD[i].id}`
                                ) || '',
                            assignBtTx3:
                                Boolean(
                                    sessionStorage.getItem(
                                        `btTx3Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBtTx3:
                                sessionStorage.getItem(
                                    `btTx3Val${testSingleD[i].id}`
                                ) || '',
                            assignBtTx4:
                                Boolean(
                                    sessionStorage.getItem(
                                        `btTx4Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBtTx4:
                                sessionStorage.getItem(
                                    `btTx4Val${testSingleD[i].id}`
                                ) || '',
                            assignBtTx5:
                                Boolean(
                                    sessionStorage.getItem(
                                        `btTx5Chk${testSingleD[i].id}`
                                    ) === 'true'
                                ) || false,
                            valueBtTx5:
                                sessionStorage.getItem(
                                    `btTx5Val${testSingleD[i].id}`
                                ) || '',

                            assignTouchZeroOutput: Boolean(
                                Boolean(a[26] === 'true' || parseInt(a[26]))
                            ),
                            valueTouchZeroOutput: parseInt(a[8]),
                            assignTouchOneOutput: Boolean(
                                Boolean(a[27] === 'true' || parseInt(a[27]))
                            ),
                            valueTouchOneOutput: parseInt(a[9]),
                            assignTouchTwoOutput: Boolean(
                                Boolean(a[28] === 'true' || parseInt(a[28]))
                            ),
                            valueTouchTwoOutput: parseInt(a[10]),
                            assignTouchThreeOutput: Boolean(
                                Boolean(a[73] === 'true' || parseInt(a[73]))
                            ),
                            valueTouchThreeOutput: parseInt(a[74]),
                            assignLeftEye: Boolean(
                                a[11] === 'true' || parseInt(a[11])
                            ),
                            assignLeftEyeR: Boolean(
                                a[11] === 'true' || parseInt(a[11])
                            ),
                            assignLeftEyeG: Boolean(
                                a[11] === 'true' || parseInt(a[11])
                            ),
                            assignLeftEyeB: Boolean(
                                a[11] === 'true' || parseInt(a[11])
                            ),
                            valueLeftEyeR: parseInt(a[29]),
                            valueLeftEyeG: parseInt(a[30]),
                            valueLeftEyeB: parseInt(a[31]),
                            assignRightEye: Boolean(
                                a[12] === 'true' || parseInt(a[12])
                            ),
                            assignRightEyeR: Boolean(
                                a[12] === 'true' || parseInt(a[12])
                            ),
                            assignRightEyeG: Boolean(
                                a[12] === 'true' || parseInt(a[12])
                            ),
                            assignRightEyeB: Boolean(
                                a[12] === 'true' || parseInt(a[12])
                            ),
                            valueRightEyeR: parseInt(a[32]),
                            valueRightEyeG: parseInt(a[33]),
                            valueRightEyeB: parseInt(a[34]),
                            assignEye1:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[0]?.isSelected === true
                                ) || false,
                            valueEyeR1: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i]?.id}`
                                    ) ?? '[]'
                                )[0]?.valR
                            ),
                            valueEyeG1: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i]?.id}`
                                    ) ?? '[]'
                                )[0]?.valG
                            ),
                            valueEyeB1: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[0]?.valB
                            ),
                            assignEye2:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[1]?.isSelected === true
                                ) || false,
                            valueEyeR2: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[1]?.valR
                            ),
                            valueEyeG2: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[1]?.valG
                            ),
                            valueEyeB2: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i]?.id}`
                                    ) ?? '[]'
                                )[1]?.valB
                            ),
                            assignEye3:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i]?.id}`
                                        ) ?? '[]'
                                    )[2]?.isSelected === true
                                ) || false,
                            valueEyeR3: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i]?.id}`
                                    ) ?? '[]'
                                )[2]?.valR
                            ),
                            valueEyeG3: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[2]?.valG
                            ),
                            valueEyeB3: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[2]?.valB
                            ),
                            assignEye4:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[3]?.isSelected === true
                                ) || false,
                            valueEyeR4: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[3]?.valR
                            ),
                            valueEyeG4: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[3]?.valG
                            ),
                            valueEyeB4: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[3]?.valB
                            ),
                            assignEye5:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[4]?.isSelected === true
                                ) || false,
                            valueEyeR5: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[4]?.valR
                            ),
                            valueEyeG5: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[4]?.valG
                            ),
                            valueEyeB5: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[4]?.valB
                            ),
                            assignEye6:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[5]?.isSelected === true
                                ) || false,
                            valueEyeR6: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[5]?.valR
                            ),
                            valueEyeG6: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[5]?.valG
                            ),
                            valueEyeB6: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[5]?.valB
                            ),
                            assignEye7:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[6]?.isSelected === true
                                ) || false,
                            valueEyeR7: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[6]?.valR
                            ),
                            valueEyeG7: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[6]?.valG
                            ),
                            valueEyeB7: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[6]?.valB
                            ),
                            assignEye8:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[7]?.isSelected === true
                                ) || false,
                            valueEyeR8: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[7]?.valR
                            ),
                            valueEyeG8: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[7]?.valG
                            ),
                            valueEyeB8: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[7]?.valB
                            ),
                            assignEye9:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[8]?.isSelected === true
                                ) || false,
                            valueEyeR9: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[8]?.valR
                            ),
                            valueEyeG9: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[8]?.valG
                            ),
                            valueEyeB9: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[8]?.valB
                            ),
                            assignEye10:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[9]?.isSelected === true
                                ) || false,
                            valueEyeR10: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[9]?.valR
                            ),
                            valueEyeG10: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[9]?.valG
                            ),
                            valueEyeB10: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[9]?.valB
                            ),
                            assignEye11:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[10]?.isSelected === true
                                ) || false,
                            valueEyeR11: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[10]?.valR
                            ),
                            valueEyeG11: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[10]?.valG
                            ),
                            valueEyeB11: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[10]?.valB
                            ),
                            assignEye12:
                                Boolean(
                                    JSON.parse(
                                        sessionStorage.getItem(
                                            `valRGBEye${testSingleD[i].id}`
                                        ) ?? '[]'
                                    )[11]?.isSelected === true
                                ) || false,
                            valueEyeR12: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[11]?.valR
                            ),
                            valueEyeG12: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[11]?.valG
                            ),
                            valueEyeB12: Number(
                                JSON.parse(
                                    sessionStorage.getItem(
                                        `valRGBEye${testSingleD[i].id}`
                                    ) ?? '[]'
                                )[11]?.valB
                            ),
                            assignBuzzer: Boolean(
                                a[35] === 'true' || parseInt(a[35])
                            ),
                            assignBuzzerFrequency:
                                a[35] === 'true'
                                    ? a[70] == 'true'
                                    : a[71] === 'false',
                            assignBuzzerTone:
                                a[35] === 'true'
                                    ? a[71] === 'true'
                                    : a[70] == 'false',
                            valueBuzzerFrequency: parseInt(a[13]),
                            valueBuzzerTone: parseInt(a[13]),
                            assignSmileOne: Boolean(
                                a[36] === 'true' || parseInt(a[36])
                            ),
                            valueSmileOne: parseInt(a[14]),
                            assignSmileTwo: Boolean(
                                a[37] === 'true' || parseInt(a[37])
                            ),
                            valueSmileTwo: parseInt(a[15]),
                            assignSmileThree: Boolean(
                                a[38] === 'true' || parseInt(a[38])
                            ),
                            valueSmileThree: parseInt(a[16]),
                            assignSmileFour: Boolean(
                                a[39] === 'true' || parseInt(a[39])
                            ),
                            valueSmileFour: parseInt(a[17]),
                            assignA1: Boolean(
                                a[18] === 'true' || parseInt(a[18])
                            ),
                            valueA1: parseInt(a[0]),
                            assignA2: Boolean(
                                a[19] === 'true' || parseInt(a[19])
                            ),
                            valueA2: parseInt(a[1]),
                            assignB1: Boolean(
                                a[20] === 'true' || parseInt(a[20])
                            ),
                            valueB1: parseInt(a[2]),
                            assignB2: Boolean(
                                a[21] === 'true' || parseInt(a[21])
                            ),
                            valueB2: parseInt(a[3]),
                            assignC1: Boolean(
                                a[22] === 'true' || parseInt(a[22])
                            ),
                            valueC1: parseInt(a[4]),
                            assignC2: Boolean(
                                a[23] === 'true' || parseInt(a[23])
                            ),
                            valueC2: parseInt(a[5]),
                            assignD1: Boolean(
                                a[24] === 'true' || parseInt(a[24])
                            ),
                            valueD1: parseInt(a[6]),
                            assignD2: Boolean(
                                a[25] === 'true' || parseInt(a[25])
                            ),
                            valueD2: parseInt(a[7]),
                            assignE1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `e1Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueE1:
                                Number(
                                    sessionStorage.getItem(
                                        `e1${testSingleD[i].id}`
                                    )
                                ) || 0,
                            assignE2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `e2Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueE2:
                                Number(
                                    sessionStorage.getItem(
                                        `e2${testSingleD[i].id}`
                                    )
                                ) || 0,
                            assignF1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `f1Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueF1:
                                Number(
                                    sessionStorage.getItem(
                                        `f1${testSingleD[i].id}`
                                    )
                                ) || 0,
                            assignF2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `f2Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueF2:
                                Number(
                                    sessionStorage.getItem(
                                        `f2${testSingleD[i].id}`
                                    )
                                ) || 0,
                            assignservo_motor1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `s1ServoChk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueservo_motor1:
                                Number(
                                    sessionStorage.getItem(
                                        `s1Servo${testSingleD[i].id}`
                                    )
                                ) || 0,
                            assignservo_motor2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `s2ServoChk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueservo_motor2:
                                Number(
                                    sessionStorage.getItem(
                                        `s2Servo${testSingleD[i].id}`
                                    )
                                ) || 0,
                            assignOLEDOne:
                                Boolean(
                                    sessionStorage.getItem(
                                        `oledChk1${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            assignOLEDTwo:
                                Boolean(
                                    sessionStorage.getItem(
                                        `oledChk2${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            assignOLEDThree:
                                Boolean(
                                    sessionStorage.getItem(
                                        `oledChk3${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            assignOLEDFour:
                                Boolean(
                                    sessionStorage.getItem(
                                        `oledChk4${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueOLEDOne:
                                sessionStorage.getItem(
                                    `oled1${testSingleD[i].id}`
                                ) || '',
                            valueOLEDTwo:
                                sessionStorage.getItem(
                                    `oled2${testSingleD[i].id}`
                                ) || '',
                            valueOLEDThree:
                                sessionStorage.getItem(
                                    `oled3${testSingleD[i].id}`
                                ) || '',
                            valueOLEDFour:
                                sessionStorage.getItem(
                                    `oled4${testSingleD[i].id}`
                                ) || '',
                            assignRGBComp1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb1Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp1R: parseInt(a[40]),
                            valueRGBComp1G: parseInt(a[41]),
                            valueRGBComp1B: parseInt(a[42]),
                            countRGBComp: parseInt(
                                sessionStorage.getItem(
                                    `countRGB${testSingleD[i].id}`
                                )
                            ),

                            assignRGBComp2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb2Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp2R: parseInt(a[43]),
                            valueRGBComp2G: parseInt(a[44]),
                            valueRGBComp2B: parseInt(a[45]),
                            assignRGBComp3:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb3Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp3R: parseInt(a[46]),
                            valueRGBComp3G: parseInt(a[47]),
                            valueRGBComp3B: parseInt(a[48]),
                            assignRGBComp4:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb4Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp4R: parseInt(a[49]),
                            valueRGBComp4G: parseInt(a[50]),
                            valueRGBComp4B: parseInt(a[51]),
                            assignRGBComp5:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb5Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp5R: parseInt(a[52]),
                            valueRGBComp5G: parseInt(a[53]),
                            valueRGBComp5B: parseInt(a[54]),
                            assignRGBComp6:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb6Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp6R: parseInt(a[55]),
                            valueRGBComp6G: parseInt(a[56]),
                            valueRGBComp6B: parseInt(a[57]),
                            assignRGBComp7:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb7Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp7R: parseInt(a[58]),
                            valueRGBComp7G: parseInt(a[59]),
                            valueRGBComp7B: parseInt(a[60]),
                            assignRGBComp8:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb8Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp8R: parseInt(a[61]),
                            valueRGBComp8G: parseInt(a[62]),
                            valueRGBComp8B: parseInt(a[63]),
                            assignRGBComp9:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb9Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp9R: parseInt(a[64]),
                            valueRGBComp9G: parseInt(a[65]),
                            valueRGBComp9B: parseInt(a[66]),
                            assignRGBComp10:
                                Boolean(
                                    sessionStorage.getItem(
                                        `rgb10Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueRGBComp10R: parseInt(a[67]),
                            valueRGBComp10G: parseInt(a[68]),
                            valueRGBComp10B: parseInt(a[69]),
                            valueM1: parseInt(a[74]) || 0,
                            assignM1:
                                Boolean(
                                    sessionStorage.getItem(
                                        `m1Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueM2: parseInt(a[75]) || 0,
                            assignM2:
                                Boolean(
                                    sessionStorage.getItem(
                                        `m2Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueM3: parseInt(a[76]) || 0,
                            assignM3:
                                Boolean(
                                    sessionStorage.getItem(
                                        `m3Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                            valueM4: parseInt(a[77]) || 0,
                            assignM4:
                                Boolean(
                                    sessionStorage.getItem(
                                        `m4Chk${testSingleD[i].id}`
                                    ) == 'true'
                                ) || false,
                        },
                    })
                else {
                    programSend.push({
                        id: testSingleD[i].id,
                        type: 'hardware',
                        state: {
                            assignBattery:
                                programData[testSingleD[i].id].state[21]
                                    .iisActionEnabled,
                            valueBattery:
                                programData[testSingleD[i].id].state[21].value,
                            assignUltrasonic:
                                programData[testSingleD[i].id].state[22]
                                    .isActionEnabled,
                            valueUltrasonic:
                                programData[testSingleD[i].id].state[22].value,
                            assignAccelerometerX:
                                programData[testSingleD[i].id].state[23]
                                    .isActionEnabled,
                            valueAccelerometerX:
                                programData[testSingleD[i].id].state[23].value,
                            assignAccelerometerY:
                                programData[testSingleD[i].id].state[24]
                                    .isActionEnabled,
                            valueAccelerometerY:
                                programData[testSingleD[i].id].state[24].value,
                            assignAccelerometerZ:
                                programData[testSingleD[i].id].state[25]
                                    .isActionEnabled,
                            valueAccelerometerZ:
                                programData[testSingleD[i].id].state[25].value,
                            assignGyroX:
                                programData[testSingleD[i].id].state[26]
                                    .isActionEnabled,
                            valueGyroX:
                                programData[testSingleD[i].id].state[26].value,
                            assignGyroY:
                                programData[testSingleD[i].id].state[27]
                                    .isActionEnabled,
                            valueGyroY:
                                programData[testSingleD[i].id].state[27].value,
                            assignGyroZ:
                                programData[testSingleD[i].id].state[28]
                                    .isActionEnabled,
                            valueGyroZ:
                                programData[testSingleD[i].id].state[28].value,

                            assignA1:
                                programData[testSingleD[i].id].state[31]
                                    .isActionEnabled,
                            valueA1:
                                programData[testSingleD[i].id].state[31].value,
                            assignA2:
                                programData[testSingleD[i].id].state[32]
                                    .isActionEnabled,
                            valueA2:
                                programData[testSingleD[i].id].state[32].value,

                            assignB1:
                                programData[testSingleD[i].id].state[33]
                                    .isActionEnabled,
                            valueB1:
                                programData[testSingleD[i].id].state[33].value,
                            assignB2:
                                programData[testSingleD[i].id].state[34]
                                    .isActionEnabled,
                            valueB2:
                                programData[testSingleD[i].id].state[34].value,

                            assignC1:
                                programData[testSingleD[i].id].state[35]
                                    .isActionEnabled,
                            valueC1:
                                programData[testSingleD[i].id].state[35].value,
                            assignC2:
                                programData[testSingleD[i].id].state[36]
                                    .isActionEnabled,
                            valueC2:
                                programData[testSingleD[i].id].state[36].value,

                            assignD1:
                                programData[testSingleD[i].id].state[37]
                                    .isActionEnabled,
                            valueD1:
                                programData[testSingleD[i].id].state[37].value,
                            assignD2:
                                programData[testSingleD[i].id].state[38]
                                    .isActionEnabled,
                            valueD2:
                                programData[testSingleD[i].id].state[38].value,

                            assignOLEDOne:
                                programData[testSingleD[i].id].state[37]
                                    .oledState[1].isActionEnabled,
                            valueOLEDOne:
                                programData[testSingleD[i].id].state[37]
                                    .oledState[1].value,
                            assignOLEDTwo:
                                programData[testSingleD[i].id].state[37]
                                    .oledState[2].isActionEnabled,
                            valueOLEDTwo:
                                programData[testSingleD[i].id].state[37]
                                    .oledState[2].value,
                            assignOLEDThree:
                                programData[testSingleD[i].id].state[37]
                                    .oledState[3].isActionEnabled,
                            valueOLEDThree:
                                programData[testSingleD[i].id].state[37]
                                    .oledState[3].value,

                            assignRGBComp1:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[1].isActionEnabled,
                            valueRGBComp1R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[1].value[0],
                            valueRGBComp1G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[1].value[1],
                            valueRGBComp1B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[1].value[2],

                            assignRGBComp2:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[2].isActionEnabled,
                            valueRGBComp2R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[2].value[0],
                            valueRGBComp2G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[2].value[1],
                            valueRGBComp2B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[2].value[2],

                            assignRGBComp3:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[3].isActionEnabled,
                            valueRGBComp3R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[3].value[0],
                            valueRGBComp3G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[3].value[1],
                            valueRGBComp3B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[3].value[2],

                            assignRGBComp4:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[4].isActionEnabled,
                            valueRGBComp4R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[4].value[0],
                            valueRGBComp4G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[4].value[1],
                            valueRGBComp4B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[4].value[2],

                            assignRGBComp5:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[5].isActionEnabled,
                            valueRGBComp5R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[5].value[0],
                            valueRGBComp5G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[5].value[1],
                            valueRGBComp5B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[5].value[2],

                            assignRGBComp6:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[6].isActionEnabled,
                            valueRGBComp6R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[6].value[0],
                            valueRGBComp6G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[6].value[1],
                            valueRGBComp6B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[6].value[2],

                            assignRGBComp7:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[7].isActionEnabled,
                            valueRGBComp7R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[7].value[0],
                            valueRGBComp7G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[7].value[1],
                            valueRGBComp7B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[7].value[2],

                            assignRGBComp8:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[8].isActionEnabled,
                            valueRGBComp8R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[8].value[0],
                            valueRGBComp8G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[8].value[1],
                            valueRGBComp8B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[8].value[2],

                            assignRGBComp9:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[9].isActionEnabled,
                            valueRGBComp9R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[9].value[0],
                            valueRGBComp9G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[9].value[1],
                            valueRGBComp9B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[9].value[2],

                            assignRGBComp10:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[10].isActionEnabled,
                            valueRGBComp10R:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[10].value[0],
                            valueRGBComp10G:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[10].value[1],
                            valueRGBComp10B:
                                programData[testSingleD[i].id].state[33]
                                    .rgbState[10].value[2],

                            valueM1:
                                programData[testSingleD[i].id].state[39].value,
                            assignM1:
                                programData[testSingleD[i].id].state[39]
                                    .isActionEnabled,
                            valueM2:
                                programData[testSingleD[i].id].state[40].value,
                            assignM2:
                                programData[testSingleD[i].id].state[40]
                                    .isActionEnabled,

                            assignHeadServo:
                                programData[testSingleD[i].id].state[3]
                                    .isActionEnabled,
                            valueHeadServo:
                                programData[testSingleD[i].id].state[3].value,
                            assignZingMp3:
                                programData[testSingleD[i].id].state[2]
                                    .isActionEnabled,
                            valueZingMp3:
                                programData[testSingleD[i].id].state[2].value,
                            assignEye1:
                                programData[testSingleD[i].id].state[76]
                                    .isActionEnabled,
                            valueEyeR1:
                                programData[testSingleD[i].id].state[76]
                                    .valueEyeR1,
                            valueEyeG1:
                                programData[testSingleD[i].id].state[76]
                                    .valueEyeG1,
                            valueEyeB1:
                                programData[testSingleD[i].id].state[76]
                                    .valueEyeB1,
                            assignEye2:
                                programData[testSingleD[i].id].state[77]
                                    .isActionEnabled,
                            valueEyeR2:
                                programData[testSingleD[i].id].state[77]
                                    .valueEyeR2,
                            valueEyeG2:
                                programData[testSingleD[i].id].state[77]
                                    .valueEyeG2,
                            valueEyeB2:
                                programData[testSingleD[i].id].state[77]
                                    .valueEyeB2,
                            assignEye3:
                                programData[testSingleD[i].id].state[78]
                                    .isActionEnabled,
                            valueEyeR3:
                                programData[testSingleD[i].id].state[78]
                                    .valueEyeR3,
                            valueEyeG3:
                                programData[testSingleD[i].id].state[78]
                                    .valueEyeG3,
                            valueEyeB3:
                                programData[testSingleD[i].id].state[78]
                                    .valueEyeB3,
                            assignEye4:
                                programData[testSingleD[i].id].state[79]
                                    .isActionEnabled,
                            valueEyeR4:
                                programData[testSingleD[i].id].state[79]
                                    .valueEyeR4,
                            valueEyeG4:
                                programData[testSingleD[i].id].state[79]
                                    .valueEyeG4,
                            valueEyeB4:
                                programData[testSingleD[i].id].state[79]
                                    .valueEyeB4,
                            assignEye5:
                                programData[testSingleD[i].id].state[80]
                                    .isActionEnabled,
                            valueEyeR5:
                                programData[testSingleD[i].id].state[80]
                                    .valueEyeR5,
                            valueEyeG5:
                                programData[testSingleD[i].id].state[80]
                                    .valueEyeG5,
                            valueEyeB5:
                                programData[testSingleD[i].id].state[80]
                                    .valueEyeB5,
                            assignEye6:
                                programData[testSingleD[i].id].state[81]
                                    .isActionEnabled,
                            valueEyeR6:
                                programData[testSingleD[i].id].state[81]
                                    .valueEyeR6,
                            valueEyeG6:
                                programData[testSingleD[i].id].state[81]
                                    .valueEyeG6,
                            valueEyeB6:
                                programData[testSingleD[i].id].state[81]
                                    .valueEyeB6,

                            ...getActions(
                                programData[testSingleD[i].id].state['4a']
                                    .value,
                                programData[testSingleD[i].id].state['4a']
                                    .isRandomActionSelected
                            ),
                        },
                    })
                }
                console.log('aaaaaa', programSend)
            } else if (testSingleD[i].data.specificElType === 'loop') {
                let send = []
                if (testSingleD[i].subprogram.length != 0)
                    await propertyPanelConversion(
                        testSingleD[i].subprogram,
                        send
                    )
                let state
                if (loopArray(testSingleD[i].id) == null) state = {}
                else
                    state = {
                        times: loopArray(testSingleD[i].id),
                    }
                programSend.push({
                    type: 'loop',
                    state: state,
                    id: testSingleD[i].id,
                    subprogram: send,
                })
            } else if (testSingleD[i].data.specificElType === 'if') {
                let send = []
                if (testSingleD[i].subprogram.length != 0)
                    await propertyPanelConversion(
                        testSingleD[i].subprogram,
                        send
                    )
                let a = ifArray(testSingleD[i].id)
                if (
                    ['Humanoid'].includes(
                        sessionStorage.getItem(`connectedDevice`)
                    )
                ) {
                    a = humanoidIfArray(testSingleD[i].id)
                }

                let source = 'battery'
                let condition = 'gt'
                if (a[0] === 'touchZero') source = 'TouchZero'
                else if (a[0] === 'touchOne') source = 'TouchOne'
                else if (a[0] === 'touchTwo') source = 'TouchTwo'
                else if (a[0] === 'touchThree') source = 'TouchThree'
                else if (a[0] === 'temperature') source = 'TEMPERATURE'
                else if (a[0] === 'microphone') source = 'MICROPHONE'
                else if (a[0] === 'accelerometer_x') source = 'ACCELEROMETER_X'
                else if (a[0] === 'accelerometer_y') source = 'ACCELEROMETER_Y'
                else if (a[0] === 'accelerometer_z') source = 'ACCELEROMETER_Z'
                else if (a[0] === 'gyro_x') source = 'gyro_x'
                else if (a[0] === 'gyro_y') source = 'gyro_y'
                else if (a[0] === 'gyro_z') source = 'gyro_z'
                else if (a[0] === 'distanceSensor')
                    source = '4-IN-1 SENSOR  →  DIST'
                else if (a[0] === 'gestureSensor')
                    source = '4-IN-1 SENSOR  →  GESTURE'
                else if (a[0] === 'lightSensor')
                    source = '4-IN-1 SENSOR  →  LIGHT'
                else if (a[0] === 'colorSensorRed')
                    source = '4-IN-1 SENSOR  →  RED'
                else if (a[0] === 'colorSensorGreen')
                    source = '4-IN-1 SENSOR  →  GREEN'
                else if (a[0] === 'colorSensorBlue')
                    source = '4-IN-1 SENSOR  →  BLUE'
                else if (a[0] === 'port A1' || a[0] === 'ultra A1')
                    source = 'A1'
                else if (a[0] === 'port A2') source = 'A2'
                else if (a[0] === 'port B1') source = 'B1'
                else if (a[0] === 'port B2') source = 'B2'
                else if (a[0] === 'port C1' || a[0] === 'ultra C1')
                    source = 'C1'
                else if (a[0] === 'port C2') source = 'C2'
                else if (a[0] === 'port D1') source = 'D1'
                else if (a[0] === 'port D2') source = 'D2'
                else if (a[0] === 'port E1') source = 'E1'
                else if (a[0] === 'port E2') source = 'E2'
                else if (a[0] === 'port F1') source = 'F1'
                else if (a[0] === 'port F2') source = 'M2'
                else if (a[0] === 'port M1') source = 'M1'
                else if (a[0] === 'port M2') source = 'M2'
                else if (a[0] === 'port M3') source = 'M3'
                else if (a[0] === 'port M4') source = 'M4'
                else if (a[0] === 'port S1') source = 'S1'
                else if (a[0] === 'port S2') source = 'S2'
                if (a[3] === 'true') condition = 'gt'
                else if (a[4] === 'true') condition = 'lt'
                else if (a[5] === 'true') condition = 'bw'
                else if (a[6] === 'true') condition = 'eq'
                else if (a[7] === 'true') condition = 'ne'
                if (
                    sessionStorage.getItem(`connectedDevice`) === 'Ace' &&
                    sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
                    source === 'battery'
                ) {
                    programSend.push({
                        type: 'sensor',
                        state: {},
                        subprogram: send,
                    })
                } else {
                    programSend.push({
                        type: 'sensor',
                        state: {
                            source: source,
                            value: Number(a[1]),
                            value2: Number(a[2]),
                            hour: 0,
                            hour2: 0,
                            minute: 0,
                            minute2: 0,
                            condition: condition,
                        },
                        subprogram: send,
                    })
                }

                // else if(testSingleD[i].data.specificElType === "end"){
                //   params.logic.end.state="end"
                // }
            } else if (testSingleD[i].data.specificElType === 'ifNo') {
                let send = []
                if (testSingleD[i].subprogram.length != 0)
                    await propertyPanelConversion(
                        testSingleD[i].subprogram,
                        send
                    )
                let a = ifArray(testSingleD[i].id)
                if (
                    ['Humanoid'].includes(
                        sessionStorage.getItem(`connectedDevice`)
                    )
                ) {
                    a = humanoidIfArray(testSingleD[i].id)
                }
                console.log('IFFFFFFFFFFFFFFF', a)
                let source = 'battery'
                let condition = 'lt'
                if (a[0] === 'touchZero') source = 'TouchZero'
                else if (a[0] === 'touchOne') source = 'TouchOne'
                else if (a[0] === 'touchTwo') source = 'TouchTwo'
                else if (a[0] === 'touchThree') source = 'TouchThree'
                else if (a[0] === 'temperature') source = 'TEMPERATURE'
                else if (a[0] === 'microphone') source = 'MICROPHONE'
                else if (a[0] === 'distanceSensor')
                    source = '4-IN-1 SENSOR  →  DIST'
                else if (a[0] === 'gestureSensor')
                    source = '4-IN-1 SENSOR  →  GESTURE'
                else if (a[0] === 'lightSensor')
                    source = '4-IN-1 SENSOR  →  LIGHT'
                else if (a[0] === 'colorSensorRed')
                    source = '4-IN-1 SENSOR  →  RED'
                else if (a[0] === 'colorSensorGreen')
                    source = '4-IN-1 SENSOR  →  GREEN'
                else if (a[0] === 'colorSensorBlue')
                    source = '4-IN-1 SENSOR  →  BLUE'
                else if (a[0] === 'port A1' || a[0] === 'ultra A1')
                    source = 'A1'
                else if (a[0] === 'port A2') source = 'A2'
                else if (a[0] === 'port B1') source = 'B1'
                else if (a[0] === 'port B2') source = 'B2'
                else if (a[0] === 'port C1' || a[0] === 'ultra C1')
                    source = 'C1'
                else if (a[0] === 'port C2') source = 'C2'
                else if (a[0] === 'port D1') source = 'D1'
                else if (a[0] === 'port D2') source = 'D2'
                else if (a[0] === 'port E1') source = 'E1'
                else if (a[0] === 'port E2') source = 'E2'
                else if (a[0] === 'port F1') source = 'F1'
                else if (a[0] === 'port F2') source = 'M2'
                else if (a[0] === 'port M1') source = 'M1'
                else if (a[0] === 'port M2') source = 'M2'
                else if (a[0] === 'port M3') source = 'M3'
                else if (a[0] === 'port M4') source = 'M4'
                else if (a[0] === 'port S1') source = 'S1'
                else if (a[0] === 'port S2') source = 'S2'
                if (a[3] === 'true') condition = 'lt'
                else if (a[4] === 'true') condition = 'gt'
                else if (a[6] === 'true') condition = 'ne'
                else if (a[7] === 'true') condition = 'eq'
                if (a[5] === 'true') condition = 'nbw'
                if (
                    sessionStorage.getItem(`connectedDevice`) === 'Ace' &&
                    sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
                    source === 'battery'
                ) {
                    programSend.push({
                        type: 'sensor',
                        state: {},
                        subprogram: send,
                    })
                } else {
                    programSend.push({
                        type: 'sensor',
                        state: {
                            source: source,
                            value: Number(a[1]),
                            value2: Number(a[2]),
                            hour: 0,
                            hour2: 0,
                            minute: 0,
                            minute2: 0,
                            condition: condition,
                        },
                        subprogram: send,
                    })
                }
            }
        }
    }
    const subprogramRecursive = async (aNode) => {
        for (let i in aNode) {
            if (
                aNode[i].data.specificElType == 'if' ||
                aNode[i].data.specificElType == 'loop' ||
                aNode[i].data.specificElType == 'ifNo'
            ) {
                aNode[i].subprogram = []

                if (aNode[i].data.specificElType == 'if') {
                    let element = await elements.filter(
                        (e) =>
                            e.targetHandle === undefined ||
                            e.sourceHandle === 'rYes' //||e.sourceHandle==='rNo'
                    )
                    let y = getOutgoers(aNode[i], element)
                    await nodeGet(y, aNode[i].subprogram)
                    await nodeAddIfNo(aNode[i].subprogram)
                    await subprogramRecursive(aNode[i].subprogram)
                } else if (aNode[i].data.specificElType == 'ifNo') {
                    let element = await elements.filter(
                        (e) =>
                            e.targetHandle === undefined ||
                            e.sourceHandle === 'rNo' //||e.sourceHandle==='rNo'
                    )
                    let y = getOutgoers(aNode[i], element)
                    await nodeGet(y, aNode[i].subprogram)
                    await nodeAddIfNo(aNode[i].subprogram)
                    await subprogramRecursive(aNode[i].subprogram)
                } else if (aNode[i].data.specificElType == 'loop') {
                    let element = await elements.filter(
                        (e) =>
                            e.targetHandle === undefined ||
                            e.sourceHandle === 'r' //||e.sourceHandle==='rNo'
                    )
                    let y = getOutgoers(aNode[i], element)
                    await nodeGet(y, aNode[i].subprogram)
                    await nodeAddIfNo(aNode[i].subprogram)
                    await subprogramRecursive(aNode[i].subprogram)
                }
            }
        }
    }
    const bytesSending = async (data) => {
        console.log(data, 'vishal')
        // if (workerCondition) {
        try {
            const SendBytes = new Uint8Array(data)
            const rest = new Uint8Array([
                'R'.charCodeAt(0),
                'E'.charCodeAt(0),
                'S'.charCodeAt(0),
                'T'.charCodeAt(0),
            ])
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
            props.worker.onmessage = async (e) => {
                if (e.data.type === 'read' && e.data.value.includes('OK')) {
                    props.worker.postMessage({
                        type: 'write',
                        value: 'REST',
                    })
                    setFeedBackMsg('Upload successful')
                    setTimeout(() => setuploadOpen(false), 2000)
                } else if (
                    e.data.type === 'read' &&
                    e.data.value.includes('PAREST')
                ) {
                    // ;
                    await new Promise((resolve, reject) =>
                        setTimeout(() => {
                            resolve()
                        }, 2500)
                    )
                    props.worker.postMessage({
                        type: 'writeArray',
                        value: data,
                    })
                }
            }
        } catch (e) {
            console.log(e)
        }
        // } else {
        //     await sendBytes(data, props.webSerial.port)

        //     let replyOK = await readBytes(props.webSerial.port, 'OK')
        //     //;
        //     if (replyOK === true) {
        //         // ;
        //         if (replyOK === true) {
        //             await sendBytes(
        //                 [
        //                     'R'.charCodeAt(0),
        //                     'E'.charCodeAt(0),
        //                     'S'.charCodeAt(0),
        //                     'T'.charCodeAt(0),
        //                 ],
        //                 props.webSerial.port
        //             )
        //             setFeedBackMsg('Upload successful')
        //             setTimeout(() => {
        //                 setuploadOpen(false)
        //             }, 2000)
        //         }
        //     } else if (replyOK == 'PAREST') {
        //         // ;
        //         setTimeout(async () => {
        //             await bytesSending(data)
        //         }, 2500)
        //     } else {
        //     }
        // }
    }
    const [uploadOpen, setuploadOpen] = useState(false)
    const sendByte = async () => {
        if (microPython) {
            return
        }
        if (!props.webSerial.isConnected) {
            setFeedBackMsg('Kindly connect your device before uploading!')
            setuploadOpen(true)
        }
        sessionStorage.setItem('isPlay', false)
        //aNode global changed
        saveDataToLocalStorage()

        let aNode = [],
            aNode1 = [],
            y = [],
            testSingleD = []

        y = getOutgoers(elements[0], elements)

        await nodeGet(y, aNode)
        await nodeAddIfNo(aNode)

        await subprogramRecursive(aNode)

        testSingleD = aNode

        let programSend = []
        programSend.push({
            type: 'start',
            state: {
                bic1: false,
                bic2: false,
                bic3: false,
                bid2: false,
                bif1: false,
                bif2: false,
                bif3: false,
            },
            bic1: false,
            bic2: false,
            bic3: false,
            bid1: false,
            bid2: false,
            bif1: false,
            bif2: false,
            bif3: false,
            bid3: false,
            bmp3: false,
            id: '10',
        })

        await propertyPanelConversion(testSingleD, programSend)
        console.log('gsk rgb ', programSend, testSingleD)
        try {
            if (
                testSingleD.length === 0 ||
                !['end', 'loop', 'end/repeat', 'end1'].includes(
                    testSingleD[testSingleD.length - 1].data.specificElType
                )
            ) {
                setFeedBackMsg('Please end the program with Loop or End.')
                setuploadOpen(true)
                return
            } else {
                if (
                    testSingleD[testSingleD.length - 1]?.data
                        ?.specificElType === 'end' ||
                    testSingleD[testSingleD.length - 1]?.data
                        ?.specificElType === 'end1'
                )
                    params.logic.end.state = 'end'
                else params.logic.end.state = 'repeat'
            }

            Object.assign(params.logic, { program: programSend })

            Object.assign(params.logic, {
                insertState: false,
                offset: {
                    left: 0,
                    top: 0,
                },
                scale: 1,
                currentProgramGuide: -1,
                active: [-1, -1],
                bottomPanel: 'border',
            })

            getBytes({ code: params })
            let bytes = sessionStorage.getItem('convert_Bytes')
            var data = bytes.split(',')

            //await writePort(data);
            // Sending Bytes and Receiving for 'OK' acknowledgement

            console.log(await props.webSerial.port, 'gsk prot')
            //await this.props.webSerial.port.readable.getReader().releaseLock();
            // if (workerCondition) {
            console.log('flow', data)
            setuploadOpen(true)
            setFeedBackMsg('Uploading ...')
            await bytesSending(data)
            // } else {
            //     if (!(await props.webSerial.port.readable.locked)) {
            //         setuploadOpen(true)
            //         setFeedBackMsg('Uploading ...')
            //         await bytesSending(data)
            //     } else {
            //         await window.location.reload()
            //         setuploadOpen(true)
            //         setFeedBackMsg('Uploading ...')
            //         // setTimeout(async () => {
            //         await bytesSending(data)
            //         //}, 4000);
            //     }
            // }
            // await sendBytes(data, props.webSerial.port);

            // const replyOK = await readBytes(props.webSerial.port, "OK");

            // if (replyOK) {
            //   sendBytes(
            //     [
            //       "R".charCodeAt(0),
            //       "E".charCodeAt(0),
            //       "S".charCodeAt(0),
            //       "T".charCodeAt(0),
            //     ],
            //     props.webSerial.port
            //   );
            //   // this.setState({ uploadSucess: true });
            // }

            // setTimeout(() => {

            // }, 3500);
            //socket.emit("/getSimulateBytes", { code: params });
        } catch (e) {}
    }

    const writePort = async (data) => {
        try {
            const filters = [{ usbVendorId: 0x1a86, usbProductId: 0x7523 }]
            const ports = await navigator.serial.getPorts({ filters })
            console.log('DATAAAS', data)
            const writer = ports[0].writable.getWriter()

            const sata = data
            const data1 = new Uint8Array(sata) // hello// 82, 76, 0, 0, 0, 82, 0, 0, 0, 66, 0, 0, 1, 0, 1,

            await writer.write(data1)

            writer.releaseLock()
        } catch (e) {}
    }
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    }

    useEffect(() => {
        sessionStorage.setItem('isReadingZingPA', false)
    }, [])

    const handleClose = async () => {
        const version = sessionStorage.getItem('deviceVersion')

        if (sessionStorage.getItem('isReadingZingPA') == 'true') {
            sessionStorage.setItem('isReadingZingPA', false)
            continousPABytesReadToggle(
                null,
                null,
                props.webSerial.port,
                null,
                'OFF'
            )
        }

        // if (!workerCondition && (await props.webSerial.isConnected)) {
        // if (await props.webSerial.port.readable.locked) {
        //     sessionStorage.setItem('isRead', false)
        // }
        // } else {
        if (
            modalType === 'if' &&
            (await props.webSerial.isConnected) &&
            JSON.parse(sessionStorage.getItem('isRead'))
        ) {
            const M8 = new Uint8Array(['M'.charCodeAt(0), '8'.charCodeAt(0)])
            sessionStorage.setItem('isRead', false)
            // await props.webSerial.port.transferOut(2, M8)
            props.worker.postMessage({
                type: 'writeArray',
                value: M8,
            })
        }
        // }
        saveDataToLocalStorage()

        setShow(false)
        setModalType('')
        setTimeout(() => {
            click(260, 200)
        }, 0)
    }
    let nodeX = 100
    let nodeY = 100
    const handleShow = () => setShow(true)

    const align = async () => {
        saveDataToLocalStorage()

        sessionStorage.setItem('planeOffset', null)
        let a = []

        await nodeGet([elements[0]], a)
        await nodeAddIfNo(a)
        await subprogramRecursive(a)

        await posAlignOld(a, nodeX, nodeY)

        largX = a[0].position.x
        await alignOffset(a)
        await alignOffsetMoving()

        history.push('/flow/digital-analog')
        history.push('/flow/flowchart')
    }
    const reArrange = async (a) => {
        for (let i in a) {
            if (a[i].data.specificElType == 'ifNo') {
                let temp = a[i]
                a[i] = a[i - 1]
                a[i - 1] = temp
                await reArrange(a[i - 1].subprogram)
                await reArrange(a[i].subprogram)
            }
        }
    }
    let largX,
        nonOffsetElement = [],
        offsetElement = []
    const alignOffset = async (a) => {
        for (let i in a) {
            await elements.map((ele) => {
                if (ele.id === a[i].id) {
                    nonOffsetElement.push(ele.id)
                    if (ele.position.x >= largX) largX = ele.position.x
                }
            })
            await alignOffset(a[i].subprogram)
        }
    }
    const alignOffsetMoving = async () => {
        await elements.map((ele) => {
            if (ele.id.search('react') == -1) {
                offsetElement.push(ele.id)
            }
        })
        for (let i in offsetElement) {
            nonOffsetElement.map((ele) => {
                console.log('GJ')
                if (ele == offsetElement[i]) offsetElement[i] = -1
            })
        }
        offsetElement = offsetElement.filter((e) => e !== -1)
        let addon = 100
        for (let i in offsetElement) {
            await setElements((els) =>
                els.map((el) => {
                    if (el.id === offsetElement[i]) {
                        el.position.x = 400 + largX + addon
                    }

                    return el
                })
            )
            addon = addon + 100
        }
    }

    const posAlignOld = async (a, nodeX, nodeY) => {
        let n
        for (let i in a) {
            if (Object.keys(a).length === 0) return { nodeY, nodeX }
            else if (
                a[i].data.specificElType === 'loop' ||
                a[i].data.specificElType === 'if'
            ) {
                a[i].position.x = nodeX
                a[i].position.y = nodeY
                // let y=nodeY
                // await posAlign(a[i].subprogram,nodeX+200,nodeY)
                if (a[i].data.specificElType !== 'if') {
                    n = await posAlignOld(
                        a[i].subprogram,
                        nodeX + 200,
                        nodeY + 80
                    )
                    nodeY = n.nodeY
                } else {
                    n = await posAlignOld(
                        a[i].subprogram,
                        nodeX + 500,
                        nodeY + 80
                    )
                }
            } else if (a[i].data.specificElType === 'ifNo') {
                await setElements((els) =>
                    els.map((el) => {
                        if (
                            el.id === `${a[i].id}` &&
                            el.data.specificElType !== 'ifNo'
                        ) {
                            el.position.x = nodeX
                            el.position.y = nodeY
                        }

                        return el
                    })
                )
                if (a[i - 1].subprogram.length === 0)
                    n = await posAlignOld(
                        a[i].subprogram,
                        nodeX + 200,
                        nodeY + 50
                    )
                else {
                    try {
                        n = await posAlignOld(
                            a[i].subprogram,
                            nodeX + 200,
                            a[i - 1].subprogram[a[i - 1].subprogram.length - 1]
                                .position.y
                        )
                    } catch (e) {
                        console.log(e)
                        n = await posAlignOld(
                            a[i].subprogram,
                            nodeX + 200,
                            nodeY + 50
                        )
                    }
                }

                nodeY = n.nodeY
            } else {
                a[i].position.x = nodeX
                a[i].position.y = nodeY
                nodeY = nodeY + 100
            }
        }
        for (let i in a)
            await setElements((els) =>
                els.map((el) => {
                    if (
                        el.id === `${a[i].id}` &&
                        a[i].data.specificElType !== 'ifNo'
                    ) {
                        console.log('@@done')
                        el = a[i]
                    }

                    return el
                })
            )
        return { nodeY }
    }

    const saveDataToLocalStorage = () => {
        let programMode = sessionStorage.programMode
        let connectedDevice = sessionStorage.connectedDevice
        let version = sessionStorage.deviceVersion
        if (localStorage.getItem('recoveryData') === null) {
            localStorage.setItem(
                'recoveryData',
                JSON.stringify({
                    [programMode]: {
                        [connectedDevice]: {
                            [version]: sessionStorage,
                        },
                    },
                })
            )
        } else {
            let updatelocalStorage = JSON.parse(
                localStorage.getItem('recoveryData')
            )
            if (programMode in updatelocalStorage) {
                if (connectedDevice in updatelocalStorage[programMode]) {
                    updatelocalStorage[programMode][connectedDevice][version] =
                        sessionStorage
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                } else {
                    updatelocalStorage[programMode][connectedDevice] = {
                        [version]: sessionStorage,
                    }
                    localStorage.setItem(
                        'recoveryData',
                        JSON.stringify(updatelocalStorage)
                    )
                }
            } else {
                updatelocalStorage[programMode] = {
                    [connectedDevice]: {
                        [version]: sessionStorage,
                    },
                }
                localStorage.setItem(
                    'recoveryData',
                    JSON.stringify(updatelocalStorage)
                )
            }
        }
    }

    const backBtnAction = () => {
        const intervalId = sessionStorage.getItem('readSetIntervalId')
        if (
            sessionStorage.getItem('deviceVersion')?.startsWith('1') &&
            ['Ace'].includes(connectedDevice) &&
            intervalId !== null
        ) {
            clearInterval(intervalId)
            sessionStorage.removeItem('readSetIntervalId')
        }

        saveDataToLocalStorage()

        history.push('/flow/digital-analog')
    }
    const saveFlow = () => {
        localStorage.setItem('recoveryData', JSON.stringify(sessionStorage))

        history.push('/flow/save')
        // history.push({
        //   pathname: "/flow/save",
        //   state: { check: modal },
        // });
    }

    const [p1, setP1] = useState({
        selected: false,
        port: {},
    })
    const [isUsb, setUsb] = useState(false)

    useEffect(() => {
        let no_port = props.webSerial
        if (typeof no_port !== undefined) {
            console.log('WORKING>>>>>>>>')
            OpenReadComPort()
        }
        let data = JSON.parse(sessionStorage.getItem('user'))

        if (data === 1) {
            setUsb(true)
        }
        if (data === 0) {
            setUsb(false)
        }
    })

    const OpenReadComPort = async () => {
        const p_Port = props.webSerial.port

        console.log(props, 'p_Port')

        try {
            console.log('OPENED')
            //await p_Port.open({ baudRate: 120000 });
        } catch (e) {
            console.log(e)
            // p_Port.close();
            // await p_Port.open({ baudRate: 120000 });
        }

        writePort('notWrite')
        console.log(p_Port, 'p_Port')
    }

    const onMove = async (event, viewport) => {
        sessionStorage.setItem('planeOffset', JSON.stringify(event))
    }

    const [{}, drop] = useDrop(
        () => ({
            accept: 'yellow',
            drop(_item, monitor) {
                console.log('GSKDND', monitor.isDragging)
                onDrop(monitor.getItemType())
                return undefined
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
                draggingColor: monitor.getItemType(),
            }),
        }),
        [onDrop]
    )
    const onMouseMove = (e) => {
        // console.log(e.clientX, e.clientY)
    }
    const onEdgeUpdate = (e, edge) => {
        let ele = document.getElementsByClassName('react-flow__edge')
        for (let i = 0; i < ele.length; i++) {
            console.log(edge, ele, 'gskkkkkkkkkkkkkkkkkkk')
            //ele[i].style.cursor = cursor, auto;
        }
    }
    const clearProgramTrue = () => {
        saveDataToLocalStorage()

        setClearProgramModal(true)
    }
    const clearProgramfalse = () => {
        setClearProgramModal(false)
    }
    const clearProgram = () => {
        sessionStorage.setItem('isSave', JSON.stringify(false))
        sessionStorage.setItem('flowchart-elements', null)
        sessionStorage.setItem('flowchart-elements-id', null)

        setElements([
            {
                id: '0',
                position: { x: 500, y: 65 },
                type: `input`,
                data: {
                    label: text1(`start`, 0),
                    elType: 'node',
                    specificElType: `start`,
                },
            },
        ])
        setClearProgramModal(false)
        // window.location.reload()
    }
    const customStylesUpload = {
        content: {
            top: '50%',
            left: '50%',
            height: ' 30%',
            width: ' 30%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            zIndex: 1000000,
            borderRadius: '15px',
            transform: 'translate(-50%, -50%)',
            border: '5px solid orange',
        },
    }

    const [isToastActive, setToastActive] = useState(false)
    const toastRef = useRef()
    const [toastMsg, setToastMsg] = useState('')
    const [ishelpButton, sethelpButton] = useState(false)
    const handleClickhelpbtn = () => {
        sethelpButton(true)
    }

    const closeModal = () => {
        sethelpButton(false)
    }

    const [showStartModal, setShowStartModal] = useState(false)

    useEffect(() => {
        cacheAssets([flowchartBg])
    }, [])
    return (
        <>
            {showStartModal && (
                <StartPanel handleClose={() => setShowStartModal(false)} />
            )}
            <DialogModal
                show={clearProgramModal}
                text="Are you sure you want to clear this program ?"
                optionsToSelect={[
                    {
                        text: 'YES',
                        do: clearProgram,
                    },
                    {
                        text: 'NO',
                        do: clearProgramfalse,
                    },
                ]}
            />
            <div className="HeaderContainer">
                <div
                    style={{
                        height: '10%',
                        width: '100%',
                        // border: "1px solid red",
                        // background: "red",
                        position: 'absolute',
                        userSelect: 'none',
                    }}
                >
                    <DialogModal
                        show={uploadOpen}
                        text={feedbackMsg}
                        showCloseBtn={true}
                        handleDialogModalClose={() => {
                            setuploadOpen(false)
                        }}
                        // optionsToSelect={optionsToSelect}
                    />
                    <ProgramHeader
                        data={{
                            options: [
                                'Select Ports',
                                'Input/Output',
                                'Digital/Analog',
                                'Flowchart',
                            ],
                            selected: 3,
                        }}
                        showSave={true}
                        showHelp={true}
                        handleHelp={handleClickhelpbtn}
                        showClear={true}
                        handleClear={clearProgramTrue}
                        showFlowchartAlign={true}
                        handleFlowchartAlign={align}
                    />
                </div>
            </div>

            <div className={'slide-left'} style={{ height: '100vh' }}>
                {' '}
                <div className="dndflow">
                    <ReactFlowProvider>
                        <Sidebar />
                        <CustomDragLayer />
                        <div className="reactflow-wrapper">
                            <ReactFlow
                                elements={elements}
                                onConnect={onConnect}
                                onElementsRemove={onElementsRemove}
                                onLoad={onLoad}
                                onDrop={onDrop}
                                onDoubleClick={onDoubleClick}
                                onElementClick={onElementClick}
                                onDragOver={onDragOver}
                                zoomOnDoubleClick={false}
                                onNodeDrag={onNodeDrag}
                                onNodeDragStop={onNodeDragStop}
                                onNodeMouseLeave={onNodeMouseLeave}
                                onNodeMouseEnter={onNodeMouseEnter}
                                // onMouseMove={onMouseMove}
                                onMove={onMove}
                                onEdgeMouseEnter={onEdgeUpdate}
                                className="react-flow-screen"
                                style={{ height: '88.3vh', width: 'inherit' }}
                                id="reactFlow"
                                ref={drop}
                            >
                                <canvas
                                    id="myCanvas"
                                    width="1775"
                                    height="884"
                                    className="react-flow__edges"
                                ></canvas>
                            </ReactFlow>
                            {showPopup ? <Popup /> : null}
                        </div>
                    </ReactFlowProvider>

                    <Modal show={show}>
                        <Modal.Body>
                            <img
                                src={closeImg}
                                alt="close"
                                onClick={handleClose}
                                style={{
                                    position: 'relative',
                                    width: '7vh',
                                    top: '13%',
                                    zIndex: '100',
                                    right: '-97%',
                                    transform: 'translate(-100%,-50%)',
                                    cursor: 'pointer',
                                }}
                            ></img>
                            <Panel
                                value={{ ms: 0, s: 0, m: 0, h: 16 }}
                                state={{ h: 0, m: 0, ms: 0, s: 0 }}
                                check={modal}
                                onChange={() => {
                                    console.log('hello===>')
                                }}
                                current={'sensor'}
                                handler={handler}
                                passEnd={passEnd}
                                FWpopUp={FWupdatePopUp}
                            />
                        </Modal.Body>
                        {updateFWpopUp ? (
                            <div className="FWUpdatePopUp">
                                "Read sensor" paramaters are supported only in
                                new Firmware. Please update the firmware -{' '}
                                <a
                                    target="_blank"
                                    href="https://firmware.plode.org/"
                                >
                                    Update Here
                                </a>
                            </div>
                        ) : (
                            <></>
                        )}
                    </Modal>
                </div>
            </div>

            <div className="SelectScreenBottom" style={{ zIndex: 'auto' }}>
                <div className="bottom-child">
                    <img
                        className="iconBtnSize imgBackBtn"
                        src={renderPrgImage('backBtn')}
                        onClick={backBtnAction}
                    />
                    {/* </Link> */}

                    <img
                        className="iconBtnSize imgNextBtn"
                        src={renderPrgImage('uploadBtn')}
                        onClick={sendByte}
                        style={{
                            zIndex: '10',
                            opacity: microPython ? '0.5' : '1',
                            cursor: microPython ? 'default' : 'pointer',
                        }}
                    />
                </div>
            </div>

            {/* {ishelpButton == true ? (
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        background: '#fff',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '1000',
                    }}
                >
                    <img
                        src={flowchartBg}
                        style={{ height: '100%', width: '100%' }}
                    />

                    <div
                        onClick={closeModal}
                        style={{
                            height: '50px',
                            width: '50px',
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '65px',
                            right: '84px',
                        }}
                    >
                        <img src={closeImg} width="70" height="auto" />
                    </div>
                </div>
            ) : null} */}

            {ishelpButton == true ? (
                <div
                    style={{
                        height: '100vh',
                        width: '100vw',
                        background: '#fff',
                        position: 'relative',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%,-50%)',
                        zIndex: '1000',
                        position: 'absolute',
                    }}
                >
                    <div
                        onClick={closeModal}
                        style={{
                            borderRadius: '50%',
                            zIndex: '2000',
                            position: 'absolute',
                            top: '15%',
                            right: '18%',
                        }}
                    >
                        <img
                            src={closeImg}
                            style={{ width: '7vh', height: 'auto' }}
                        />
                    </div>
                    <Slider
                        {...settings}
                        style={{
                            transform: 'translate(-50%,-50%)',
                            top: '46%',
                            left: '50%',
                            zIndex: '10',
                            position: 'absolute',
                        }}
                    >
                        {/* First screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img src={flowchartBg} style={{ width: '100%' }} />

                            <div
                                style={{
                                    // height: "90px",
                                    width: '3.5%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '48%',
                                    left: '30.6%',
                                    background: '#fff',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '6px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Flow chart based programming screen-various
                                    blocks of programming are available on the
                                    left panel, which can be dragged-and-dropped
                                    to the workspace area and connected to each
                                    other serially
                                </p>
                            </div>
                        </div>
                        {/* second screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img src={flowchartBg} style={{ width: '100%' }} />
                            <div
                                style={{
                                    // height: "150px",
                                    width: '4%',
                                    zIndex: '2000',
                                    position: 'absolute',
                                    top: '36%',
                                    left: '41.4%',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    background: '#fff',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '6px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular ',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    Every block once dragged to the workspace
                                    area, has a property panel, which can be
                                    opened on tapping on that block
                                </p>
                            </div>
                        </div>
                        {/* Third Screen */}
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                background: '#fff',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                zIndex: '1000',
                            }}
                        >
                            <img src={flowchartBg} style={{ width: '100%' }} />
                            <div
                                style={{
                                    // height: "160px",
                                    width: '2.8%',
                                    // zIndex: "2000",
                                    position: 'absolute',
                                    top: '45%',
                                    left: '55.3%',
                                    fontSize: '22px',
                                    borderRadius: '35px',
                                    transform: 'translate(-50%,-50%)',
                                    background: '#fff',
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '6px',
                                        fontSize: '1.8vh',
                                        textAlign: 'left',
                                        color: '#707070',
                                        fontFamily: 'Halcyon_Regular ',
                                        borderRadius: '12px',
                                        marginBottom: '0px',
                                    }}
                                >
                                    To remove a connection or block, drag the
                                    respective block to the left panel and drop
                                    it there
                                </p>
                            </div>
                        </div>
                    </Slider>
                </div>
            ) : null}

            <div
                className={`toast-msg 
        ${isToastActive ? 'toast-msg-active' : 'toast-msg-inactive'}`}
                ref={toastRef}
            >
                {toastMsg}
            </div>
        </>
    )
}

// export default DnDFlow;

const mapStateToProps = (state) => {
    console.log('mapStateToProps', state)

    return state
}
const mapDispatchToProps = (dispatch) => {
    return {
        webSerialConnect: (data) => {
            dispatch(webSerialConnect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DnDFlow)
