import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router'

import renderImage from '../../source/importImg'

import AdvanceSettingStyle from './AdvanceSetting.module.css'
import cacheAssets from '../../utils/cacheAssets'
import AdvanceSlider from '../ReusableComponents/AdvanceSetSlider/AdvanceSlider'
const divstyle = {
    // backgroundColor: 'rgba(106, 90, 90, 0.5)',
    position: 'absolute',
    top: '30%',
    height: '30%',
    width: '13%',
    // marginTop: '5%',
    // marginLeft: '5%',
    cursor: 'pointer',
}

function AdvanceSettingV1(props) {
    let history = useHistory()
    const version = sessionStorage.getItem('deviceVersion')
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    const isHumanoid2 =
        connectedDevice == 'Humanoid' && version?.startsWith('2')

    const [isHelp, setHelp] = useState(false)
    const handleHelpBtn = (e) => {
        if (isHelp == true) {
            setHelp(false)
        } else {
            setHelp(true)
        }
    }

    const gobackUrl = () => {
        history.push('/Selection')
    }
    const firmwareUpdate = () => {
        window.location.href = 'https://firmware.plode.org/PeeCeeBeta'
    }
    const AccountSetting = () => {
        history.push('/accountSetting')
    }
    const aboutClick = () => {
        history.push('/about')
    }
    const operationModeHandel = () => {
        history.push('/operationMode')
    }

    useEffect(() => {
        let assetsToBeCached = [
            renderImage('backBtn'),
            renderImage('firmwareUpdate'),
            renderImage('AccountSetting'),
        ]
        cacheAssets(assetsToBeCached)
    }, [])

    return (
        <>
            <div className={AdvanceSettingStyle.Main_Select}>
                <div className={AdvanceSettingStyle.Select_Header}>
                    <div>
                        <img
                            className={AdvanceSettingStyle.Back_Btn}
                            src={renderImage('backBtn')}
                            onClick={gobackUrl}
                        ></img>
                    </div>

                    <div style={{ position: 'relative' }}>
                        <p className={AdvanceSettingStyle.text}>
                            Advance Setting
                        </p>
                    </div>
                    {/* <div id={AdvanceSettingStyle.Help_Bttn}>
                        {isHelp == false ? (
                            <div className="header_button">
                                <img
                                    className={
                                        AdvanceSettingStyle.Help_Bttn_Img
                                    }
                                    src={renderImage('helpBtnInActive')}
                                    onClick={handleHelpBtn}
                                ></img>
                                <span
                                    className="tooltiptext"
                                    style={{ left: '90.3%' }}
                                >
                                    {' '}
                                    <p>Help</p>
                                </span>
                            </div>
                        ) : (
                            <div style={{ position: 'relative' }}>
                                {' '}
                                <div className={AdvanceSettingStyle.S_slide}>
                                    <AdvanceSlider />
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '23vh',
                                        left: '40vw',
                                        transform: 'translate(-50%,-50%)',
                                        zIndex: '100000',
                                    }}
                                >
                                    <img
                                        className={
                                            AdvanceSettingStyle.helpClose
                                        }
                                        src={renderImage('clos')}
                                        onClick={handleHelpBtn}
                                    ></img>
                                </div>
                            </div>
                        )}
                    </div> */}
                </div>

                {/* <div className={AdvanceSettingStyle.setting_Body}>
                    <div onClick={AccountSetting}>
                        <img
                            className={AdvanceSettingStyle.setting_BodyDiv}
                            src={renderImage('AccountSetting')}
                        ></img>
                        <div className={AdvanceSettingStyle.Body_Text}>
                            <p className={AdvanceSettingStyle.P_Text}>
                                Account <br /> Setting
                            </p>
                        </div>
                    </div>

                    <div onClick={firmwareUpdate}>
                        <img
                            className={AdvanceSettingStyle.setting_BodyDiv1}
                            src={renderImage('firmwareUpdate')}
                        ></img>
                        <div className={AdvanceSettingStyle.Body_Text1}>
                            <p className={AdvanceSettingStyle.P_Text1}>
                                Firmware <br /> Update
                            </p>
                        </div>
                    </div>

                    <div onClick={aboutClick}>
                        <img
                            className={AdvanceSettingStyle.setting_BodyDiv3}
                            src={renderImage('firmwareUpdate')}
                        ></img>
                        <div className={AdvanceSettingStyle.Body_Text3}>
                            <p className={AdvanceSettingStyle.P_Text1}>About</p>
                        </div>
                    </div>
                </div> */}

                <div
                    // className={`${
                    //     slideDirection === 'true' ? 'slide-right' : ''
                    // }`}
                    style={{ height: '100%', position: 'relative' }}
                >
                    {' '}
                    <div
                        className="Play_Functions"
                        style={
                            isHumanoid2
                                ? {
                                      gridTemplateColumns:
                                          '12% 16% 3% 16% 3% 16% 3% 16% 12% ',
                                  }
                                : {
                                      gridTemplateColumns:
                                          '21.5% 16% 3% 16% 3% 16% 21.5% ',
                                  }
                        }
                    >
                        <div></div>

                        <div>
                            <div
                                style={divstyle}
                                onClick={AccountSetting}
                            ></div>

                            <img
                                alt=""
                                className="Remote_Card"
                                style={
                                    isHumanoid2
                                        ? { left: '19%' }
                                        : { left: '29%' }
                                }
                                src={renderImage('AccountSetting')}
                            ></img>
                            <div
                                style={isHumanoid2 ? { left: '15%' } : {}}
                                className={AdvanceSettingStyle.Body_Text}
                            >
                                <p className={AdvanceSettingStyle.P_Text}>
                                    Account <br /> Setting
                                </p>
                            </div>
                        </div>

                        <div></div>

                        <div>
                            <div
                                style={divstyle}
                                onClick={firmwareUpdate}
                            ></div>
                            <img
                                alt=""
                                className="Speech_Card"
                                src={renderImage('firmwareUpdate')}
                                style={
                                    isHumanoid2
                                        ? { left: '38%' }
                                        : { left: '48%' }
                                }
                            ></img>

                            <div
                                style={isHumanoid2 ? { left: '35%' } : {}}
                                className={AdvanceSettingStyle.Body_Text1}
                            >
                                <p className={AdvanceSettingStyle.P_Text1}>
                                    Firmware <br /> Update
                                </p>
                            </div>
                        </div>

                        <div></div>

                        <div
                        // style={{
                        //     display:
                        //         connectedDevice === 'Roboki'
                        //             ? 'none'
                        //             : 'block',
                        // }}
                        >
                            <div style={divstyle} onClick={aboutClick}></div>
                            <img
                                alt=""
                                className="Music_Card"
                                src={renderImage('aboutApplication')}
                                style={
                                    isHumanoid2
                                        ? { left: '57%' }
                                        : { left: '67%' }
                                }
                            ></img>

                            <div
                                style={isHumanoid2 ? { left: '54%' } : {}}
                                className={AdvanceSettingStyle.Body_Text4}
                            >
                                <p className={AdvanceSettingStyle.P_Text1}>
                                    About
                                </p>
                            </div>
                        </div>

                        <div></div>

                        {isHumanoid2 && (
                            <div>
                                <div
                                    style={divstyle}
                                    onClick={operationModeHandel}
                                ></div>
                                <img
                                    alt="Operation Modes"
                                    className="Speech_Card"
                                    src={renderImage('operationModes')}
                                    style={{ left: '76%' }}
                                ></img>
                                <div
                                    style={{ left: '72%' }}
                                    className={AdvanceSettingStyle.Body_Text1}
                                >
                                    <p className={AdvanceSettingStyle.P_Text1}>
                                        Operation <br /> Modes
                                    </p>
                                </div>
                            </div>
                        )}
                        <div></div>
                    </div>
                    {/* <p className="comingSoon_text">
                    The feature is not currently available for this device.
                    Coming soon.
                </p> */}
                </div>
                <div></div>
            </div>
        </>
    )
}

export default AdvanceSettingV1
