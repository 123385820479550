// Function to establish a serial connection
// If isAutomatic is false, a pop up is shown to manually connect to the device.
// Otherwise, we automatically get all the ports and connect to the device.
import {
    getVersion,
    getS3Version,
} from '../ReusableComponents/ByteTransfer/getVersion'
import { _0PORT } from '../flowchart_port/FlowchartConnections/Myflowchart/PortTypes'

const DEVICES = [
    {
        name: 'PeeCee Alpha',
        id: 'Ace',
        version: '0.1.13',
        usbProductId: 29987,
        src: 'PC',
    },
    {
        name: 'Crawlee',
        id: 'Hexapod',
        version: '1.0.01',
        usbProductId: 29987,
        src: 'Hexapod',
    },
    {
        name: 'Zing Beta',
        id: 'Humanoid',
        version: '1.0.01',
        usbProductId: 29987,
        src: 'Zing',
    },
    {
        name: 'Zing Alpha',
        id: 'Humanoid',
        version: '0.1.10',
        usbProductId: 29987,
        src: 'Zing',
    },
    {
        name: 'PeeCee Beta',
        id: 'Ace',
        version: '1.0.04',
        usbProductId: 4097,
        src: 'PCv1',
    },
    {
        name: 'Roboki',
        id: 'Roboki',
        version: '1.0.1',
        usbProductId: 4097,
        src: 'Roboki',
    },
    {
        name: 'Crawlee',
        id: 'Hexapod',
        version: '2.0.01',
        usbProductId: 4097,
        src: 'Hexapod',
    },
    {
        name: 'Zing Beta',
        id: 'Humanoid',
        version: '2.0.0',
        usbProductId: 4097,
        src: 'Zing',
    },
    {
        name: 'Klaw',
        id: 'Klaw',
        version: '1.0.01',
        usbProductId: 4097,
        src: 'Klaw',
    },
    {
        name: 'Toki',
        id: 'Toki',
        version: '1.0.1',
        usbProductId: 32832,
        src: 'Toki',
    },
]
const filters = [
    { usbVendorId: 0x1a86, usbProductId: 0x7523 },
    { usbVendorId: 0x303a, usbProductId: 0x1001 },
    { usbVendorId: 0x1a86, usbProductId: 0x8040 },
]

const sendCONFBytes = async (worker) => {
    let getWorkerdata = false
    let isMemoryPresent = false
    worker.postMessage({
        type: 'WriteArrayFormat1310',
        value: [
            'C'.charCodeAt(0),
            'O'.charCodeAt(0),
            'N'.charCodeAt(0),
            'F'.charCodeAt(0),
        ],
    })

    worker.onmessage = (e) => {
        const regex = /^CF.*ER$/

        if (
            e.data.type == 'actionRead' &&
            regex.test(e.data.value.stringData)
        ) {
            if (e.data.value.arrayData?.[3] == 1) {
                isMemoryPresent = true
            }
            getWorkerdata = true
        }
    }

    let timeOutVariable = setTimeout(() => {
        getWorkerdata = true
    }, 2000)
    while (!getWorkerdata) {
        await new Promise((reslove) => setTimeout(reslove, 100))
        console.log('JJJJJJJJJJJJJ')
    }
    clearTimeout(timeOutVariable)
    return isMemoryPresent
}

export const connectSerialDeviceInitial = async (
    { isAutomatic = false },
    worker
) => {
    try {
        // sessionStorage.clear('deviceDetails')
        let port = null

        if (isAutomatic) {
            const portList = await navigator.serial.getPorts({
                filters,
            })

            if (portList.length <= 0) return null
            port = portList[0]
            console.log('port', portList)
        } else port = await navigator.serial.requestPort({ filters })

        let pid = port.getInfo().usbProductId
        console.log('HHHHHHHHH', pid)

        console.log('pid', pid, worker)
        const matchedObj = DEVICES.filter((obj) => obj.usbProductId == pid)
        let allowedDevice = sessionStorage.getItem('allowedDevice')
        sessionStorage.setItem('matchedDevices', JSON.stringify(matchedObj))
        console.log('matchedObj', matchedObj, pid)
        try {
            const getConfirmation = async () => {
                return new Promise((resolve, reject) => {
                    console.log(worker)
                    worker.postMessage({ type: 'connected', pid, matchedObj })
                    worker.onmessage = (e) => {
                        if (e.data.type == 'connected' && e.data.value) {
                            resolve(true)
                        }
                        if (e.data.type == 'connected' && !e.data.value)
                            resolve(false)
                    }
                })
            }
            const confirm = await getConfirmation()
            console.log(confirm)
            if (confirm) {
                console.log('Connection established!')
                var user = 1
                sessionStorage.setItem('user', JSON.stringify(user))

                //ask version
                const { Version, device } = await getS3Version(worker)
                // alert(deviceVersion)
                //store in session
                console.log('got the version back', Version, device)
                if (!Version) return
                let matchedObj_with_Version = DEVICES.find(
                    (obj) =>
                        Version.slice(0, 1) == obj.version.slice(0, 1) &&
                        obj.id == device
                )
                //for mismatch between selection from kitSelection and connected device
                let ver = allowedDevice ? allowedDevice.slice(-1) : ''
                if (
                    allowedDevice &&
                    device == 'Ace' &&
                    allowedDevice.includes('Ace') &&
                    ver == Version[0]
                ) {
                    allowedDevice = allowedDevice.slice(
                        0,
                        allowedDevice.length - 1
                    )
                }

                let isMemoryPresent = false
                if (
                    matchedObj_with_Version.id == 'Humanoid' &&
                    Version?.startsWith('2')
                ) {
                    isMemoryPresent = await sendCONFBytes(worker)
                }

                if (allowedDevice && allowedDevice != device) {
                    let allowedDeviceObj = DEVICES.find((obj) => {
                        if (obj.id.includes('Ace')) {
                            return (
                                obj.id == device && obj.version[0] == Version[0]
                            )
                        } else return obj.id == device
                    })
                    allowedDeviceObj = {
                        ...allowedDeviceObj,
                        selected: allowedDevice,
                        version: Version,
                    }
                    console.log('allowedDeviceObj', allowedDeviceObj)
                    // sendConnection(false)
                    return {
                        isPortOpen: false,
                        port,
                        allowedDeviceObj,
                        isMemoryPresent,
                    }
                }
                sessionStorage.setItem(
                    'connectedDevice',
                    matchedObj_with_Version.id
                )

                // if (matchedObj_with_Version.id === 'Humanoid') {
                //     sessionStorage.setItem('zingVersion', Version)
                // } else {
                sessionStorage.setItem('deviceVersion', Version)
                // }
                console.log(
                    'session',
                    sessionStorage.getItem('deviceVersion'),
                    Version,
                    isMemoryPresent
                )

                return { isPortOpen: true, port, worker, isMemoryPresent }
            } else alert('unable to open port')
        } catch (e) {
            console.log(e)
        }
    } catch (e) {
        console.log(e)
    }
}
export const connectSerialDeviceIntermediate = async (
    { isAutomatic = false },
    worker
) => {
    let port = null

    if (isAutomatic) {
        const portList = await navigator.serial.getPorts({
            filters,
        })
        if (portList.length <= 0) return null
        port = portList[0]
        console.log('port', port)
    } else port = await navigator.serial.requestPort({ filters })

    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice')

    let pid = port.getInfo().usbProductId
    if (pid) sendConnection(true)
    const matchedObj = DEVICES.filter((obj) => obj.usbProductId == pid)

    console.log('matchedobj', matchedObj, pid)

    // console.log('reached', matchedObj)
    const getConfirmation = async () => {
        return new Promise((resolve, reject) => {
            worker.postMessage({ type: 'connected', pid, matchedObj })
            worker.onmessage = (e) => {
                if (e.data.type == 'connected' && e.data.value) {
                    resolve(true)
                }
                if (e.data.type == 'connected' && !e.data.value) resolve(false)
            }
        })
    }
    const confirm = await getConfirmation()
    if (confirm) {
        var user = 1
        sessionStorage.setItem('user', JSON.stringify(user))
        // console.log('Connection established!')
        // var user = 1
        // sessionStorage.setItem('user', JSON.stringify(user))

        //ask version

        const { Version, device } = await getS3Version(worker)
        console.log('worker', Version)
        // alert(deviceVersion)
        //store in session
        if (Version == 'no data found') {
            console.log('no version found')
            sessionStorage.setItem('connectedDevice', connectedDevice)
            sendConnection(false)
            return { isPortOpen: true, port }
        }
        console.log('Version', Version)
        let matchedObj_with_Version = DEVICES.find(
            (obj) =>
                Version.slice(0, 1) == obj.version.slice(0, 1) &&
                obj.id == device
        )
        matchedObj_with_Version = {
            ...matchedObj_with_Version,
            version: Version,
        }
        sessionStorage.setItem('manualConnection', false)

        console.log(
            'matchedObj_with_Version',
            connectedDevice,
            deviceVersion,
            matchedObj_with_Version
        )

        let isMemoryPresent = false
        if (
            matchedObj_with_Version.id == 'Humanoid' &&
            Version?.startsWith('2')
        ) {
            isMemoryPresent = await sendCONFBytes(worker)
        }

        if (connectedDevice == 'Humanoid') {
            if (
                connectedDevice !== device ||
                Version?.slice(0, 3) !== deviceVersion?.slice(0, 3)
            ) {
                console.log('matchedObj_with_Version', matchedObj_with_Version)
                sendConnection(false)
                return {
                    isPortOpen: false,
                    port,
                    matchedObj_with_Version,
                    isMemoryPresent,
                }
            }
        } else if (
            connectedDevice !== device ||
            Version[0] !== deviceVersion[0]
        ) {
            console.log('matchedObj_with_Version', matchedObj_with_Version)
            sendConnection(false)
            return {
                isPortOpen: false,
                port,
                matchedObj_with_Version,
                isMemoryPresent,
            }
        }
        if (connectedDevice == device && Version[0] == deviceVersion[0]) {
            sessionStorage.setItem('deviceVersion', Version)
        }

        sendConnection(false)
        return { isPortOpen: true, port, isMemoryPresent }
    } else {
    }
}
export const connectSerialDeviceOnRefresh = async (
    { isAutomatic = false },
    worker
) => {
    const portList = await navigator.serial.getPorts({
        filters,
    })

    if (portList.length <= 0) return null

    let port = portList[0]
    let pid = port.getInfo().usbProductId
    const res = await openSerialReadComPort(port, worker, pid)
    let deviceVersion = sessionStorage.getItem('deviceVersion')
    let device = sessionStorage.getItem('connectedDevice')
    worker.postMessage({ type: 'reconnect', deviceVersion, device })
    return res
}
export const connectSerialDevice = async ({ isAutomatic = false }, worker) => {
    // if (
    //     isAutomatic &&
    //     localStorage.getItem('plodeDeviceIsConnected') === 'true'
    // )
    //     return
    console.log('connected device')
    try {
        const filters = [
            { usbVendorId: 0x1a86, usbProductId: 0x7523 },
            { usbVendorId: 0x303a, usbProductId: 0x1001 },
            { usbVendorId: 0x1a86, usbProductId: 0x8040 },
        ]

        let port = null
        if (isAutomatic) {
            const portList = await navigator.serial.getPorts({
                filters,
            })
            if (portList.length <= 0) return null
            port = portList[0]
        } else {
            port = await navigator.serial.requestPort({ filters })
        }

        return await openSerialReadComPort(port, worker)
    } catch (e) {
        console.log(e)
    }
}

// Function to establish a USB connection
export const connectUSBDevice = async ({ isAutomatic = false }) => {
    // if (
    //     isAutomatic &&
    //     localStorage.getItem('plodeDeviceIsConnected') === 'true'
    // )
    //     return

    try {
        const filters = [{ vendorId: 0xb1b0, productId: 0x93c3 }]

        let port = null
        if (isAutomatic) {
            const portList = await navigator.usb.getDevices({
                filters,
            })
            console.log(portList)
            if (portList.length <= 0) return null
            port = portList[0]
        } else {
            port = await navigator.usb.requestDevice({ filters })
        }

        return await openUSBReadComPort(port)
    } catch (e) {
        console.log(e)
    }
}

// This Function opens the port and creates a two way communication
// for the browser to be able to read and write from the embedded device.
// If the port is successfully opened, we also return the port object.
export const openSerialReadComPort = async (port, worker, pid = 0) => {
    try {
        let deviceVersion = sessionStorage.getItem('deviceVersion')

        // if (
        //     (deviceVersion?.includes('1.0.0') &&
        //         sessionStorage.getItem('connectedDevice') == 'Ace') ||
        //     (deviceVersion?.includes('2.0.0') &&
        //         ['Hexapod', 'Humanoid'].includes(
        //             sessionStorage.getItem('connectedDevice')
        //         ))
        // ) {
        console.log('web worker')
        worker.postMessage({ type: 'connected', pid })
        // } else await port.open({ baudRate: 120000 })

        // if (port !== null) webSerialConnect({ port })
        console.log('Connection established!')

        var user = 1
        sessionStorage.setItem('user', JSON.stringify(user))

        return { isPortOpen: true, port }
    } catch (e) {
        console.log(e)
        // window.location.reload()
        // closeReadComPort(port)
        return { isPortOpen: false, port, errMsg: e.message }
    }
}

export const openUSBReadComPort = async (port) => {
    try {
        let configNumber_ = 1
        let interfaceNumber_ = 1
        await port.open()

        if (port.configuration === null)
            await port.selectConfiguration(configNumber_)
        console.log(port)
        await port.claimInterface(interfaceNumber_)

        console.log('Connection established!')

        var user = 1
        sessionStorage.setItem('user', JSON.stringify(user))

        return { isPortOpen: true, port }
    } catch (e) {
        console.log(e)
        // closeReadComPort(port)
        return { isPortOpen: false, port: null, errMsg: e.message }
    }
}

export const closeReadComPort = async (port, worker) => {
    try {
        console.log(port)
        worker.postMessage({ type: 'disconnected' })
        // } else {
        //     console.log('close', port)

        //     await port.close()
        // }
        sessionStorage.setItem('microPython', 'false')
        console.log('Closed readComPort')
    } catch (e) {
        sessionStorage.setItem('microPython', 'false')
        console.log(e)
        //time being solution
        await port.close()
        worker.postMessage({ type: 'disconnected' })
    }
}

const sendConnection = (val) => {
    const event = new CustomEvent('isConnecting', { detail: val })
    document.dispatchEvent(event)
}
