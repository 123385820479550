import AudioA from '../Assets/audio/A.mp3'
import AudioB from '../Assets/audio/B.mp3'
import AudioC from '../Assets/audio/C.mp3'
import AudioD from '../Assets/audio/D.mp3'
import AudioDb from '../Assets/audio/Db.mp3'
import AudioE from '../Assets/audio/E.mp3'
import AudioF from '../Assets/audio/F.mp3'
import AudioG from '../Assets/audio/G.mp3'
import add from '../Assets/Bisoft_UI/Buttons/Active with shadow/add.png'
import add3x from '../Assets/Bisoft_UI/Buttons/Active with shadow/add@3x.png'
import backbtn from '../Assets/Bisoft_UI/Buttons/Active with shadow/back@2x.png'
import clos from '../Assets/Bisoft_UI/Program/PNG/close@2x.png'
import disco_Ac from '../Assets/Bisoft_UI/Buttons/Active with shadow/disco@3x.png'
import gesture_Ac from '../Assets/Bisoft_UI/Buttons/Active with shadow/gesture@3x.png'
import PcpianoAc from '../Assets/Bisoft_UI/Buttons/Active with shadow/pc (piano).png'
import PianokeysAc from '../Assets/Bisoft_UI/Buttons/Active with shadow/piano keys.png'
import remove3x from '../Assets/Bisoft_UI/Buttons/Active with shadow/remove@3x.png'
import smile1_Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile 1.png'
import smile2_Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile 2.png'
import smile3_Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile 3.png'
import smile4_Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/smile 4.png'
import talk_Ac from '../Assets/Bisoft_UI/Buttons/Active with shadow/talkback@3x.png'
import UsbOff from '../Assets/Bisoft_UI/Buttons/Active with shadow/usb - off.png'
import UsbOn from '../Assets/Bisoft_UI/Buttons/Active with shadow/usb - on.png'
import reconnect from '../Assets/Bisoft_UI/Buttons/Active without shadow/cta button 152x52 - fill.png'
import reconnectSvg from '../Assets/Bisoft_UI/Buttons/SVG/ctabuttonSvg.svg'
import leftArrow from '../Assets/Bisoft_UI/Buttons/Active without shadow/left-arrow.png'
import rightArrow from '../Assets/Bisoft_UI/Buttons/Active without shadow/right-arrow.png'
import add3xIA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/add@3x.png'
import devices from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/devices.png'
import disco_IA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/disco@3x.png'
import gesture_IA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/gesture@3x.png'
import helpBtnInActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/help@2x.png'
import plodeIconSvg from '../Assets/Bisoft_UI/Main/SVG/plodeIconSvg.svg'
import leadingPageSvg from '../Assets/Bisoft_UI/Main/SVG/leadingPageSvg.svg'
import yuduRobotics from '../Assets/Bisoft_UI/Main/PNG/yuduRobotics.png'

import Hexapod from '../Assets/hexapod/HexapodMain@2x.png'
import hexapod from '../Assets/hexapod/HexapodMain@3x.png'
//MUSIC Screen
import PcpianoIA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/pc (piano).png'
import PianokeysIA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/piano keys.png'
import remove3xIA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/remove@3x.png'
import skip from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/skip@3x.png'
import smile1 from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile 1.png'
import smile2 from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile 2.png'
import smile3 from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile 3.png'
import smile4 from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/smile 4.png'
import talk_IA from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/talkback@3x.png'
import AddInfo from '../Assets/Bisoft_UI/Help screen/Main/Group 3172@2x.png'
import Connect from '../Assets/Bisoft_UI/Help screen/Main/Group 3173@2x.png'
import PlyComp from '../Assets/Bisoft_UI/Help screen/Main/property panel@2x.png'
import Ply from '../Assets/Bisoft_UI/Help screen/Play/Group 3174@2x.png'
import Prog from '../Assets/Bisoft_UI/Help screen/Play/Group 3175@2x.png'
import Bild from '../Assets/Bisoft_UI/Help screen/Play/Group 3176@2x.png'
import Remt from '../Assets/Bisoft_UI/Help screen/Play/Group 3177@2x.png'
import Peech from '../Assets/Bisoft_UI/Help screen/Play/Group 3178@2x.png'
import Msic from '../Assets/Bisoft_UI/Help screen/Play/Group 3179@2x.png'
import Camr from '../Assets/Bisoft_UI/Help screen/Play/Group 3180@2x.png'
import Remt1 from '../Assets/Bisoft_UI/Help screen/Play/Group 3181@2x.png'
import Remt2 from '../Assets/Bisoft_UI/Help screen/Play/Group 3182@2x.png'
import Remt3 from '../Assets/Bisoft_UI/Help screen/Play/Group 3183@2x.png'
import Remt4 from '../Assets/Bisoft_UI/Help screen/Play/Group 3184@2x.png'
import Remt5 from '../Assets/Bisoft_UI/Help screen/Play/Group 3185@2x.png'
import Remt6 from '../Assets/Bisoft_UI/Help screen/Play/Group 3186@2x.png'
import Spch1 from '../Assets/Bisoft_UI/Help screen/Play/Group 3187@2x.png'
import Spch2 from '../Assets/Bisoft_UI/Help screen/Play/Group 3188@2x.png'
import Musc1 from '../Assets/Bisoft_UI/Help screen/Play/Group 3189@2x.png'
import Musc2 from '../Assets/Bisoft_UI/Help screen/Play/Group 3190@2x.png'
import Musc3 from '../Assets/Bisoft_UI/Help screen/Play/Group 3191@2x.png'
import Musc4 from '../Assets/Bisoft_UI/Help screen/Play/Group 3192@2x.png'

import Cam1 from '../Assets/Bisoft_UI/Help screen/Play/Group 3193@2x.png'
import Cam2 from '../Assets/Bisoft_UI/Help screen/Play/Group 3194@2x.png'
import bisoft_logo from '../Assets/Bisoft_UI/Main/PNG/bisoft logo.png'
import Computer from '../Assets/Bisoft_UI/Main/PNG/computer.png'
import PC_New from '../Assets/playcomp_updated/PC_New@2x.png'
import leading_page from '../Assets/Bisoft_UI/Main/PNG/leading page graphics.png'
import Pc from '../Assets/Bisoft_UI/Main/PNG/PC_image.png'
import PCv1 from '../Assets/Bisoft_UI/Main/PNG/PC_v1_image@2x.png'
import PCv1_3x from '../Assets/Bisoft_UI/Main/PNG/PC_v1_image@3x.png'
import RoboticArm from '../Assets/Bisoft_UI/Play/PNG/RoboticArm@3x.png'
import PCimg from '../Assets/Bisoft_UI/Main/PNG/PC_image@2x.png'
import PC from '../Assets/Bisoft_UI/Main/PNG/PC_image@2x.png'
import Zing from '../Assets/Bisoft_UI/Main/PNG/Zing_image@2x.png'
import FourteethIA_Svg from '../Assets/Bisoft_UI/Play/SVG/4 in 1 - inactive.svg'
import FourteethAc_Svg from '../Assets/Bisoft_UI/Play/SVG/4 in 1 - active.svg'
import Blueslider_Svg from '../Assets/Bisoft_UI/Play/SVG/blue_slider.svg'
import buzzerAc_Svg from '../Assets/Bisoft_UI/Play/SVG/buzzer - active.svg'
import buzzerIA_Svg from '../Assets/Bisoft_UI/Play/SVG/buzzer - inactive.svg'
import buzzer_bg_Svg from '../Assets/Bisoft_UI/Play/SVG/buzzer property bg.svg'
import Camera_Svg from '../Assets/Bisoft_UI/Play/SVG/camera group button.svg'
import Disconnected_Svg from '../Assets/Bisoft_UI/Play/SVG/disconnected.svg'
import pianoActiveBtn from '../Assets/Bisoft_UI/Play/PNG/pianoActiveBtn.png'
import newPc from '../Assets/Bisoft_UI/Main/SVG/newPc.svg'
//REMOTE Screen
import eyeAc_Svg from '../Assets/Bisoft_UI/Play/SVG/eye - active.svg'
import eyeIA_Svg from '../Assets/Bisoft_UI/Play/SVG/eye - inactive.svg'
import dice_IA from '../Assets/Bisoft_UI/Play/SVG/dice_inactive.svg'
import dice_AC from '../Assets/Bisoft_UI/Play/SVG/dice_active.svg'
import simon_IA from '../Assets/Bisoft_UI/Play/SVG/simon_inactive.svg'
import simon_AC from '../Assets/Bisoft_UI/Play/SVG/simon_active.svg'
import guitar_IA from '../Assets/Bisoft_UI/Play/SVG/guitar_inactive.svg'
import guitar_AC from '../Assets/Bisoft_UI/Play/SVG/guitar_active.svg'
import level_IA from '../Assets/Bisoft_UI/Play/SVG/level_inactive.svg'
import level_AC from '../Assets/Bisoft_UI/Play/SVG/level_active.svg'
import eye_bg_Svg from '../Assets/Bisoft_UI/Play/SVG/eye property bg.svg'
import Eye_bg_Svg from '../Assets/Bisoft_UI/Play/SVG/Eye_bg.svg'
import Freq_Svg from '../Assets/Bisoft_UI/Play/SVG/frequency_slider.svg'
import Greenslider_Svg from '../Assets/Bisoft_UI/Play/SVG/green_slider.svg'
import sliderIA from '../Assets/Bisoft_UI/Play/SVG/inactiveslider.svg'
import MusicCard_Svg from '../Assets/Bisoft_UI/Play/SVG/music group button.svg'
import Pianosmall_Svg from '../Assets/Bisoft_UI/Play/SVG/piano small.svg'
import piano_4key from '../Assets/Bisoft_UI/Play/SVG/piano_4key.svg'
import Pianotogglebg_Svg from '../Assets/Bisoft_UI/Play/SVG/piano toggle bg.svg'
import PlayCard_Svg from '../Assets/Bisoft_UI/Play/SVG/play menu card - small.svg'
import popup_Svg from '../Assets/Bisoft_UI/Play/SVG/popup card.svg'
import Popup from '../Assets/Bisoft_UI/Play/SVG/popup.svg'
import Redslider_Svg from '../Assets/Bisoft_UI/Play/SVG/red_slider.svg'
import RemoteCard_Svg from '../Assets/Bisoft_UI/Play/SVG/remote group button.svg'
import SideToggle_bg from '../Assets/Bisoft_UI/Play/SVG/toggle_bg.svg'
import PC_ButtonActive from '../Assets/Bisoft_UI/Play/SVG/PC_ButtonActive.svg'
import PC_ButtonInActive from '../Assets/Bisoft_UI/Play/SVG/PC_ButtonInActive.svg'
import ArmButtonActive from '../Assets/Bisoft_UI/Play/SVG/ArmButtonActive.svg'
import ArmButtonInActive from '../Assets/Bisoft_UI/Play/SVG/ArmButtonInActive.svg'
import Drop from '../Assets/Bisoft_UI/Play/SVG/Drop.svg'
import Pick from '../Assets/Bisoft_UI/Play/SVG/Pick.svg'
import rightInActive from '../Assets/Bisoft_UI/Play/SVG/rightInActive.svg'
import rightActive from '../Assets/Bisoft_UI/Play/SVG/rightActive.svg'
import leftInActive from '../Assets/Bisoft_UI/Play/SVG/leftInActive.svg'
import leftActive from '../Assets/Bisoft_UI/Play/SVG/leftActive.svg'
import upInActive from '../Assets/Bisoft_UI/Play/SVG/upInActive.svg'
import downActive from '../Assets/Bisoft_UI/Play/SVG/downActive.svg'
import downInActive from '../Assets/Bisoft_UI/Play/SVG/downInActive.svg'
import upActive from '../Assets/Bisoft_UI/Play/SVG/upActive.svg'

//SPEECH Screen
import Soundwaves_Svg from '../Assets/Bisoft_UI/Play/SVG/sound waves.svg'
import SpeechAc_Svg from '../Assets/Bisoft_UI/Play/SVG/speech active.svg'
import SpeechCard_Svg from '../Assets/Bisoft_UI/Play/SVG/speech group button.svg'
import SpeechIA_Svg from '../Assets/Bisoft_UI/Play/SVG/speech inactive.svg'
import teethAc_Svg from '../Assets/Bisoft_UI/Play/SVG/teeth - active.svg'
import teethIA_Svg from '../Assets/Bisoft_UI/Play/SVG/teeth - inactive.svg'
import teeth_bg_Svg from '../Assets/Bisoft_UI/Play/SVG/teeth property bg.svg'
import bisoft_logo1 from '../Assets/Bisoft_UI/Plode/Plode Icon.png'
//gsk edit humanoid
import selectbar from '../Assets/images/select bar.png'
import helpbutton from '../Assets/images/help@2x.png'
import buletooth from '../Assets/images/bluetooth - on@2x.png'
import savebutton from '../Assets/images/save - secondary@2x.png'
import humanoid from '../Assets/images/ZingImage.png'
import inactiveBuzzer from '../Assets/images/buzzer - inactive-1.png'
import popupcard from '../Assets/internalAccessories/popupcard@2x.png'
import inactiveBattery from '../Assets/images/inactiveImg/battery.svg'
import inactiveUltrasonic from '../Assets/images/inactiveImg/ultrasonic.svg'
import inactiveAccelerometerX from '../Assets/images/inactiveImg/acceleX.svg'
import inactiveAccelerometerY from '../Assets/images/inactiveImg/acceleY.svg'
import inactiveAccelerometerZ from '../Assets/images/inactiveImg/acceleZ.svg'
import inactiveGyroX from '../Assets/images/inactiveImg/gyroX.svg'
import inactiveGyroY from '../Assets/images/inactiveImg/gyroY.svg'
import inactiveGyroZ from '../Assets/images/inactiveImg/gyroZ.svg'
import inactiveLeftRGB from '../Assets/images/inactiveImg/lefteye.svg'
import inactiveRightRGB from '../Assets/images/inactiveImg/righteye.svg'
import inactiveMp3 from '../Assets/images/inactiveImg/mp3.svg'
import inactiveIndividual from '../Assets/images/inactiveImg/individualServo.svg'
import inactiveHeadServo from '../Assets/images/inactiveImg/servo.svg'
import inactiveActionGroup from '../Assets/images/inactiveImg/actionServo.svg'
import backbutton from '../Assets/images/back@2x.png'
import nextbutton from '../Assets/images/next@2x.png'
import activeBattery from '../Assets/images/activeImg/battery.svg'
import activeUltrasonic from '../Assets/images/activeImg/ultrasonic.svg'
import activeAccelerometerX from '../Assets/images/activeImg/acceleX.svg'
import activeAccelerometerY from '../Assets/images/activeImg/acceleY.svg'
import activeAccelerometerZ from '../Assets/images/activeImg/acceleZ.svg'
import activeGyroX from '../Assets/images/activeImg/gyroX.svg'
import activeGyroY from '../Assets/images/activeImg/gyroY.svg'
import activeGyroZ from '../Assets/images/activeImg/gyroZ.svg'
import activeLeftRGB from '../Assets/images/activeImg/lefteye.svg'
import activeRightRBG from '../Assets/images/activeImg/righteye.svg'
import activeMp3 from '../Assets/images/activeImg/mp3.svg'
import activeIndividual from '../Assets/images/activeImg/individualservo.svg'
import activeHeadServo from '../Assets/images/activeImg/servo.svg'
import activeActionGroup from '../Assets/images/activeImg/actionServo.svg'
import inactiveEye from '../Assets/images/eye-inactive.png'
import activeEye from '../Assets/images/eye-active.png'
import activeBuzzer from '../Assets/images/buzzer - active.png'
import activeAdvanceSetting from '../Assets/images/advanced settings@2x.png'
import firmwareUpdate from '../Assets/images/firmwareUpdate.png'
import AccountSetting from '../Assets/images/AccountSetting.png'
import aboutApplication from '../Assets/images/aboutApplication.svg'
import operationModes from '../Assets/images/operationModes.svg'
import deleteAcBtn from '../Assets/images/DeleteAcBtn.png'
import settingImg from '../Assets/images/Group 5674@2x.png'
import M1 from '../Assets/images/M1.svg'
import M2 from '../Assets/images/M2.svg'
import simpleServo from '../Assets/images/simpleServo.svg'
import activeM1 from '../Assets/images/activeM1.svg'
import activeM2 from '../Assets/images/activeM2.svg'
import activesimpleServo from '../Assets/images/activeSimpleServo.svg'
// guitar tone
import A0 from '../Assets/audio/guitar/A0.mp3'
import A1 from '../Assets/audio/guitar/A1.mp3'
import A2 from '../Assets/audio/guitar/A2.mp3'
import A3 from '../Assets/audio/guitar/A3.mp3'
import A4 from '../Assets/audio/guitar/A4.mp3'
import A5 from '../Assets/audio/guitar/A5.mp3'
import A6 from '../Assets/audio/guitar/A5.mp3'
import A7 from '../Assets/audio/guitar/A5.mp3'
import E1 from '../Assets/audio/guitar/E1.mp3'
import E2 from '../Assets/audio/guitar/E2.mp3'
import E3 from '../Assets/audio/guitar/E3.mp3'
import E4 from '../Assets/audio/guitar/E4.mp3'
import E5 from '../Assets/audio/guitar/E5.mp3'
import E6 from '../Assets/audio/guitar/E5.mp3'
import E7 from '../Assets/audio/guitar/E5.mp3'
//paino for pc1.0
import key1 from '../Assets/audio/piano/a.wav'
import key2 from '../Assets/audio/piano/b.wav'
import key3 from '../Assets/audio/piano/c.wav'
import key4 from '../Assets/audio/piano/d.wav'
import key5 from '../Assets/audio/piano/e.wav'
import key6 from '../Assets/audio/piano/f.wav'
import key7 from '../Assets/audio/piano/g.wav'
import key8 from '../Assets/audio/piano/h.wav'
import key9 from '../Assets/audio/piano/i.wav'
import key10 from '../Assets/audio/piano/j.wav'
import key11 from '../Assets/audio/piano/k.wav'
import key12 from '../Assets/audio/piano/l.wav'
import key13 from '../Assets/audio/piano/m.wav'
import key14 from '../Assets/audio/piano/n.wav'
import key15 from '../Assets/audio/piano/p.wav'
import key16 from '../Assets/audio/piano/q.wav'
import key17 from '../Assets/audio/piano/r.wav'
import key18 from '../Assets/audio/piano/s.wav'
import key19 from '../Assets/audio/piano/t.wav'
//gsk end
// zingPlayHelpScrren
import speechH from '../Assets/Bisoft_UI/Help screen/ZingPlay/speechH.png'
import speechH1 from '../Assets/Bisoft_UI/Help screen/ZingPlay/speechH1.png'
import remoteH from '../Assets/Bisoft_UI/Help screen/ZingPlay/remoteH.png'
import remoteH1 from '../Assets/Bisoft_UI/Help screen/ZingPlay/remoteH1.png'
import remoteH2 from '../Assets/Bisoft_UI/Help screen/ZingPlay/remoteH2.png'
import cameraH from '../Assets/Bisoft_UI/Help screen/ZingPlay/cameraH.png'
import cameraH1 from '../Assets/Bisoft_UI/Help screen/ZingPlay/cameraH1.png'
import ZingConnect from '../Assets/Bisoft_UI/Help screen/Main/ZingConect.png'

// PcV1.0 PLay_HelpScreen
import PlayH1 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH1.png'
import PlayH2 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH2.png'
import PlayH3 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH3.png'
import PlayH4 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH4.png'
import PlayH5 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH5.png'
import PlayH6 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH6.png'
import PlayH7 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH7.png'
import PlayH8 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH8.png'
import PlayH9 from '../Assets/Bisoft_UI/Help screen/Play1.0/PlayH9.png'
import CameraH1 from '../Assets/Bisoft_UI/Help screen/Play1.0/CameraH1.png'
import CameraH2 from '../Assets/Bisoft_UI/Help screen/Play1.0/CameraH2.png'
import CameraH3 from '../Assets/Bisoft_UI/Help screen/cameraScreen/cameraPcv1.0.png'
import MusicH1 from '../Assets/Bisoft_UI/Help screen/Play1.0/MusicH1.png'
import MusicH2 from '../Assets/Bisoft_UI/Help screen/Play1.0/MusicH2.png'
import MusicH3 from '../Assets/Bisoft_UI/Help screen/Play1.0/MusicH3.png'
import MusicH4 from '../Assets/Bisoft_UI/Help screen/Play1.0/MusicH4.png'
import SpeechH1 from '../Assets/Bisoft_UI/Help screen/Play1.0/SpeechH1.png'
import advanceSlider from '../Assets/Bisoft_UI/Help screen/Play/AdanceSet.png'

// Selection Screen
import SelectionPlay from '../Assets/Bisoft_UI/Help screen/selectionScreen/play.png'
import SelectionLearn from '../Assets/Bisoft_UI/Help screen/selectionScreen/learn.png'
import SelectionCode from '../Assets/Bisoft_UI/Help screen/selectionScreen/code.png'
import SelectionDataGraph from '../Assets/Bisoft_UI/Help screen/selectionScreen/dataGraph.png'
import SelectionAppMode from '../Assets/Bisoft_UI/Help screen/selectionScreen/appMode.png'

//Roboki imges
import Roboki from '../Assets/Bisoft_UI/Main/PNG/Roboki.png'

//kitSelection
import peeCeeB from '../Assets/kitSelection/PeeCeeB@2x.png'
import peeCeeA from '../Assets/kitSelection/Group 6136@2x.png'
import crawle from '../Assets/kitSelection/Crawle@2x.png'
import roboki from '../Assets/kitSelection/Roboki@2x.png'
import klaw from '../Assets/kitSelection/klaw@2x.png'
import trons from '../Assets/kitSelection/Play Dashes_1@2x.png'
import tronix from '../Assets/kitSelection/Play Dots@2x.png'
import zing from '../Assets/kitSelection/humanoid@2x.png'
import arrowRight from '../Assets/kitSelection/Path@2x.png'
import searchIcon from '../Assets/kitSelection/search.png'
import auto from '../Assets/kitSelection/autoBtn@2x.png'
import doubleArrow from '../Assets/kitSelection/right.png'
import kitSelectionDynamex from '../Assets/kitSelection/kitSelectionDynamex@2x.png'
import plodeX from '../Assets/kitSelection/plodeX@2x.png'
//Klaw images
import Klaw from '../Assets/klaw/toy-robotic-arm@2x.png'
import Klaw_3x from '../Assets/klaw/toy-robotic-arm@3x.png'
import NewKlaw_3x from '../Assets/klaw/RoboticArmImage@3x.png'
import switch1InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/switch1.png'
import switch2InActive from '../Assets/Bisoft_UI/Buttons/Inactive with shadow/switch2.png'
import switch1Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/switch1.png'
import switch2Active from '../Assets/Bisoft_UI/Buttons/Active with shadow/switch2.png'

import inactivePot1 from '../Assets/klaw/inactiveButton/pot1.svg'
import inactivePot2 from '../Assets/klaw/inactiveButton/pot2.svg'
import inactivePot3 from '../Assets/klaw/inactiveButton/pot3.svg'
import inactivePot4 from '../Assets/klaw/inactiveButton/pot4.svg'
import inactivePot5 from '../Assets/klaw/inactiveButton/pot5.svg'
import inactivePot6 from '../Assets/klaw/inactiveButton/pot6.svg'

import activePot1 from '../Assets/klaw/activeButton/pot1.svg'
import activePot2 from '../Assets/klaw/activeButton/pot2.svg'
import activePot3 from '../Assets/klaw/activeButton/pot3.svg'
import activePot4 from '../Assets/klaw/activeButton/pot4.svg'
import activePot5 from '../Assets/klaw/activeButton/pot5.svg'
import activePot6 from '../Assets/klaw/activeButton/pot6.svg'

// import inactiveJoystick1 from '../Assets/klaw/joystick1@2x.png'
// import inactiveJoystick2 from '../Assets/klaw/joystick2@2x.png'
// import inactiveJoystick3 from '../Assets/klaw/joystick3@2x.png'
// import inactiveSwitch1 from '../Assets/klaw/switch1@2x.png'
// import inactiveSwitch2 from '../Assets/klaw/switch2@2x.png'
// import inactiveSwitch3 from '../Assets/klaw/switch3@2x.png'

// import activeJoystick1 from '../Assets/klaw/joystick1Active@2x.png'
// import activeJoystick2 from '../Assets/klaw/joystick2Active@2x.png'
// import activeJoystick3 from '../Assets/klaw/joystick3Active@2x.png'
// import activeSwitch1 from '../Assets/klaw/switch1Active@2x.png'
// import activeSwitch2 from '../Assets/klaw/switch2Active@2x.png'
// import activeSwitch3 from '../Assets/klaw/switchActive3@2x.png'

//toki images
import toki from '../Assets/kitSelection/tokiKitSelection@2x.png'

function renderImage(imageName) {
    switch (imageName) {
        case 'bisoft_logo':
            return bisoft_logo
        case 'bisoft_logo1':
            return bisoft_logo1
        case 'leadingPageSvg':
            return leadingPageSvg
        case 'plodeIconSvg':
            return plodeIconSvg
        case 'settingImg':
            return settingImg
        case 'add':
            return add
        case 'add3x':
            return add3x
        case 'remove3x':
            return remove3x
        case 'add3xIA':
            return add3xIA
        case 'remove3xIA':
            return remove3xIA
        case 'Arm':
            return RoboticArm
        case 'PC':
            return PC
        case 'newPc':
            return newPc
        case 'PCv1_3x':
            return PCv1_3x
        case 'PCv1':
            return PCv1
        case 'Zing':
            return Zing
        case 'Pc':
            return Pc
        case 'leading_page':
            return leading_page
        case 'reconnect':
            return reconnect
        case 'reconnectSvg':
            return reconnectSvg
        case 'devices':
            return devices
        case 'Computer':
            return Computer
        case 'PC_New':
            return PC_New
        case 'skip':
            return skip
        case 'popup_Svg':
            return popup_Svg
        case 'backBtn':
            return backbtn
        case 'rightArrow':
            return rightArrow
        case 'leftArrow':
            return leftArrow
        case 'PlayCard_Svg':
            return PlayCard_Svg
        case 'helpBtnInActive':
            return helpBtnInActive
        case 'clos':
            return clos
        case 'RemoteCard_Svg':
            return RemoteCard_Svg
        case 'MusicCard_Svg':
            return MusicCard_Svg
        case 'SpeechCard_Svg':
            return SpeechCard_Svg
        case 'Camera_Svg':
            return Camera_Svg
        case 'UsbOn':
            return UsbOn
        case 'UsbOff':
            return UsbOff
        case 'Soundwaves_Svg':
            return Soundwaves_Svg
        case 'SpeechIA_Svg':
            return SpeechIA_Svg
        case 'SpeechAc_Svg':
            return SpeechAc_Svg
        case 'PcpianoIA':
            return PcpianoIA
        case 'PianokeysIA':
            return PianokeysIA
        case 'PcpianoAc':
            return PcpianoAc
        case 'PianokeysAc':
            return PianokeysAc
        case 'Pianosmall_Svg':
            return Pianosmall_Svg
        case 'piano_4key':
            return piano_4key

        case 'Disconnected_Svg':
            return Disconnected_Svg
        case 'pianoActiveBtn':
            return pianoActiveBtn
        case 'Pianotogglebg_Svg':
            return Pianotogglebg_Svg
        case 'dice_IA':
            return dice_IA
        case 'dice_AC':
            return dice_AC
        case 'level_IA':
            return level_IA
        case 'level_AC':
            return level_AC
        case 'downActive':
            return downActive
        case 'upInActive':
            return upInActive
        case 'upActive':
            return upActive
        case 'downInActive':
            return downInActive
        case 'rightActive':
            return rightActive
        case 'rightInActive':
            return rightInActive
        case 'leftActive':
            return leftActive
        case 'leftInActive':
            return leftInActive
        case 'drop':
            return Drop
        case 'pick':
            return Pick
        case 'guitar_IA':
            return guitar_IA
        case 'guitar_AC':
            return guitar_AC
        case 'simon_IA':
            return simon_IA
        case 'simon_AC':
            return simon_AC
        case 'eyeAc_Svg':
            return eyeAc_Svg
        case 'eyeIA_Svg':
            return eyeIA_Svg
        case 'buzzerIA_Svg':
            return buzzerIA_Svg
        case 'buzzerAc_Svg':
            return buzzerAc_Svg
        case 'sideToggle_bg':
            return SideToggle_bg
        case 'PC_ButtonActive':
            return PC_ButtonActive
        case 'PC_ButtonInActive':
            return PC_ButtonInActive
        case 'ArmButtonActive':
            return ArmButtonActive
        case 'ArmButtonInActive':
            return ArmButtonInActive
        case 'Eye_bg_Svg':
            return Eye_bg_Svg
        case 'eye_bg_Svg':
            return eye_bg_Svg
        case 'buzzer_bg_Svg':
            return buzzer_bg_Svg
        case 'teeth_bg_Svg':
            return teeth_bg_Svg
        case 'teethIA_Svg':
            return teethIA_Svg
        case 'teethAc_Svg':
            return teethAc_Svg
        case 'FourteethIA_Svg':
            return FourteethIA_Svg
        case 'FourteethAc_Svg':
            return FourteethAc_Svg

        case 'red_slider':
            var type = Redslider_Svg
            return type
        case 'green_slider':
            var type = Greenslider_Svg
            return type
        case 'blue_slider':
            var type = Blueslider_Svg
            return type
        case 'inactiveslider':
            return sliderIA
        case 'frequency_slider':
            return Freq_Svg
        case 'smile1_Active':
            return smile1_Active
        case 'smile2_Active':
            return smile2_Active
        case 'smile3_Active':
            return smile3_Active
        case 'smile4_Active':
            return smile4_Active
        case 'smile1':
            return smile1
        case 'smile2':
            return smile2
        case 'smile3':
            return smile3
        case 'smile4':
            return smile4
        case 'talk_IA':
            return talk_IA
        case 'talk_Ac':
            return talk_Ac
        case 'disco_IA':
            return disco_IA
        case 'disco_Ac':
            return disco_Ac
        case 'gesture_IA':
            return gesture_IA
        case 'gesture_Ac':
            return gesture_Ac
        case 'Bild':
            return Bild
        case 'Ply':
            return Ply
        case 'Prog':
            return Prog
        case 'Remt':
            return Remt
        case 'Peech':
            return Peech
        case 'Msic':
            return Msic
        case 'Camr':
            return Camr
        case 'AddInfo':
            return AddInfo
        case 'PlyComp':
            return PlyComp
        case 'Connect':
            return Connect
        case 'Cam1':
            return Cam1
        case 'Cam2':
            return Cam2
        case 'Spch1':
            return Spch1
        case 'Spch2':
            return Spch2
        case 'Musc1':
            return Musc1
        case 'Musc2':
            return Musc2
        case 'Musc3':
            return Musc3
        case 'Musc4':
            return Musc4
        case 'Remt1':
            return Remt1
        case 'Remt2':
            return Remt2
        case 'Remt3':
            return Remt3
        case 'Remt4':
            return Remt4
        case 'Remt5':
            return Remt5
        case 'Remt6':
            return Remt6
        case 'PCimg':
            return PCimg
        case 'AudioDb':
            return AudioDb
        case 'AudioA':
            return AudioA
        case 'AudioC':
            return AudioC
        case 'AudioE':
            return AudioE
        case 'AudioB':
            return AudioB
        case 'AudioD':
            return AudioD
        case 'AudioF':
            return AudioF
        case 'AudioG':
            return AudioG
        case 'Popup':
            return Popup
        case 'Popup':
            return Popup
        case 'selectbar':
            return selectbar
        case 'helpbutton':
            return helpbutton
        case 'buletooth':
            return buletooth
        case 'savebutton':
            return savebutton
        case 'humanoid':
            return humanoid
        case 'inactiveBuzzer':
            return inactiveBuzzer
        case 'popupcard':
            return popupcard
        case 'inactiveBattery':
            return inactiveBattery
        case 'inactiveUltrasonic':
            return inactiveUltrasonic
        case 'inactiveAccelerometerX':
            return inactiveAccelerometerX
        case 'inactiveAccelerometerY':
            return inactiveAccelerometerY
        case 'inactiveAccelerometerZ':
            return inactiveAccelerometerZ
        case 'inactiveGyroX':
            return inactiveGyroX
        case 'inactiveGyroY':
            return inactiveGyroY
        case 'inactiveGyroZ':
            return inactiveGyroZ
        case 'inactiveLeftRGB':
            return inactiveLeftRGB
        case 'inactiveRightRGB':
            return inactiveRightRGB
        case 'inactiveMp3':
            return inactiveMp3
        case 'inactiveIndividual':
            return inactiveIndividual
        case 'inactiveHeadServo':
            return inactiveHeadServo
        case 'inactiveActionGroup':
            return inactiveActionGroup
        case 'backbutton':
            return backbutton
        case 'nextbutton':
            return nextbutton
        case 'activeBattery':
            return activeBattery
        case 'activeUltrasonic':
            return activeUltrasonic
        case 'activeAccelerometerX':
            return activeAccelerometerX
        case 'activeAccelerometerY':
            return activeAccelerometerY
        case 'activeAccelerometerZ':
            return activeAccelerometerZ
        case 'activeGyroX':
            return activeGyroX
        case 'activeGyroY':
            return activeGyroY
        case 'activeGyroZ':
            return activeGyroZ
        case 'activeLeftRGB':
            return activeLeftRGB
        case 'activeRightRBG':
            return activeRightRBG
        case 'activeMp3':
            return activeMp3
        case 'activeIndividual':
            return activeIndividual
        case 'activeHeadServo':
            return activeHeadServo
        case 'activeActionGroup':
            return activeActionGroup
        case 'inactiveEye':
            return inactiveEye
        case 'activeEye':
            return activeEye
        case 'activeBuzzer':
            return activeBuzzer
        case 'activeAdvanceSetting':
            return activeAdvanceSetting
        case 'M1':
            return M1
        case 'M2':
            return M2
        case 'simpleServo':
            return simpleServo
        case 'activeM1':
            return activeM1
        case 'activeM2':
            return activeM2
        case 'activesimpleServo':
            return activesimpleServo
        case 'firmwareUpdate':
            return firmwareUpdate
        case 'AccountSetting':
            return AccountSetting
        case 'aboutApplication':
            return aboutApplication
        case 'operationModes':
            return operationModes
        case 'deleteAcBtn':
            return deleteAcBtn
        case 'A0':
            return A0
        case 'A7':
            return A1
        case 'AM':
            return A2
        case 'B7':
            return A3
        case 'C0':
            return A4
        case 'C7':
            return A5
        case 'D0':
            return A6
        case 'D7':
            return A7
        case 'DM':
            return E1
        case 'E0':
            return E2
        case 'E7':
            return E3
        case 'EM':
            return E3
        case 'G0':
            return E4
        case 'G7':
            return E5
        case 'K0001':
            return key1
        case 'K0010':
            return key2
        case 'K0011':
            return key3
        case 'K0100':
            return key4
        case 'K0101':
            return key5
        case 'K0110':
            return key6
        case 'K0111':
            return key7
        case 'K1000':
            return key8
        case 'K1001':
            return key9
        case 'K1010':
            return key10
        case 'K1011':
            return key11
        case 'K1100':
            return key12
        case 'K1101':
            return key13
        case 'K1110':
            return key14
        case 'K1111':
            return key15
        case 'remoteH':
            return remoteH
        case 'remoteH1':
            return remoteH1
        case 'remoteH2':
            return remoteH2
        case 'speechH1':
            return speechH1
        case 'speechH':
            return speechH
        case 'cameraH1':
            return cameraH1
        case 'cameraH':
            return cameraH
        case 'ZingConnect':
            return ZingConnect
        case 'PlayH1':
            return PlayH1
        case 'PlayH2':
            return PlayH2
        case 'PlayH3':
            return PlayH3
        case 'PlayH4':
            return PlayH4
        case 'PlayH5':
            return PlayH5
        case 'PlayH6':
            return PlayH6
        case 'PlayH7':
            return PlayH7
        case 'PlayH8':
            return PlayH8
        case 'PlayH9':
            return PlayH9
        case 'MusicH1':
            return MusicH1
        case 'MusicH2':
            return MusicH2
        case 'MusicH3':
            return MusicH3
        case 'MusicH4':
            return MusicH4
        case 'CameraH1':
            return CameraH1
        case 'CameraH2':
            return CameraH2
        case 'CameraH3':
            return CameraH3
        case 'SpeechH1':
            return SpeechH1
        case 'advanceSlider':
            return advanceSlider
        case 'SelectionPlay':
            return SelectionPlay
        case 'SelectionCode':
            return SelectionCode
        case 'SelectionLearn':
            return SelectionLearn
        case 'SelectionDataGraph':
            return SelectionDataGraph
        case 'SelectionAppMode':
            return SelectionAppMode

        case 'Roboki':
            return Roboki
        case 'Hexapod':
            return Hexapod
        case 'hexapod':
            return hexapod

        case 'peeCeeA':
            return peeCeeA
        case 'peeCeeB':
            return peeCeeB
        case 'klaw':
            return klaw
        case 'crawle':
            return crawle
        case 'searchIcon':
            return searchIcon
        case 'doubleArrow':
            return doubleArrow
        case 'roboki':
            return roboki
        case 'trons':
            return trons
        case 'tronix':
            return tronix
        case 'kitSelectionDynamex':
            return kitSelectionDynamex
        case 'plodeX':
            return plodeX
        case 'zing':
            return zing
        case 'arrowRight':
            return arrowRight
        case 'auto':
            return auto
        case 'Klaw':
            return Klaw
        case 'Klaw_3x':
            return Klaw_3x
        case 'NewKlaw_3x':
            return NewKlaw_3x
        case 'switch1InActive':
            return switch1InActive
        case 'switch2InActive':
            return switch2InActive
        case 'switch1Active':
            return switch1Active
        case 'switch2Active':
            return switch2Active

        case 'activePot1':
            return activePot1
        case 'activePot2':
            return activePot2
        case 'activePot3':
            return activePot3
        case 'activePot4':
            return activePot4
        case 'activePot5':
            return activePot5
        case 'activePot6':
            return activePot6

        case 'inactivePot1':
            return inactivePot1
        case 'inactivePot2':
            return inactivePot2
        case 'inactivePot3':
            return inactivePot3
        case 'inactivePot4':
            return inactivePot4
        case 'inactivePot5':
            return inactivePot5
        case 'inactivePot6':
            return inactivePot6
        case 'yuduRobotics':
            return yuduRobotics

        // case 'activeJoystick1':
        //     return activeJoystick1
        // case 'inactiveJoystick1':
        //     return inactiveJoystick1
        // case 'activeSwitch1':
        //     return activeSwitch1
        // case 'inactiveSwitch1':
        //     return inactiveSwitch1
        // case 'activeJoystick2':
        //     return activeJoystick2
        // case 'inactiveJoystick2':
        //     return inactiveJoystick2
        // case 'activeSwitch2':
        //     return activeSwitch2
        // case 'inactiveSwitch2':
        //     return inactiveSwitch2
        // case 'activeJoystick3':
        //     return activeJoystick3
        // case 'inactiveJoystick3':
        //     return inactiveJoystick3
        // case 'activeSwitch3':
        //     return activeSwitch3
        // case 'inactiveSwitch3':
        //     return inactiveSwitch3

        //toki
        case 'toki':
            return toki
    }
}

export default renderImage
