import {
    decimalToMSBLSB,
    generateDeviceDataKey,
} from '../../ReusableComponents/ByteTransfer/utils'
import { DEFAULTSERVOVALUES } from './defaultData'

// const crc_create = (buf) => {
//     let crc = 0x00

//     for (let i = 0; i < buf.length; i++) {
//         // console.log('GGGGGG', buf[i])
//         crc ^= buf[i] // XOR the CRC with the current byte

//         // Process each bit in the current byte
//         for (let j = 0; j < 8; j++) {
//             if (crc & 0x80) {
//                 // If the MSB is set, XOR with the polynomial 0x07
//                 crc = (crc << 1) ^ 0x07
//             } else {
//                 // Otherwise, just shift the CRC left
//                 crc = crc << 1
//             }

//             // Ensure CRC remains an 8-bit value
//             crc &= 0xff
//         }
//     }
//     // console.log('VVVVVVVVV', crc)
//     return crc
// }

// generate the  CRC(Cyclic Redundancy Check)

const crc_create = (buf) => {
    let crc
    let temp = 0
    for (let i = 0; i < buf.length; i++) {
        // console.log('GGGGGG', buf[i])
        temp += buf[i]
    }
    temp = temp & 0xff
    crc = ~temp
    return crc & 0xff
}

const fileSymlinkBytesGeneration = ({ byteType, ele, ind }) => {
    try {
        let bytes = [0x0f, 0xff]
        let crc = ''
        console.log('MMMMMMMM', byteType, ele)
        switch (byteType) {
            case 'openCreateFileByte': {
                let length = ele.length + 2
                bytes.push(length, 0xf0)

                let pathAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...pathAsci]

                crc = crc_create([length, 0xf0, ...pathAsci])

                break
            }
            case 'deleteFileByte': {
                let length = ele.length + 2
                bytes.push(length, 0xfd)

                let pathAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...pathAsci]

                crc = crc_create([length, 0xfd, ...pathAsci])
                break
            }
            case 'writeFileByte': {
                // ele = parseInt(ele)
                // bytes.push(3, 0xc2, ele)
                // crc = crc_create([3, 0xc2, ele])
                break
            }
            case 'closeFileByte': {
                bytes.push(2, 0xfc)
                crc = crc_create([2, 0xfc])
                break
            }
            case 'checkFileExistByte': {
                let length = ele.length + 2

                bytes.push(length, '?'.charCodeAt(0))
                let pathAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...pathAsci]

                crc = crc_create([length, '?'.charCodeAt(0), ...pathAsci])
                break
            }
            case 'checkDirectoryExistByte': {
                console.log('MMMMMMMM')
                let length = ele.length + 2
                bytes.push(length, 0xd1)

                let pathAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...pathAsci]

                crc = crc_create([length, 0xd1, ...pathAsci])
                break
            }
            case 'createDirectoryByte': {
                let length = ele.length + 2
                bytes.push(length, 0xcd)

                let pathAsci = ele.split('').map((item) => item.charCodeAt(0))
                bytes = [...bytes, ...pathAsci]

                crc = crc_create([length, 0xcd, ...pathAsci])
                break
            }
        }

        bytes.push(crc, 0xf9)
        console.log('bytes', bytes)
        return bytes
    } catch (e) {
        console.log('ERROR', e)
        return [
            'R'.charCodeAt(0),
            'E'.charCodeAt(0),
            'S'.charCodeAt(0),
            'T'.charCodeAt(0),
        ]
    }
}

const writeFileSteps = {
    checkDirectoryExistByte: {
        byteType: 'checkDirectoryExistByte',
        value: '/sdcard/zing/actions/user/',
        expectedResponse: ['FOK', 'FER'],
    },
    createDirectoryByte: {
        byteType: 'createDirectoryByte',
        value: '/sdcard/zing/actions/user',
        expectedResponse: ['FOK', 'FER'],
    },
    checkFileExistByte: {
        byteType: 'checkFileExistByte',
        value: '/sdcard/zing/actions/user/',
        expectedResponse: ['FOK', 'FER'],
    },

    openCreateFileByte: {
        byteType: 'openCreateFileByte',
        value: '/sdcard/zing/actions/user/',
        expectedResponse: ['FOK', 'FER'],
    },
    closeFileByte: {
        byteType: 'closeFileByte',
        value: null,
        expectedResponse: ['FOK', 'FER'],
    },
}

const fileWriteByteGeneration = ({
    actionsData,
    mode,
    connectedDevice,
    deviceVersion,
}) => {
    try {
        const actionGroupBytes = []
        const rawData = []
        let waitTime = 2000
        const servoDataKey = generateDeviceDataKey(
            connectedDevice,
            deviceVersion
        )

        const actionArr = Array.from(
            { length: connectedDevice === 'Hexapod' ? 18 : 16 },
            (_, i) => i + 1
        )
        if (connectedDevice === 'Humanoid') {
            actionArr.push('H')
        }
        actionArr.splice(0, 0, 'T')

        let dataLength = 2
        actionGroupBytes.push('W'.charCodeAt(0))
        rawData.push('W'.charCodeAt(0))

        if (mode == 'ALLACTIONS') {
            let MSBLSBRowLength = decimalToMSBLSB(actionsData.allActions.length)
            actionGroupBytes.push(MSBLSBRowLength[0], MSBLSBRowLength[1])
            rawData.push(actionsData.allActions.length)
            // dataLength += 1

            actionsData.allActions.map((action) => {
                //calculate the total time require to complete the all action
                waitTime += action.data['T'].value

                // Filling each servo value for particular action
                actionArr.map((key) => {
                    let MSBLSB
                    if (key !== 'H') {
                        if (action.data[key].isEnabled) {
                            MSBLSB = decimalToMSBLSB(action.data[key].value)
                            rawData.push(action.data[key].value)
                        } else {
                            MSBLSB = decimalToMSBLSB(
                                DEFAULTSERVOVALUES[
                                    servoDataKey
                                    // `${connectedDevice.toUpperCase()}`
                                ][key]
                            )
                            rawData.push(
                                DEFAULTSERVOVALUES[
                                    servoDataKey
                                    // `${connectedDevice.toUpperCase()}`
                                ][key]
                            )
                        }
                        actionGroupBytes.push(MSBLSB[0], MSBLSB[1])
                        dataLength += 2
                    } else {
                        if (action.data[key].isEnabled) {
                            actionGroupBytes.push(action.data[key].value)
                            rawData.push(action.data[key].value)
                        } else {
                            actionGroupBytes.push(90)
                            rawData.push(90)
                        }

                        dataLength += 1
                    }
                })
            })

            let crc = crc_create([dataLength, ...actionGroupBytes])

            //add header and length
            actionGroupBytes.unshift(0x0f, 0xff, dataLength)
            rawData.unshift(0x0f, 0xff, dataLength)

            actionGroupBytes.push(crc, 0xf9)
            rawData.push(crc, 0xf9)

            console.log('rawData', rawData)

            return actionGroupBytes
        }
    } catch (e) {
        console.error(e)
        return []
    }
}

export { fileSymlinkBytesGeneration, writeFileSteps, fileWriteByteGeneration }
