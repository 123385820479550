import { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import renderImage from '../../../source/importImg'
import style from '../../ReusableComponents/DialogModal/DialogModal.module.css'
import { readBytes, sendBytes } from '../../ReusableComponents/ByteTransfer'
import { actionsToBytes } from './ActionsCreate'
import { saveAs } from 'file-saver'
import ActionLoader from './ActionLoader'
import { width } from '@material-ui/system'
import { height } from '../../../helpers/Sizes'
import {
    fileSymlinkBytesGeneration,
    fileWriteByteGeneration,
    writeFileSteps,
} from './fileSymlinkBytes'

let status = true
const INITIAL_FEEDBACK = {
    isOn: false,
    msg: '',
}
const SavePopUp = (props) => {
    const [fileName, setFileName] = useState('')
    const [feedback, setFeedback] = useState(INITIAL_FEEDBACK)
    const [showActionLoader, setShowActionLoader] = useState(false)
    let connectedDevice = sessionStorage.getItem('connectedDevice')
    let deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'

    const sendDataAsPacket = useRef({ byteSend: false, responseMessage: '' })

    useEffect(() => {
        if (props.filePopup.isShow === false) {
            setFileName('')
        }
    }, [props.filePopup.isShow])

    let inputHandler = (event) => {
        setFileName(`${event.target.value}`.toUpperCase())
    }

    //check the user input file name is validation
    const isValidFName = (fName) => {
        const validNamePattern = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/
        return validNamePattern.test(fName)
    }

    const saveFile = () => {
        sessionStorage.setItem('isSave', JSON.stringify(true))
        if (!isValidFName(fileName)) {
            showSaveFeedBack('This is not a valid file name.', false)
            return
        }
        //in the board the file name name accept less than or equal to 10
        if (fileName.length > 10) {
            showSaveFeedBack('File name content maximum 10 character.', false)
            return
        }

        props.setFilePopUp({ isShow: false, isPlay: false })
        const fileData = JSON.stringify(sessionStorage)
        const blob = new Blob([fileData], { type: 'text/plain' })
        if (fileName.length !== 0) saveAs(blob, `${fileName}.pld`)
    }

    //save to board
    const uploadToBoard = async () => {
        if (!isValidFName(fileName)) {
            showSaveFeedBack('This is not a valid file name.', false)
            return
        }
        //in the board the file name name accept less than or equal to 8
        if (fileName.length > 10) {
            showSaveFeedBack('File name content maximum 8 character.', false)
            return
        }
        setShowActionLoader(true)
        const actionsData = JSON.parse(
            sessionStorage.getItem('createActionState')
        )
        let file = ['{'.charCodeAt(0)]
        file = file.concat(
            `${fileName + '.BIN'}`
                .split('')
                .map((val) => val.charCodeAt(0))
                .concat(['}'.charCodeAt(0)])
        )
        let replyOK
        if (['1', '2'].includes(deviceVersion[0])) {
            //for zing v2 board create save action bytes and sen to the board
            const { data, waitTime } = actionsToBytes(
                actionsData,
                1,
                'ALLACTIONS',
                '',
                fileName
            )
            props.worker.postMessage({
                type: 'writeArray',
                value: data,
            })
            // sessionStorage.setItem('sendDataAsPacket', 'true')
            sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
            try {
                let replyOK = await Promise.race([
                    checkSave(),
                    timeoutPromise(waitTime),
                ])
                setShowActionLoader(false)
                showSaveFeedBack('Action saved successfully', true)
            } catch (e) {
                setShowActionLoader(false)
                showSaveFeedBack('Action does not save', false)
            }
        }
        // else if (deviceVersion?.startsWith('1')) {
        //     const data = actionsToBytes(
        //         actionsData,
        //         1,
        //         'ALLACTIONS',
        //         '',
        //         fileName
        //     )
        //     try {
        //         await sendBytes(data, props.webSerial.port)
        //         let replySAVED = await Promise.race([
        //             readBytes(props.webSerial.port, 'GRSV'),
        //             timeoutPromise(5000),
        //         ])
        //         if (replySAVED) {
        //             showSaveFeedBack('Action saved successfully', true)
        //         } else {
        //             showSaveFeedBack('Action does not save', false)
        //         }
        //     } catch (e) {
        //         showSaveFeedBack('Action does not save', false)
        //     }
        // }
        else {
            await sendBytes(file, props.webSerial.port)
            replyOK = await readBytes(props.webSerial.port, 'OK')
            console.log(replyOK)
            if (replyOK) {
                const { data, waitTime } = actionsToBytes(
                    actionsData,
                    1,
                    'ALLACTIONS'
                )

                // if (
                //     ['1', '2'].includes(
                //         (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
                //     ) &&
                //     ['Humanoid'].includes(
                //         sessionStorage.getItem('connectedDevice')
                //     )
                // ) {
                //     sendDataAsPackets_zingV2(data, 43, props.webSerial.port)
                // } else {
                sendSaveDataAsPackets(data, 43, props.webSerial.port)
                // }
            }
        }
    }

    //after the Save came from zing show a feedBack message and remove the save popup
    const showSaveFeedBack = (msg, isClose) => {
        setFeedback({
            isOn: true,
            msg,
        })
        setTimeout(() => {
            setFeedback({
                isOn: false,
                msg: null,
            })
            if (isClose) {
                props.setFilePopUp({ isShow: false, isPlay: false })
            }
        }, 2000)
    }

    // and also sends them at the given interval
    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log('HHHHHHHHHH Time Out')
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: '',
                }
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }

    //check save is come or not
    const checkSave = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                // if (!JSON.parse(sessionStorage.getItem('sendDataAsPacket'))) {
                //     return resolve()
                // }
                if (!sendDataAsPacket.current.byteSend) {
                    return resolve(sendDataAsPacket.current.responseMessage)
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }

    //---------------------- write file in board start ------------------------------------------

    const sendPlayBytes = () => {
        const bytes = [
            'P'.charAt(0),
            'S'.charAt(0),
            'A'.charAt(0),
            fileName.length,
        ]
        let fileNameAsci = fileName.split('').map((item) => item.charCodeAt(0))
        bytes = [...bytes, ...fileNameAsci, 'E'.charAt(0), 'R'.charAt(0)]

        props.worker.postMessage({
            type: 'WriteArrayFormat1310',
            value: bytes,
        })
    }

    const sendByte = ({ data }) => {
        const bytes = fileSymlinkBytesGeneration({
            byteType: data.byteType,
            ele: data.value,
        })
        props.worker.postMessage({
            type: 'WriteArrayFormat1310',
            value: bytes,
        })
    }

    const fileWrite = async () => {
        try {
            //check directory
            sendByte({ data: writeFileSteps.checkDirectoryExistByte })
            sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
            let replyOK = await Promise.race([
                checkSave(),
                timeoutPromise(5000), //
            ])
            if (replyOK !== 'FOK') {
                alert('file does not write.')
                return
            }

            //check file
            const checkFileObj = {
                ...writeFileSteps.checkFileExistByte,
                value:
                    writeFileSteps.checkFileExistByte.value + fileName + '.bin',
            }
            sendByte({ data: checkFileObj })
            sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
            let replyOKFile = await Promise.race([
                checkSave(),
                timeoutPromise(5000), //
            ])

            if (replyOKFile === 'FOK') {
                let isConfirm = window.confirm(
                    'Do you want to overWrite the file?'
                )
                if (!isConfirm) {
                    return
                }
            }
            // else if (replyOKFile !== 'FER') {
            //     alert('file does not write.')
            //     return
            // }

            //open  file
            const openFileObj = {
                ...writeFileSteps.openCreateFileByte,
                value:
                    writeFileSteps.openCreateFileByte.value + fileName + '.bin',
            }
            sendByte({ data: openFileObj })
            sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
            let replyOKOpenFile = await Promise.race([
                checkSave(),
                timeoutPromise(5000), //
            ])
            if (replyOKOpenFile !== 'FOK') {
                alert('file does not write.')
                return
            }

            //write file
            const actionsData = JSON.parse(
                sessionStorage.getItem('createActionState')
            )
            const writeFileBytes = fileWriteByteGeneration({
                actionsData,
                mode: 'ALLACTIONS',
                connectedDevice,
                deviceVersion,
            })

            while (true) {
                let bytes = writeFileBytes.splice(0, 999)
                props.worker.postMessage({
                    type: 'WriteArrayFormat1310',
                    value: bytes,
                })
                sendDataAsPacket.current = {
                    byteSend: true,
                    responseMessage: '',
                }
                let replyOKCloseFile = await Promise.race([
                    checkSave(),
                    timeoutPromise(5000), //
                ])
                if (replyOKCloseFile !== 'FOK') {
                    alert('file does not write.')
                    return
                }

                if (writeFileBytes.length == 0) {
                    break
                }
            }

            //close file
            sendByte({ data: writeFileSteps.closeFileByte })
            sendDataAsPacket.current = { byteSend: true, responseMessage: '' }
            let replyOKCloseFile = await Promise.race([
                checkSave(),
                timeoutPromise(5000), //
            ])
            if (replyOKCloseFile !== 'FOK') {
                alert('file does not write.')
                return
            }
            props.setFilePopUp({
                isShow: false,
                isPlay: false,
            })

            if (props.filePopup.isPlay) {
                sendPlayBytes()
            }
        } catch (e) {
            console.log('ERROR', e)
        }
    }

    //---------------------- write file in board end ------------------------------------------

    //check the SAVE come from zing
    useEffect(() => {
        const messageHandler = (e) => {
            if (e.data.type === 'read' && e.data.value.includes('GRSV')) {
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: 'GRSV',
                }
            }
            if (
                e.data.type === 'read' &&
                ['FOK', 'FER'].includes(e.data.value)
            ) {
                sendDataAsPacket.current = {
                    byteSend: false,
                    responseMessage: e.data.value,
                }
            }
        }
        props.worker.addEventListener('message', messageHandler)

        return () => {
            props.worker.removeEventListener('message', messageHandler)
        }
    }, [])

    const sendSaveDataAsPackets = async (data, size) => {
        const actionLength = JSON.parse(
            sessionStorage.getItem('createActionState')
        ).allActions.length
        let count = 0
        while (data.length !== 0) {
            if (status) {
                let dataToBeSentNow = data.splice(0, size)
                sendBytes(dataToBeSentNow, props.webSerial.port)
                count += 1
                status = false
                let replyOK
                try {
                    await Promise.race([
                        (replyOK = await readBytes(
                            props.webSerial.port,
                            count === actionLength ? 'SAVED' : 'OK'
                        )),
                        new Promise((_, reject) =>
                            setTimeout(reject, 2000, new Error('timeout'))
                        ),
                    ])
                    if (replyOK === false) {
                        status = true
                        break
                    }
                    if (count === actionLength && replyOK) {
                        sessionStorage.setItem('isSave', JSON.stringify(true))
                        setFeedback({
                            isOn: true,
                            msg: 'Action saved successfully',
                        })
                        setTimeout(() => {
                            setFeedback({
                                isOn: false,
                                msg: null,
                            })
                        }, 1000)
                    }
                    if (replyOK) {
                        status = true
                    }
                } catch (error) {
                    window.location.reload()
                }
            }
        }
    }

    return (
        <>
            {props.filePopup.isShow && (
                <div className={style.container}>
                    {showActionLoader && <ActionLoader />}
                    <div
                        className={style.box}
                        style={{ width: '430px', minHeight: '200px' }}
                    >
                        <img
                            className={style.closeBtn}
                            style={{ width: '2.5rem', top: '-60px' }}
                            src={renderImage('clos')}
                            onClick={() => {
                                if (showActionLoader) return
                                props.setFilePopUp({
                                    isShow: false,
                                    isPlay: false,
                                })
                            }}
                        />
                        <input
                            placeholder="Enter the action name"
                            style={{
                                width: '15vw',
                                height: '4.5vh',
                                backgroundColor: 'lightgrey',
                                textAlign: 'center',
                                position: 'absolute',
                                borderRadius: '5px',
                                border: 'none',
                                top: '46%',
                                left: '50%',
                                transform: 'translate(-50%,-50%)',
                            }}
                            type="text"
                            value={fileName}
                            autoFocus={true}
                            onChange={inputHandler}
                            disabled={showActionLoader}
                        />{' '}
                        <br></br>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                            }}
                        >
                            <button
                                style={{
                                    background:
                                        ' linear-gradient(to right, #fda84e, #f97d62)',
                                    color: 'white',
                                    borderRadius: '7px',
                                }}
                                onClick={() => {
                                    if (showActionLoader) return
                                    saveFile()
                                }}
                            >
                                {' '}
                                Save as file
                            </button>
                            {props.webSerial.isConnected && (
                                <button
                                    style={{
                                        background:
                                            ' linear-gradient(to right, #fda84e, #f97d62)',
                                        color: 'white',
                                        borderRadius: '7px',
                                    }}
                                    onClick={() => {
                                        if (showActionLoader) return
                                        // uploadToBoard()
                                        fileWrite()
                                    }}
                                >
                                    Save to{' '}
                                    {connectedDevice === 'Hexapod'
                                        ? 'crawl-e'
                                        : 'Zing'}
                                </button>
                            )}
                        </div>
                    </div>
                    {feedback.isOn ? (
                        <p
                            style={{
                                position: 'absolute',
                                bottom: '10%',
                            }}
                        >
                            {feedback.msg}
                        </p>
                    ) : (
                        <></>
                    )}
                </div>
            )}
        </>
    )
}
const mapStateToProps = (state) => {
    return state
}
export default connect(mapStateToProps)(SavePopUp)
