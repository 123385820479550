import React, { useState } from 'react'
import renderImage from '../../source/importImg'
import SliderBox from '../ReusableComponents/SliderBox/SliderBox'
import TextRow from './helpers/TextRow'
import SelectionRow from '../logic/panels/output/SelectionRow'
import unicodeToChar from '../../utils/unicodeToChar'
import { DEFAULTSERVOVALUES } from '../Code/CreateAction/defaultData'
import { ColorRing } from 'react-loader-spinner'

import { connect } from 'react-redux'

import {
    EXTERNALDEVICES,
    CUSTOMGROUPACTIONS,
    CUSTOMGROUPACTIONS12,
    GROUPACTIONS,
    PORTDATA,
} from './defaultData'

import './ActionPanel.css'
import { useEffect } from 'react'
import {
    decimalToMSBLSB,
    generateDeviceDataKey,
    utf16ToChar,
} from '../ReusableComponents/ByteTransfer/utils'
import {
    sendAndWaitForAck,
    sendBytes,
} from '../ReusableComponents/ByteTransfer'
import {
    compName_character,
    klawLiveServoPortsNumber,
    livePortsNumber,
} from '../logic/panels/helpers/LiveButton'

var humanoidRGBButtonGrp = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5rem',
    marginBottom: '2rem',
}

var humanoidStyleAdd = {
    backgroundImage: `url(${renderImage('add3x')}`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 40,
    width: 40,
    cursor: 'pointer',
}

var humanoidStyleRemove = {
    backgroundImage: `url(${renderImage('remove3xIA')}`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 40,
    width: 40,
    cursor: 'pointer',
}

const availableExternalPorts = {
    HUMANOID: [31, 32, 33, 34, 35, 36, 37, 38],
    HEXAPOD: [31, 32, 33, 34, 35, 36, 37, 38],
    KLAW: [31, 32, 33, 34, 35, 36],
}

function ActionPanel({
    webSerial,
    check,
    logic,
    assembly,
    isUpdateRedux,
    updateLogic,
    worker,
}) {
    const [portData, setPortData] = useState(
        JSON.parse(sessionStorage.getItem('humanoidPortData'))
    )

    const [programData, setProgramData] = useState(
        JSON.parse(sessionStorage.getItem('humanoidProgramData'))
    )

    const [isSliderChanging, setIsSliderChanging] = useState(false)
    const [id, setId] = useState()
    const [value, setValue] = useState()
    const [customActionList, setCustomActionList] =
        useState(CUSTOMGROUPACTIONS12)
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(100)
    // const zingVersion = sessionStorage.getItem('zingVersion') || '0.0.0'
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const connectedDevice = sessionStorage.getItem('connectedDevice') || ''
    const [dropDownValue, setDropDownValue] = useState('default')
    const [showActionLoader, setShowActionLoader] = useState(false)
    let PORTDATAKey = generateDeviceDataKey(connectedDevice, deviceVersion)
    if (
        ![
            'HUMANOID0',
            'HUMANOID1',
            'HUMANOID2',
            'HEXAPOD1',
            'HEXAPOD2',
            'KLAW1',
        ].includes(PORTDATAKey)
    ) {
        PORTDATAKey = 'HUMANOID0'
    }

    const EXTERNALDEVICESData =
        ['0', '1'].includes(deviceVersion[0]) &&
        ['Hexapod', 'Humanoid'].includes(connectedDevice)
            ? EXTERNALDEVICES['v1']
            : EXTERNALDEVICES['v2']

    let startTypes = JSON.parse(sessionStorage.getItem('logic')).program[0]
        .state

    //rearrange the oder of bidata1 and bidata2
    if (startTypes) {
        try {
            const rearrangedObj = {
                ...Object.fromEntries(
                    Object.entries(startTypes).filter(([key]) => key !== 'bid2')
                ),
                ...Object.fromEntries(
                    Object.entries(startTypes).filter(([key]) => key === 'bid2')
                ),
            }
            startTypes = rearrangedObj
        } catch (e) {
            console.log('ERROR', e)
        }
    }

    const timeoutPromise = (ms) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Timeout exceeded'))
            }, ms)
        })
    }
    const checkOk = async () => {
        return new Promise(async (resolve, reject) => {
            while (true) {
                if (!JSON.parse(sessionStorage.getItem('sendDataAsPacket'))) {
                    return resolve()
                }

                await new Promise((reslove) => {
                    setTimeout(reslove, 100)
                })
            }
        })
    }
    // This useEffect takes care of updating only slider changes to the REDUX
    // This is put in a separate useEffect using [timeout and clear timeout] to avoid slider lag
    // caused by continuous redux updation on every slider change. Using timeout and clear timeout avoids this lag.
    useEffect(() => {
        if (!isSliderChanging) return

        //remove  the 500ms because, if we change the action slider vary fast that time in slower window machine
        //redux does not updates. now give 100ms
        const timeout = setTimeout(() => {
            if (id == 0 || id == 1) {
                // handling redux logic differently for RBG sliders
                updateReduxLogic(
                    [
                        `${PORTDATA[PORTDATAKey][id].reduxLogicName[1]}R`,
                        `${PORTDATA[PORTDATAKey][id].reduxLogicName[1]}G`,
                        `${PORTDATA[PORTDATAKey][id].reduxLogicName[1]}B`,
                    ],
                    [value[0], value[1], value[2]]
                )
            } else if ([66, 67, 68, 69, 70].includes(id)) {
                updateReduxLogic(
                    [
                        PORTDATA[PORTDATAKey][id].reduxLogicName[1],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[2],
                    ],
                    [value[0], value[1]]
                )
            } else if ([63, 54, 71, 72, 73, 74, 75].includes(id)) {
                updateReduxLogic(
                    [
                        PORTDATA[PORTDATAKey][id].reduxLogicName[0],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[1],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[2],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[3],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[4],
                    ],
                    [value[0], value[1], value[2], value[3], value[4]]
                )
            } else if (['76', '77', '78', '79', '80', '81'].includes(id)) {
                updateReduxLogic(
                    [
                        PORTDATA[PORTDATAKey][id].reduxLogicName[1],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[2],
                        PORTDATA[PORTDATAKey][id].reduxLogicName[3],
                    ],
                    [value[0], value[1], value[2]]
                )
            } else {
                updateReduxLogic(
                    [PORTDATA[PORTDATAKey][id].reduxLogicName[1]],
                    [value]
                )
            }
            setIsSliderChanging(false)
        }, 100)
        return () => {
            clearTimeout(timeout)
        }
    }, [value, isSliderChanging])

    //handel the drop down value change
    const handelDropDown = (id, value, type) => {
        let newValuearr = ['default', 'default']
        const newProgramData = { ...programData }
        if (type === 'output') {
            newProgramData[check].state[id].value[0] = value
            newValuearr[0] = value
        } else if (type === 'input') {
            newProgramData[check].state[id].value[1] = value
            newValuearr[1] = value
        }
        setProgramData(newProgramData)
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
        setId(id)
        setIsSliderChanging(true)
        setValue([...newValuearr])
    }

    // updates data for slider change
    const handleSliderChange = (id, value, type) => {
        const newProgramData = { ...programData }
        if ([63, 54, 71, 72, 73, 74, 75].includes(id)) {
            let newValuearr = []

            newValuearr[0] = newProgramData[check].state[id].value[0]
            // logic.program[check].state[
            //     PORTDATA[`${connectedDevice.toUpperCase()}${deviceVersion.slice(0,3)}`][id].reduxLogicName[0]
            // ]
            newValuearr[1] = newProgramData[check].state[id].value[1]
            // logic.program[check].state[
            //     PORTDATA[`${connectedDevice.toUpperCase()}${deviceVersion.slice(0,3)}`][id].reduxLogicName[1]
            // ]
            if (type === 'dropdown') {
                newValuearr[2] = newProgramData[check].state[id].value[2]
                // logic.program[check].state[
                //     PORTDATA[PORTDATAKey][id].reduxLogicName[2]
                // ]
            } else {
                newValuearr[2] = value
            }
            newValuearr[3] = newProgramData[check].state[id].value[3]
            // logic.program[check].state[
            //     PORTDATA[PORTDATAKey][id].reduxLogicName[3]
            // ]
            if (type === 'dropdown') {
                newValuearr[4] = value
                setDropDownValue(value)
            } else {
                newValuearr[4] = newProgramData[check].state[id].value[4]
                // logic.program[check].state[
                //     PORTDATA[PORTDATAKey][id].reduxLogicName[4]
                // ]
            }
            newProgramData[check].state[id].value = [...newValuearr]
            setProgramData(newProgramData)

            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(newProgramData)
            )

            setIsSliderChanging(true)
            setId(id)
            // 'value' - only to trigger useEffect
            setValue(newValuearr)
        } else if (['76', '77', '78', '79', '80', '81'].includes(id)) {
            newProgramData[check].state[id][`valueEyeR${id - 75}`] = value[0]
            newProgramData[check].state[id][`valueEyeG${id - 75}`] = value[1]
            newProgramData[check].state[id][`valueEyeB${id - 75}`] = value[2]
            newProgramData[check].state[id].value = value
            setProgramData(newProgramData)

            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(newProgramData)
            )
            setIsSliderChanging(true)
            setId(id)
            // 'value' - only to trigger useEffect
            setValue(value)
        } else {
            newProgramData[check].state[id].value = value
            setProgramData(newProgramData)

            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(newProgramData)
            )
            setIsSliderChanging(true)
            setId(id)
            // 'value' - only to trigger useEffect
            setValue(value)
        }
    }

    //off the live output
    const liveOffByteSend = async (id) => {
        // let assembly = props.assembly
        const portName = portData[id]?.name?.split(' ')?.[1]
        const externalDevice = assembly.PortConnections?.[portName]?.type

        const senData = (data) => {
            worker.postMessage({
                type: 'writeArray',
                value: data,
            })
        }

        if (
            ['Klaw'].includes(connectedDevice) &&
            ['6', '7', '8', '9', '10'].includes(id)
        ) {
            let defaultValue = portData?.[id]?.value
            if (defaultValue == undefined) return
            let newValue = Math.round(0.6 * defaultValue)

            let data = ['L'.charCodeAt(0), 'W'.charCodeAt(0)]
            data.push(klawLiveServoPortsNumber[id]) //compName_character[PortConnections[port].type]
            data.push('S'.charCodeAt(0)) //livePortsNumber[port]
            data.push(newValue)
            senData(data)
        } else if (
            [
                'led_1c',
                'led',
                'servo_motor',
                'servo_motor_270',
                'servo_motor_360',
            ].includes(externalDevice)
        ) {
            let data = ['L'.charCodeAt(0), 'W'.charCodeAt(0)]
            data.push(compName_character[externalDevice])
            data.push(livePortsNumber[portName])
            if (externalDevice == 'servo_motor_360') {
                data.push(100)
            } else {
                data.push(0)
            }

            senData(data)
        } else if (
            ['mini_geared_motor', 'dc_motor', 'dynamex_motor'].includes(
                externalDevice
            )
        ) {
            let data = ['L'.charCodeAt(0), 'W'.charCodeAt(0)]
            data.push(compName_character[externalDevice])

            let data1 = [...data, livePortsNumber[portName], 0]
            let data2 = [...data, livePortsNumber[portName[0] + '2'], 0]

            senData(data1)
            await new Promise((resolve) => setTimeout(resolve, 100))
            senData(data2)
        }
    }
    // }

    // updates data when enable/disable external device button click
    const handleExternalDeviceLiveBtnClick = (id, isLiveBtnActive) => {
        const newProgramData = { ...programData }
        if (programData[check]?.state?.[id]?.isLiveBtnActive !== undefined) {
            //off the live output send bytes
            if (programData[check]?.state?.[id].isLiveBtnActive) {
                liveOffByteSend(id)
            }

            newProgramData[check].state[id].isLiveBtnActive = isLiveBtnActive
            // !programData[check]?.state?.[id].isLiveBtnActive

            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(newProgramData)
            )

            updateReduxLogic(
                [PORTDATA[PORTDATAKey][id].reduxLogicName[2]],
                [newProgramData[check].state[id].isLiveBtnActive]
            )
        }
    }

    // updates data when enable/disable sensor button
    const handleSliderBtnClick = (id, assignBid) => {
        const newProgramData = { ...programData }

        //send the off live write
        if (programData[check]?.state?.[id]?.isLiveBtnActive !== undefined) {
            if (
                programData[check]?.state?.[id].isLiveBtnActive &&
                programData[check].state[id].isActionEnabled
            ) {
                liveOffByteSend(id)
                newProgramData[check].state[id].isLiveBtnActive = false
            }
        }

        newProgramData[check].state[id].isActionEnabled =
            !programData[check].state[id].isActionEnabled
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )

        if (id == 0 || id == 1) {
            // handling redux logic differently for RBG slider's enable/disable button
            updateReduxLogic(
                [
                    `${PORTDATA[PORTDATAKey][id].reduxLogicName[0]}R`,
                    `${PORTDATA[PORTDATAKey][id].reduxLogicName[0]}G`,
                    `${PORTDATA[PORTDATAKey][id].reduxLogicName[0]}B`,
                ],
                [
                    newProgramData[check].state[id].isActionEnabled,
                    newProgramData[check].state[id].isActionEnabled,
                    newProgramData[check].state[id].isActionEnabled,
                ]
            )
        } else if ([63, 54, 71, 72, 73, 74, 75].includes(id)) {
            updateReduxLogic(
                [
                    PORTDATA[PORTDATAKey][id].reduxLogicName[0],
                    PORTDATA[PORTDATAKey][id].reduxLogicName[1],
                    PORTDATA[PORTDATAKey][id].reduxLogicName[2],
                    PORTDATA[PORTDATAKey][id].reduxLogicName[3],
                    PORTDATA[PORTDATAKey][id].reduxLogicName[4],
                ],
                [
                    // newProgramData[check].state[id].isActionEnabled,
                    // logic.program[check].state[
                    //     PORTDATA[id].reduxLogicName[0]
                    // ],
                    !newProgramData[check].state[id].value[0],
                    !assignBid,
                    0,
                    false,
                    'default',
                ]
            )
            newProgramData[check].state[id].value = [
                !newProgramData[check].state[id].value[0],
                !assignBid,
                0,
                false,
                'default',
            ]
            setProgramData(newProgramData)

            sessionStorage.setItem(
                'humanoidProgramData',
                JSON.stringify(newProgramData)
            )
        } else {
            updateReduxLogic(
                [
                    PORTDATA[PORTDATAKey][id].reduxLogicName[0],
                    PORTDATA[PORTDATAKey][id].reduxLogicName[2],
                ],
                [newProgramData[check].state[id].isActionEnabled, false]
            )
        }
        // }
    }
    const handelAssignButtonClick = (id, assignBid) => {
        const newProgramData = { ...programData }

        let newValuearr = [true, false, 0, false, 'default']
        if (assignBid) {
            newValuearr[1] = true
            newValuearr[2] = programData[check].state[id].value[2]
            newValuearr[3] = false
            newValuearr[4] = programData[check].state[id].value[4]
        } else {
            newValuearr[1] = false
            newValuearr[2] = programData[check].state[id].value[2]
            newValuearr[3] = true
            newValuearr[4] = programData[check].state[id].value[4]
        }
        newProgramData[check].state[id].value = [...newValuearr]

        updateReduxLogic(
            [
                PORTDATA[PORTDATAKey][id].reduxLogicName[0],
                PORTDATA[PORTDATAKey][id].reduxLogicName[1],
                PORTDATA[PORTDATAKey][id].reduxLogicName[2],
                PORTDATA[PORTDATAKey][id].reduxLogicName[3],
                PORTDATA[PORTDATAKey][id].reduxLogicName[4],
            ],
            [...newValuearr]
        )

        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
        setIsSliderChanging(true)
        setId(id)
        // 'value' - only to trigger useEffect
        setValue(newValuearr)
    }

    // (Drop-Dowm Menu of ZingV0.1)  handles when a create action is selected from the group actions.
    const handleActionSelect = () => {
        console.log(
            '//CHECKINGmySelection',
            document.getElementById('mySelection').value
        )

        let value = document.getElementById('mySelection').value
        const newProgramData = { ...programData }
        newProgramData[check].state['4a'].value =
            programData[check].state['4a'].value.slice(0, 2) ===
            value.slice(0, 2)
                ? value
                : 0
        setProgramData(newProgramData)

        let customActionKeys = []
        let groupActionKeys = Object.entries({
            ...GROUPACTIONS[`${connectedDevice.toUpperCase()}`],
        }).map((action) => {
            if (action[1].name !== 'CustomAction') {
                return `assign${action[1].name.replaceAll(' ', '')}`
            } else {
                customActionKeys.push(
                    `assign${action[1].name.replaceAll(' ', '')}`
                )
                customActionKeys.push(
                    `value${action[1].name.replaceAll(' ', '')}`
                )
                return
            }
        })
        groupActionKeys = groupActionKeys.concat(customActionKeys)
        let customActionValues = []
        let groupActionValues = Object.entries({
            ...GROUPACTIONS[`${connectedDevice.toUpperCase()}`],
        }).map((action) => {
            if (action[1].name !== 'CustomAction') {
                if (newProgramData[check].state['4a'].value == action[0])
                    return true
                return false
            } else {
                customActionValues.push(
                    newProgramData[check].state['4a'].value
                        .toString()
                        .slice(0, 2) == action[0]
                )
                customActionValues.push(
                    ['1', '2'].includes(deviceVersion[0]) &&
                        ['Humanoid', 'Hexapod'].includes(connectedDevice)
                        ? customActionList[value] !== undefined
                            ? customActionList[value].value
                            : 24
                        : CUSTOMGROUPACTIONS[
                              `${connectedDevice.toUpperCase()}`
                          ][value] !== undefined
                        ? CUSTOMGROUPACTIONS[
                              `${connectedDevice.toUpperCase()}`
                          ][value].value
                        : 24
                )
                return
            }
        })
        groupActionValues = groupActionValues.concat(customActionValues)
        updateReduxLogic(groupActionKeys, groupActionValues)
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // (Drop-Dowm Menu of ZingV1.0)  handles when a create action is selected from the group actions.

    // handles when a particular action is selected from the group actions.
    const handleAction = (event) => {
        console.log(event.target.id)
        const newProgramData = { ...programData }
        if (event.target.id == '75') {
            newProgramData[check].state['4a'].value = '74'
            newProgramData[check].state['4a'].isRandomActionSelected =
                !newProgramData[check].state['4a'].isRandomActionSelected
        } else {
            newProgramData[check].state['4a'].value =
                programData[check].state['4a'].value.toString().slice(0, 2) ===
                event.target.id
                    ? 0
                    : event.target.id
            newProgramData[check].state['4a'].isRandomActionSelected = false
        }
        setProgramData(newProgramData)

        // redux update code
        let customActionKeys = []
        let groupActionKeys = Object.entries({
            ...GROUPACTIONS[`${connectedDevice.toUpperCase()}`],
        }).map((action) => {
            if (action[1].name !== 'CustomAction') {
                return `assign${action[1].name.replaceAll(' ', '')}`
            } else {
                customActionKeys.push(
                    `assign${action[1].name.replaceAll(' ', '')}`
                )
                customActionKeys.push(
                    `value${action[1].name.replaceAll(' ', '')}`
                )
                return
            }
        })

        groupActionKeys = groupActionKeys.concat(customActionKeys)
        let actionDamy = []
        let customActionValues = []
        let groupActionValues = Object.entries({
            ...GROUPACTIONS[`${connectedDevice.toUpperCase()}`],
        }).map((action) => {
            if (action[1].name == 'RandomAction') {
                actionDamy.push({
                    name: action[1].name,
                    value: newProgramData[check].state['4a']
                        .isRandomActionSelected,
                })
                return newProgramData[check].state['4a'].isRandomActionSelected
            } else if (action[1].name !== 'CustomAction') {
                actionDamy.push({
                    name: action[1].name,
                    value: event.target.id == action[0],
                })
                if (event.target.id == action[0]) return true
                return false
            } else {
                customActionValues.push(
                    newProgramData[check].state['4a'].value
                        .toString()
                        .slice(0, 2) == action[0]
                )
                ///////////////// Default value should be 24 this is because when the user////////////
                //////////////// select 'select option' or leave the button enabled no action shoule be performed////////////////////
                if (event.target.id == '75') {
                    customActionValues.push(28)
                } else {
                    customActionValues.push(24)
                }
                actionDamy.push({
                    name: action[1].name,
                    value: newProgramData[check].state['4a'].value == action[0],
                })

                return
            }
        })
        groupActionValues = groupActionValues.concat(customActionValues)
        updateReduxLogic(groupActionKeys, groupActionValues)
        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // seperate function to handle enable/diable OLED
    const handleOLEDButtonClick = (id) => {
        const newProgramData = { ...programData }
        newProgramData[check].state[37].oledState[id].isEnabled =
            !newProgramData[check].state[37].oledState[id].isEnabled
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // OLED FOR PROJECT BASED
    const handleOLEDButtonClickExternal = (id) => {
        const newPortData = { ...portData }
        newPortData[37].oledState[id].isEnabled =
            !newPortData[37].oledState[id].isEnabled
        setPortData(newPortData)

        sessionStorage.setItem('humanoidPortData', JSON.stringify(newPortData))
    }
    const handleOLEDTextChange = (id, value) => {
        const newProgramData = { ...programData }
        newProgramData[check].state[37].oledState[id].value = value
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // OLED PROJETC BASED

    const handleOLEDTextChangeExternal = (id, value) => {
        const newPortData = { ...portData }
        newPortData[37].oledState[id].value = value
        setPortData(newPortData)

        sessionStorage.setItem('humanoidPortData', JSON.stringify(newPortData))
    }

    // This RGB function is for Port B1's RGB not the left/RightEye RGB
    const handleHumanoidRGBSlider = (id, value) => {
        const newProgramData = { ...programData }
        newProgramData[check].state[33].rgbState[id].value = value
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // This RGB function is for Port B1's RGB not the left/RightEye RGB
    const handleHumanoidRGBButtonClick = (id) => {
        const newProgramData = { ...programData }
        newProgramData[check].state[33].rgbState[id].isEnabled =
            !newProgramData[check].state[33].rgbState[id].isEnabled
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // function to add multiple external RGBs on port B
    const humanoidAddRGB = () => {
        const newProgramData = { ...programData }
        const count = programData[check].state[33].rgbCount
        if (count == 10) return

        newProgramData[check].state[33].rgbCount = count + 1
        newProgramData[check].state[33].rgbState[count + 1].show = true
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // function to remove external RGBs on port B
    const humanoidRemoveRGB = () => {
        const newProgramData = { ...programData }
        const count = programData[check].state[33].rgbCount
        if (count == 1) return

        newProgramData[check].state[33].rgbState[count].show = false
        newProgramData[check].state[33].rgbCount = count - 1
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
    }

    // Recursive function that traverses depth wise to find a particular node and update it.
    const findNodeAndUpdate = (program, id, keys, values) => {
        for (let node = 0; node < program.length; node++) {
            if (program[node].nodeId === id) {
                for (let i = 0; i < keys.length; i++) {
                    program[node].state[keys[i]] = values[i]
                }
                break
            }
            if (
                program[node].type === 'condition' ||
                program[node].type === 'sensor' ||
                program[node].type === 'loop'
            ) {
                findNodeAndUpdate(program[node].subprogram, id, keys, values)
            }
        }
    }

    // redux update function
    // keys and values are array of same length - multiple key: values can be updated on a single dispatch
    const updateReduxLogic = (keys, values) => {
        if (isUpdateRedux !== true) return

        let newLogic = JSON.parse(JSON.stringify(logic))
        findNodeAndUpdate(newLogic.program, check, keys, values)
        updateLogic(newLogic)
    }

    // this function decides whether to show a certain port or not.
    // It also returns the button text for that port.
    const decideRenderPort = (portId) => {
        // if (PORTDATA[PORTDATAKey][portId]) {
        const portName = PORTDATA[PORTDATAKey][portId].name.split(' ')[1]
        if (assembly.PortConnections[portName] == null)
            return { isRenderPort: false }

        const externalDevice = assembly.PortConnections[portName].type

        if (EXTERNALDEVICESData[externalDevice].type == 'input')
            return { isRenderPort: false }

        // rendering once for motors
        if (
            ['geared_motor', 'mini_geared_motor', 'dc_motor'].includes(
                externalDevice
            )
        ) {
            if (connectedDevice == 'Klaw' && [35, 36].includes(portId)) {
                return {
                    isRenderPort: true,
                    btnText: `Port ${portName} : ${EXTERNALDEVICESData[externalDevice].name}`,
                    externalDevice,
                }
            }
            if (portId % 2 === 0) return { isRenderPort: false }
            return {
                isRenderPort: true,
                btnText: `Port ${portName[0]} : ${EXTERNALDEVICESData[externalDevice].name}`,
                externalDevice,
            }
        }
        // for devices that take up both ports (e.g. A1 & A2) we render the slider only once
        // if (portId % 2 === 0) {
        // 	const complimentPortName = PORTDATA[PORTDATAKey][portId - 1].name.split(" ")[1];
        // 	if (
        // 		assembly.PortConnections[portName].type ===
        // 		assembly.PortConnections[complimentPortName].type
        // 	)
        // 		return {
        // 			isRenderPort: true,
        // 			btnText: `Port ${portName[0]} 112 : ${EXTERNALDEVICES[externalDevice].name}`,
        // 			externalDevice,
        // 		};
        // }

        return {
            isRenderPort: true,
            btnText: `Port ${portName} : ${EXTERNALDEVICESData[externalDevice].name}`,
            externalDevice,
        }
        // }
    }

    const [lastSentBytesTime, setLastSentBytesTime] = useState(Date.now())

    const liveServoFeedback = (servoId, value, id) => {
        if (servoId === 'T') return
        const servoFeedbackBytes = []
        servoFeedbackBytes.push(
            'S'.charCodeAt(0),
            'M'.charCodeAt(0),
            parseInt(servoId)
        )

        let MSBLSB
        if (id && programData[check]?.state?.[id]?.isLiveSActive) {
            let defaultServoValue = PORTDATA[PORTDATAKey]?.[id]?.value
            if (defaultServoValue) {
                MSBLSB = decimalToMSBLSB(defaultServoValue)
                servoFeedbackBytes.push(MSBLSB[0], MSBLSB[1])
            }
        } else {
            let MSBLSB = decimalToMSBLSB(value)
            servoFeedbackBytes.push(MSBLSB[0], MSBLSB[1])
        }

        // sends bytes every 100ms
        // Zing doesn't respond when sent faster than 100ms
        const now = Date.now()
        if (now - lastSentBytesTime < 100) return

        worker.postMessage({
            type: 'writeArray',
            value: servoFeedbackBytes,
        })

        setLastSentBytesTime(now)
    }

    //for live servo button state save
    const handelServoButtonClick = (servoId, id, checkLiveServo) => {
        if (servoId === 'T') return
        if (
            checkLiveServo &&
            checkLiveServo === 'checkLiveServo' &&
            !programData[check].state[id].isLiveSActive
        ) {
            return
        }
        const newProgramData = { ...programData }
        newProgramData[check].state[id].isLiveSActive =
            !programData[check].state[id].isLiveSActive
        setProgramData(newProgramData)

        sessionStorage.setItem(
            'humanoidProgramData',
            JSON.stringify(newProgramData)
        )
        updateReduxLogic(
            [PORTDATA[PORTDATAKey][id].reduxLogicName[2]],
            [newProgramData[check].state[id].isLiveSActive]
        )
    }

    const [zingSlotData, setZingSlotData] = useState(['F', 'F', 'F', 'F', 'F'])

    // This Function fetches Zing createAction slot data.
    const fetchActionSlotData = async () => {
        // if (
        //     !(
        //         deviceVersion.includes('1.0.0') &&
        //         ['Humanoid'].includes(connectedDevice)
        //     )
        // ) {
        //     let response = await sendAndWaitForAck(
        //         ['F'.charCodeAt(0), 'S'.charCodeAt(0)],
        //         5,
        //         webSerial.port
        //     )
        //     if (response.ok) {
        //         const zingSlotData = utf16ToChar(response.data)
        //         setZingSlotData(zingSlotData)
        //     }
        // }

        // if (deviceVersion?.startsWith('1')) {
        //     if (webSerial.port && webSerial.port.readable) {
        //         await sendBytes(
        //             ['F'.charCodeAt(0), 'R'.charCodeAt(0)],
        //             webSerial.port
        //         )
        //         console.log(webSerial.port)
        //         let reader = webSerial.port.readable.getReader()
        //         let actionsList = ''
        //         while (true) {
        //             const { value } = await Promise.race([
        //                 reader.read(),
        //                 new Promise((_, reject) =>
        //                     setTimeout(reject, 2000, new Error('timeout'))
        //                 ),
        //             ])
        //             const result = unicodeToChar(value).trim()
        //             actionsList += result
        //             if (actionsList.slice(-3) === 'END') {
        //                 actionsList = actionsList.slice(0, -3)
        //                 reader.releaseLock()
        //                 break
        //             }
        //         }
        //         actionsList = actionsList
        //             .replace(/.BIN/g, ',')
        //             .split(',')
        //             .map((item) => item.trim())
        //         for (let i = 0; i <= actionsList.slice(0, -1).length - 1; i++) {
        //             const id = `74${80 + i + 1}`
        //             const name = actionsList[i]
        //             const value = 24 + i + 1
        //             CUSTOMGROUPACTIONS12[id] = { name, value }
        //         }
        //         setCustomActionList(CUSTOMGROUPACTIONS12)
        //     }
        // } else
        if (['1', '2'].includes(deviceVersion[0])) {
            setShowActionLoader(true)
            worker.postMessage({
                type: 'fetchFileWriteArray',
                value: ['F'.charCodeAt(0), 'R'.charCodeAt(0)],
            })
            sessionStorage.setItem('sendDataAsPacket', 'true')
            try {
                let replyOK = await Promise.race([
                    checkOk(),
                    timeoutPromise(2000), //
                ])
                setShowActionLoader(false)
            } catch (e) {
                setShowActionLoader(false)
            }
        } else {
            let response = await sendAndWaitForAck(
                ['F'.charCodeAt(0), 'S'.charCodeAt(0)],
                5,
                webSerial.port
            )
            if (response.ok) {
                const zingSlotData = utf16ToChar(response.data)
                setZingSlotData(zingSlotData)
            }
        }
    }

    // useEffect(async () => {
    //     if (portData['4a'].isPortSelected) fetchActionSlotData()
    // }, [webSerial])

    // changing medium value of Individual servo for zingv1.0
    // useEffect(() => {
    //     let version = sessionStorage.getItem('zingVersion')

    //     if (version.startsWith('1')) {
    //         const newProgramData = { ...programData }
    //         Object.keys(newProgramData[check].state).map((key) => {
    //             if (key >= 5) {
    //                 newProgramData[check].state[5].value =
    //                     DEFAULTSERVOVALUES[key - 4]
    //             }
    //         })
    //         setProgramData(newProgramData)

    //         sessionStorage.setItem(
    //             'humanoidProgramData',
    //             JSON.stringify(newProgramData)
    //         )
    //     }
    // }, [])
    const getMinRangeV1 = (min, max, servoId) => {
        if (
            ['1', '2'].includes(deviceVersion[0]) &&
            ['Humanoid'].includes(connectedDevice)
        ) {
            switch (servoId) {
                case 1:
                    return { min: 1600, max: 2900 }
                case 2:
                    return { min: 400, max: 3600 }
                case 3:
                    return { min: 400, max: 3600 }
                case 4:
                    return { min: 100, max: 3300 }
                case 5:
                    return { min: 1600, max: 3500 }
                case 6:
                    return { min: 500, max: 3600 }
                case 7:
                    return { min: 500, max: 3600 }
                case 8:
                    return { min: 0, max: 4095 }
                case 9:
                    return { min: 1200, max: 2400 }
                case 10:
                    return { min: 500, max: 3700 }
                case 11:
                    return { min: 500, max: 3700 }
                case 12:
                    return { min: 800, max: 4000 }
                case 13:
                    return { min: 500, max: 2500 }
                case 14:
                    return { min: 500, max: 3500 }
                case 15:
                    return { min: 500, max: 3500 }
                case 16:
                    return { min: 0, max: 4095 }
            }
        }
        return { min: min, max: 4095 }
    }

    // const getMaxRangeV1 = (max) => {
    //     return (max = 4095)
    // }

    const decidePortName = (port) => {
        var port = port[1]
        if (JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo`))) {
            return `Port ${port.name.slice(-2)} : Servo Motor`
        } else if (
            JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo360`))
        ) {
            return `Port ${port.name.slice(-2)} : Servo Motor 360`
        } else if (
            JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo270`))
        ) {
            return `Port ${port.name.slice(-2)} : Servo Motor 270`
        } else {
            if (
                JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}DIGI`))
            ) {
                return `${port.name.slice(-2)} Analog`
            } else {
                return port.name
            }
        }
    }

    const getMin = (port) => {
        if (
            decidePortName(port).includes('M1') ||
            decidePortName(port).includes('M2')
        ) {
            return -100
        }
        var port = port[1]
        if (
            JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo360`))
        ) {
            return -100
        } else {
            return 0
        }
    }

    const getMax = (port) => {
        if (
            decidePortName(port).includes('M1') ||
            decidePortName(port).includes('M2')
        ) {
            return 100
        }
        var port = port[1]
        if (JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo`))) {
            return 180
        } else if (
            JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo360`))
        ) {
            return 100
        } else if (
            JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}Servo270`))
        ) {
            return 270
        } else {
            if (
                JSON.parse(sessionStorage.getItem(`${port.name.slice(-2)}DIGI`))
            ) {
                return 100
            } else if (port.name === 'MP3 Port') {
                return 255
            } else if (port.name === 'Servo' || port.name === 'Servo Head') {
                return 180
            } else {
                return 1
            }
        }
    }

    const startPanelKeyObj = {
        bic1: 52,
        bic2: 53,
        btTx: '',
        bif1: 55,
        bif2: 56,
        usbtx: 65,
        bid1: 54,
        bid2: 63,
        asgn1: 66,
        asgn2: 67,
        asgn3: 68,
        asgn4: 69,
        asgn5: 70,
        BtTx1: 71,
        BtTx2: 72,
        BtTx3: 73,
        BtTx4: 74,
        BtTx5: 75,
    }
    //finde the key for start panel word in defaultData object
    const findestartPanelKey = (value) => {
        return startPanelKeyObj[value]
    }
    console.log('checkkkk', programData, check)

    useEffect(() => {
        // console.log()
        setCustomActionList(logic.CUSTOMGROUPACTIONS12)
    }, [
        logic.CUSTOMGROUPACTIONS12,
        programData[check].state['4a']?.value.toString().slice(0, 2),
    ])

    //read from serial worker
    useEffect(() => {
        const messageHandler = (e) => {
            let fsLength = 0
            if (e.data.type === 'actionRead') {
                let { arrayData, stringData } = e.data.value

                //extract the data after "FR" and before "END"
                let regexEqu = /13,10,70,82,([^]*?),10,69,78,68/
                let match = arrayData.join(',').match(regexEqu)
                if (match) {
                    const matchArr = match[1].split(',')
                    fsLength = matchArr[0]
                    const extractStringData = unicodeToChar(matchArr)
                    let fileNameArr = extractStringData.split(/[\n]+/)
                    //in the fileNameArr remove the length of actions
                    fileNameArr.shift()

                    for (let i = 0; i < fileNameArr.length; i++) {
                        const id = `74${80 + i + 1}`
                        const name = fileNameArr[i]
                        CUSTOMGROUPACTIONS12[id] = {
                            name,
                            value: name,
                        }
                    }

                    let newLogic = {
                        ...logic,
                        CUSTOMGROUPACTIONS12,
                    }
                    updateLogic(newLogic)
                    sessionStorage.setItem('sendDataAsPacket', 'false')
                }

                // if (stringData.includes('FR') && !stringData.includes('BIN')) {
            }
        }
        worker.addEventListener('message', messageHandler)

        return () => {
            worker.removeEventListener('message', messageHandler)
        }
    }, [])

    //get the current action panel live button state
    const findeCurrentPanelstate = (program, portId) => {
        try {
            for (let node = 0; node < program.length; node++) {
                if (program[node].nodeId === check) {
                    let reduxLogicNameArr = portData[portId]?.reduxLogicName

                    let reduxName =
                        reduxLogicNameArr[reduxLogicNameArr.length - 1]
                    if (reduxName.includes('liveBtn')) {
                        let currentState = program[node].state
                        return currentState[reduxName]
                    }
                }
                if (
                    program[node].type === 'condition' ||
                    program[node].type === 'sensor' ||
                    program[node].type === 'loop'
                ) {
                    if (program[node].subprogram.length > 0)
                        return findeCurrentPanelstate(
                            program[node].subprogram,
                            portId
                        )
                }
            }
        } catch (e) {
            console.log('ERROR', e)
            return false
        }
    }

    return (
        <div>
            {/* //////////////////// */}
            {/* External Accessories */}
            {availableExternalPorts[connectedDevice.toUpperCase()].map(
                (portId) => {
                    // if (!PORTDATA[PORTDATAKey][portId]) {
                    //     console.log('JJJJJJJJ', portId)
                    //     return
                    // }
                    const { isRenderPort, btnText, externalDevice } =
                        decideRenderPort(portId)

                    if (!isRenderPort) return

                    let minSliderValue = EXTERNALDEVICESData[externalDevice].min
                    let maxSliderValue = EXTERNALDEVICESData[externalDevice].max
                    if (
                        connectedDevice == 'Klaw' &&
                        [35, 36].includes(portId)
                    ) {
                        minSliderValue = 0
                    }

                    let sliderType = 'single'
                    if (externalDevice === 'RGB') sliderType = 'RGB'
                    return (
                        <>
                            <SliderBox
                                key={portId}
                                id={portId}
                                btnText={btnText}
                                sliderText="Value"
                                value={programData[check].state[portId].value}
                                min={minSliderValue}
                                max={maxSliderValue}
                                handleSliderChange={handleSliderChange}
                                handleClick={handleSliderBtnClick}
                                handleExternalDeviceLiveBtnClick={
                                    handleExternalDeviceLiveBtnClick
                                }
                                isLiveBtnActive={findeCurrentPanelstate(
                                    logic.program,
                                    portId
                                )}
                                isEnabled={
                                    programData[check].state[portId]
                                        .isActionEnabled
                                }
                                type={sliderType}
                            />
                        </>
                    )
                }
            )}

            {assembly.PortConnections.D &&
                assembly.PortConnections.D.type == 'OLED' &&
                Object.keys(portData[37].oledState).map((key) => {
                    if (key == 4) {
                        return (
                            <SelectionRow
                                name={'OLED'}
                                port={'D'}
                                label={`OLED LINE ${key}`}
                                textValue={portData[37].oledState[key].value}
                                selected={portData[37].oledState[key].value}
                                assign={portData[37].oledState[key].isEnabled}
                                key={'OLED'}
                                handlecheckbox={() =>
                                    handleOLEDButtonClickExternal(key)
                                }
                                handleTextChange={(event) =>
                                    handleOLEDTextChangeExternal(
                                        key,
                                        event.target.value
                                    )
                                }
                            />
                        )
                    }
                    return (
                        <TextRow
                            name={'OLED'}
                            port={'D'}
                            label={`OLED LINE ${key}`}
                            textValue={portData[37].oledState[key].value}
                            assign={portData[37].oledState[key].isEnabled}
                            key={'OLED'}
                            handlecheckbox={() =>
                                handleOLEDButtonClickExternal(key)
                            }
                            handleTextChange={(event) =>
                                handleOLEDTextChangeExternal(
                                    key,
                                    event.target.value
                                )
                            }
                        />
                    )
                })}

            {/* /////////////////// */}
            {/* Internal Accesories */}
            {Object.entries(portData).map((port) => (
                <div key={port[0]}>
                    {!['0', '1'].includes(port[0]) &&
                        port[1].type === 'output' &&
                        port[1].isPortSelected &&
                        (port[1].device === null ||
                            port[1].device === undefined) && (
                            <SliderBox
                                id={port[0]}
                                btnText={decidePortName(port)}
                                sliderText="Value"
                                value={programData[check].state[port[0]].value}
                                min={getMin(port)}
                                max={getMax(port)}
                                handleSliderChange={handleSliderChange}
                                handleClick={handleSliderBtnClick}
                                handleExternalDeviceLiveBtnClick={
                                    handleExternalDeviceLiveBtnClick
                                }
                                isEnabled={
                                    programData[check].state[port[0]]
                                        .isActionEnabled
                                }
                                type={
                                    port[0] == 0 || port[0] == 1
                                        ? 'RGB'
                                        : 'single'
                                }
                            />
                        )}

                    {port[1].type === 'output' &&
                        port[1].isPortSelected &&
                        port[1].device === 'rgb' &&
                        port[0] == 33 && (
                            <>
                                {Object.entries(
                                    programData[check].state[33].rgbState
                                ).map((rgb) => (
                                    <>
                                        {rgb[1].show && (
                                            <SliderBox
                                                id={rgb[0]}
                                                btnText={`Port B - RGB ${rgb[0]}`}
                                                sliderText="Value"
                                                value={rgb[1].value}
                                                min={[0, 0, 0]}
                                                max={[255, 255, 255]}
                                                handleSliderChange={
                                                    handleHumanoidRGBSlider
                                                }
                                                handleClick={
                                                    handleHumanoidRGBButtonClick
                                                }
                                                isEnabled={rgb[1].isEnabled}
                                                type="RGB"
                                            />
                                        )}
                                    </>
                                ))}

                                <div style={humanoidRGBButtonGrp}>
                                    <div
                                        style={humanoidStyleAdd}
                                        onClick={humanoidAddRGB}
                                    ></div>
                                    <div
                                        style={humanoidStyleRemove}
                                        onClick={humanoidRemoveRGB}
                                    ></div>
                                </div>
                            </>
                        )}

                    {port[1].type === 'output' &&
                        port[1].isPortSelected &&
                        port[1].device === 'oled' &&
                        port[0] == 37 && (
                            <>
                                {Object.entries(port[1].oledState).map(
                                    (oled) => (
                                        <TextRow
                                            name={'OLED'}
                                            port={'D'}
                                            assign={
                                                programData[check].state[37]
                                                    .oledState[oled[0]]
                                                    .isEnabled
                                            }
                                            key={oled[0]}
                                            handlecheckbox={() =>
                                                handleOLEDButtonClick(oled[0])
                                            }
                                            textValue={
                                                programData[check].state[37]
                                                    .oledState[oled[0]].value
                                            }
                                            label={`OLED Line ${oled[0]}`}
                                            handleTextChange={(event) =>
                                                handleOLEDTextChange(
                                                    oled[0],
                                                    event.target.value
                                                )
                                            }
                                        />
                                    )
                                )}
                            </>
                        )}

                    {/* ------------------- humonoid & hexapod right eye ------------------- */}
                    {port[1].type === 'rgbEye' &&
                        portData['1'].isPortSelected &&
                        port[1].name.includes('Right') && (
                            <>
                                <SliderBox
                                    id={port[0]}
                                    btnText={decidePortName(port)}
                                    sliderText="Value"
                                    value={
                                        programData[check].state[port[0]].value
                                    }
                                    min={getMin(port)}
                                    max={getMax(port)}
                                    handleSliderChange={handleSliderChange}
                                    handleClick={handleSliderBtnClick}
                                    handleExternalDeviceLiveBtnClick={
                                        handleExternalDeviceLiveBtnClick
                                    }
                                    isEnabled={
                                        programData[check].state[port[0]]
                                            .isActionEnabled
                                    }
                                    type={'RGB'}
                                />
                            </>
                        )}

                    {/* ------------------- humonoid & hexapod left eye ------------------- */}
                    {port[1].type === 'rgbEye' &&
                        portData['0'].isPortSelected &&
                        port[1].name.includes('Left') && (
                            <>
                                <SliderBox
                                    id={port[0]}
                                    eyeId={port[0] - 76}
                                    btnText={decidePortName(port)}
                                    sliderText="Value"
                                    value={
                                        programData[check].state[port[0]].value
                                    }
                                    min={getMin(port)}
                                    max={getMax(port)}
                                    handleSliderChange={handleSliderChange}
                                    handleClick={handleSliderBtnClick}
                                    handleExternalDeviceLiveBtnClick={
                                        handleExternalDeviceLiveBtnClick
                                    }
                                    isEnabled={
                                        programData[check].state[port[0]]
                                            .isActionEnabled
                                    }
                                    type={'RGB'}
                                />
                            </>
                        )}
                    {/* If Individual Servo is selected in the internal accesories page */}
                    {port[0] == 5 &&
                        portData['4b'].isPortSelected &&
                        connectedDevice !== 'Klaw' && (
                            <SliderBox
                                key={4.5}
                                id={4.5}
                                btnText={portData[4.5].name}
                                sliderText="milliseconds"
                                value={programData[check].state[4.5].value}
                                min={portData[4.5].min}
                                max={portData[4.5].max}
                                handleSliderChange={handleSliderChange}
                                handleClick={handleSliderBtnClick}
                                handleExternalDeviceLiveBtnClick={
                                    handleExternalDeviceLiveBtnClick
                                }
                                isEnabled={
                                    programData[check].state[4.5]
                                        .isActionEnabled
                                }
                                type="single"
                            />
                        )}

                    {/* ------------------- Klaw all servo slider ------------------- */}
                    {['Klaw'].includes(connectedDevice) &&
                        port[1].type === 'pwm' &&
                        portData['4b'].isPortSelected && (
                            <div key={port[0]}>
                                <SliderBox
                                    key={port[0]}
                                    id={port[0]}
                                    servoId={port[0] - 5}
                                    btnText={port[1].name}
                                    sliderText="Value"
                                    value={
                                        programData[check].state[port[0]].value
                                    }
                                    min={portData[port[0]].min}
                                    max={portData[port[0]].max}
                                    handleSliderChange={handleSliderChange}
                                    handleClick={handleSliderBtnClick}
                                    handleExternalDeviceLiveBtnClick={
                                        handleExternalDeviceLiveBtnClick
                                    }
                                    isLiveBtnActive={findeCurrentPanelstate(
                                        logic.program,
                                        port[0]
                                    )}
                                    // liveServoFeedback={liveServoFeedback}
                                    // handelServoButtonClick={
                                    //     handelServoButtonClick
                                    // }
                                    isEnabled={
                                        programData[check].state[port[0]]
                                            .isActionEnabled
                                    }
                                    // isLiveSActive={
                                    //     programData[check].state[port[0]]
                                    //         .isLiveSActive
                                    // }
                                    type="single"
                                />
                            </div>
                        )}

                    {/* ------------------- humonoid all servo slider ------------------- */}
                    {['1', '2'].includes(deviceVersion[0]) &&
                    ['Humanoid'].includes(connectedDevice)
                        ? port[1].type === 'servo' &&
                          portData['4b'].isPortSelected && (
                              <SliderBox
                                  key={port[0]}
                                  id={port[0]}
                                  servoId={port[0] - 4}
                                  btnText={port[1].name}
                                  sliderText="Value"
                                  value={
                                      programData[check].state[port[0]].value
                                  }
                                  min={
                                      getMinRangeV1(
                                          port[1].min,
                                          port[1].max,
                                          port[0] - 4
                                      ).min
                                  }
                                  max={
                                      getMinRangeV1(
                                          port[1].min,
                                          port[1].max,
                                          port[0] - 4
                                      ).max
                                  }
                                  handleSliderChange={handleSliderChange}
                                  handleClick={handleSliderBtnClick}
                                  handleExternalDeviceLiveBtnClick={
                                      handleExternalDeviceLiveBtnClick
                                  }
                                  liveServoFeedback={liveServoFeedback}
                                  handelServoButtonClick={
                                      handelServoButtonClick
                                  }
                                  isEnabled={
                                      programData[check].state[port[0]]
                                          .isActionEnabled
                                  }
                                  isLiveSActive={
                                      programData[check].state[port[0]]
                                          .isLiveSActive
                                  }
                                  type="servo"
                              />
                          )
                        : port[1].type === 'servo' &&
                          portData['4b'].isPortSelected && (
                              <SliderBox
                                  key={port[0]}
                                  id={port[0]}
                                  servoId={port[0] - 4}
                                  btnText={port[1].name}
                                  sliderText="Value"
                                  value={
                                      programData[check].state[port[0]].value
                                  }
                                  min={port[1].min}
                                  max={port[1].max}
                                  handleSliderChange={handleSliderChange}
                                  handleClick={handleSliderBtnClick}
                                  handleExternalDeviceLiveBtnClick={
                                      handleExternalDeviceLiveBtnClick
                                  }
                                  liveServoFeedback={liveServoFeedback}
                                  handelServoButtonClick={
                                      handelServoButtonClick
                                  }
                                  isEnabled={
                                      programData[check].state[port[0]]
                                          .isActionEnabled
                                  }
                                  isLiveSActive={
                                      programData[check].state[port[0]]
                                          .isLiveSActive
                                  }
                                  type="servo"
                              />
                          )}
                </div>
            ))}

            {/* for start panel action map */}
            {Object.keys(startTypes).map((value, index) => {
                let startPanelKey = findestartPanelKey(value)
                if (value == 'btTx') {
                    if (startTypes[value] == true) {
                        return (
                            <>
                                {[
                                    'BtTx1',
                                    'BtTx2',
                                    'BtTx3',
                                    'BtTx4',
                                    'BtTx5',
                                ].map((ele) => {
                                    startPanelKey = findestartPanelKey(ele)
                                    return (
                                        <SliderBox
                                            btnText={
                                                programData[check].state[
                                                    startPanelKey
                                                ].name
                                            }
                                            sliderText="Value"
                                            id={startPanelKey}
                                            value={
                                                programData[check].state[
                                                    startPanelKey
                                                ].value
                                            }
                                            min={[
                                                PORTDATA[PORTDATAKey][
                                                    startPanelKey
                                                ].min,
                                            ]}
                                            max={[
                                                PORTDATA[PORTDATAKey][
                                                    startPanelKey
                                                ].max,
                                            ]}
                                            type="bttx"
                                            handleSliderChange={
                                                handleSliderChange
                                            }
                                            handleClick={handleSliderBtnClick}
                                            handleExternalDeviceLiveBtnClick={
                                                handleExternalDeviceLiveBtnClick
                                            }
                                            isEnabled={
                                                programData[check].state[
                                                    startPanelKey
                                                ].isActionEnabled
                                            }
                                            handelAssignButtonClick={
                                                handelAssignButtonClick
                                            }
                                            check={check}
                                        />
                                    )
                                })}
                            </>
                        )
                    }
                } else if (['bid1', 'bid2'].includes(value)) {
                    if (startTypes[value] == true) {
                        return (
                            <SliderBox
                                btnText={
                                    programData[check].state[startPanelKey].name
                                }
                                sliderText="Value"
                                id={startPanelKey}
                                value={
                                    programData[check].state[startPanelKey]
                                        .value
                                }
                                min={[PORTDATA[PORTDATAKey][startPanelKey].min]}
                                max={255} //[PORTDATA[PORTDATAKey][startPanelKey].max]
                                type="bid"
                                handleSliderChange={handleSliderChange}
                                handleClick={handleSliderBtnClick}
                                handleExternalDeviceLiveBtnClick={
                                    handleExternalDeviceLiveBtnClick
                                }
                                isEnabled={
                                    programData[check].state[startPanelKey]
                                        .isActionEnabled
                                }
                                handelAssignButtonClick={
                                    handelAssignButtonClick
                                }
                                check={check}
                                // dropDownValue={
                                //     logic.program[check].state[
                                //         PORTDATA[PORTDATAKey][id].reduxLogicName[1]
                                //     ]
                                // }
                                // dropDownValue={dropDownValue}
                                // setDropDownValue={setDropDownValue}
                            />
                        )
                    }
                } else if (
                    ['asgn1', 'asgn2', 'asgn3', 'asgn4', 'asgn5'].includes(
                        value
                    )
                ) {
                    if (startTypes[value] == true) {
                        return (
                            <SliderBox
                                btnText={
                                    programData[check].state[startPanelKey].name
                                }
                                sliderText="Value"
                                id={startPanelKey}
                                value={
                                    programData[check].state[startPanelKey]
                                        .value
                                }
                                min={[PORTDATA[PORTDATAKey][startPanelKey].min]}
                                max={[PORTDATA[PORTDATAKey][startPanelKey].max]}
                                type="asign"
                                handleSliderChange={handelDropDown}
                                handleClick={handleSliderBtnClick}
                                handleExternalDeviceLiveBtnClick={
                                    handleExternalDeviceLiveBtnClick
                                }
                                isEnabled={
                                    programData[check].state[startPanelKey]
                                        .isActionEnabled
                                }
                            />
                        )
                    }
                } else {
                    if (
                        startTypes[value] == true &&
                        !['btRx', 'btRte', 'usbrx'].includes(value)
                    ) {
                        let min = [PORTDATA[PORTDATAKey][startPanelKey].min]
                        let max = [PORTDATA[PORTDATAKey][startPanelKey].max]
                        if (['bic1', 'bic2'].includes(value)) {
                            min = -1
                            max = 1
                        }
                        return (
                            <SliderBox
                                btnText={
                                    programData[check].state[startPanelKey].name
                                }
                                sliderText="Value"
                                id={startPanelKey}
                                value={
                                    programData[check].state[startPanelKey]
                                        .value
                                }
                                min={min}
                                max={max}
                                type="single"
                                handleSliderChange={handleSliderChange}
                                handleClick={handleSliderBtnClick}
                                handleExternalDeviceLiveBtnClick={
                                    handleExternalDeviceLiveBtnClick
                                }
                                isEnabled={
                                    programData[check].state[startPanelKey]
                                        .isActionEnabled
                                }
                            />
                        )
                    }
                }
            })}

            {/* If Action Group is selected in the internal accesories page */}
            {connectedDevice != 'Klaw' && portData['4a'].isPortSelected && (
                <div className="action-contaniner">
                    {Object.entries(
                        GROUPACTIONS[`${connectedDevice.toUpperCase()}`]
                    )
                        .filter((action) => !['74', '75'].includes(action[0]))
                        .map((action) => (
                            <button
                                key={action[0]}
                                id={action[0]}
                                type="button"
                                className={
                                    programData[check].state['4a'].value ===
                                    action[0]
                                        ? 'enabledBtn'
                                        : 'disabledBtn'
                                }
                                onClick={handleAction}
                            >
                                {action[1].name}
                            </button>
                        ))}
                    <div className="dropdown-menu-wrapper">
                        <button
                            id="74"
                            className="action-button"
                            style={
                                programData[check].state['4a'].value
                                    .toString()
                                    .slice(0, 2) === '74'
                                    ? {
                                          background:
                                              'linear-gradient(to right, #feba45, #f97e61)',
                                          color: '#ffffff',
                                      }
                                    : {
                                          background:
                                              'linear-gradient(to right, #9f9f9f, #e3e3e3)',
                                          color: 'black',
                                      }
                            }
                            onClick={(event) => {
                                handleAction(event)

                                let actionValue =
                                    programData[check].state['4a'].value
                                if (actionValue.toString().includes('74')) {
                                    fetchActionSlotData()
                                }
                            }}
                        >
                            Custom Action
                        </button>

                        <button
                            id="75"
                            className={
                                programData[check].state['4a'].value
                                    .toString()
                                    .slice(0, 2) === '74' &&
                                programData[check].state['4a']
                                    .isRandomActionSelected
                                    ? 'enabledBtn'
                                    : 'disabledBtn'
                            }
                            onClick={(e) => {
                                if (
                                    programData[check].state['4a'].value
                                        .toString()
                                        .slice(0, 2) === '74'
                                ) {
                                    handleAction(e)
                                }
                            }}
                        >
                            Random Action
                        </button>

                        {showActionLoader && (
                            <div
                                style={{
                                    position: 'absolute',
                                    zIndex: '1',
                                    left: '50%',
                                }}
                            >
                                <ColorRing />
                            </div>
                        )}

                        <select
                            disabled={
                                !(
                                    programData[check].state['4a'].value
                                        .toString()
                                        .slice(0, 2) === '74'
                                ) ||
                                programData[check].state['4a']
                                    .isRandomActionSelected
                            }
                            style={{
                                textAlign: 'center',
                                outline: 'none',
                            }}
                            value={programData[check].state['4a'].value}
                            id="mySelection"
                            className="dropdown-menu-item-list"
                            onChange={handleActionSelect}
                        >
                            <option value={74}>Select an option</option>

                            {!(
                                (deviceVersion.startsWith('1') ||
                                    deviceVersion.startsWith('2')) &&
                                ['Humanoid', 'Hexapod'].includes(
                                    connectedDevice
                                )
                            ) &&
                                Object.entries(
                                    CUSTOMGROUPACTIONS[
                                        `${connectedDevice.toUpperCase()}`
                                    ]
                                ).map((action, index) => {
                                    if (zingSlotData[index] === 'T')
                                        return (
                                            <option
                                                key={action[0]}
                                                id={action[0]}
                                                value={action[0]}
                                            >
                                                {action[1].name}
                                            </option>
                                        )
                                })}
                            {['1', '2'].includes(deviceVersion[0]) &&
                                ['Humanoid', 'Hexapod'].includes(
                                    connectedDevice
                                ) &&
                                Object.entries(customActionList).map(
                                    (action, index) => {
                                        return (
                                            <option
                                                key={action[0]}
                                                id={action[0]}
                                                value={action[0]}
                                            >
                                                {action[1].name}
                                            </option>
                                        )
                                    }
                                )}
                            {/* {[1, 2].map(() => {
                                return <option>UUU</option>
                            })} */}
                        </select>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => {
    return state
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateLogic: (data) => {
            dispatch({ type: 'LOGIC_SELECTION', payload: data })
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionPanel)
