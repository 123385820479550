import renderImage from '../../../../source/importImg'
import SliderBox from '../../../ReusableComponents/SliderBox/SliderBox'
import servoPopupStyle from './ServoDetailsPopup.module.css'

function ServoDetailsPopup(props) {
    const {
        createActionState,
        servoDetailsPopUpObj,
        setServoDetailsPopUpObj,
        handleMinMax,
        showCloseBtn = true,
        handleSliderChange,
        handleSliderBtnClick,
    } = props
    const connectedDevice = sessionStorage.getItem('connectedDevice')
    // const createActionState = JSON.parse(
    //     sessionStorage.getItem('createActionState')
    // )
    const deviceVersion = sessionStorage.getItem('deviceVersion') || '0.0.0'
    const isZingVersion12 =
        ['1', '2'].includes(
            (sessionStorage.getItem('deviceVersion') || '0.0.0')[0]
        ) && ['Humanoid'].includes(sessionStorage.getItem('connectedDevice'))

    const handleDialogModalClose = () => {
        setServoDetailsPopUpObj({
            isShowsServoDetailsPopUp: false,
            servoId: 0,
            actionNumber: 0,
        })
    }

    //calculate the servo details popup Time in milisecond
    const calculateTime = (actionNumber) => {
        let times = 0
        let allActions = createActionState.allActions

        if (actionNumber >= allActions.length) {
            return 0
        }
        for (let i = 0; i <= actionNumber; i++) {
            times += allActions[i].data['T'].value
        }
        return times
    }

    // console.log(createActionState, servoDetailsPopUpObj.actionNumber)
    return (
        <>
            {servoDetailsPopUpObj.isShowsServoDetailsPopUp && (
                <div className={servoPopupStyle.servoPopupMain}>
                    <div className={servoPopupStyle.servoPopupBox}>
                        <img
                            className={servoPopupStyle.closeBtn}
                            style={
                                !showCloseBtn ? { visibility: 'hidden' } : null
                            }
                            src={renderImage('clos')}
                            onClick={handleDialogModalClose}
                        />
                        <div className={servoPopupStyle.servoPopupBody}>
                            <p className={servoPopupStyle.bodyPtag}>
                                Time (Milisecond) :{' '}
                                {calculateTime(
                                    servoDetailsPopUpObj.actionNumber
                                )}
                            </p>
                            <p className={servoPopupStyle.bodyPtag}>
                                Motor Name : {servoDetailsPopUpObj.servoId}
                            </p>
                            {/* <SliderBox
                                isDisabledAction={
                                    display !== 'block' || isEnableReadServo
                                }
                                key={servoId[0]}
                                id={`${servoId[0]}servo`}
                                btnText={`Head Servo`}
                                sliderText="Value"
                                value={
                                    createActionState.allActions[
                                        servoDetailsPopUpObj.actionNumber
                                    ].data[servoDetailsPopUpObj.servoId].value
                                }
                                min={0}
                                max={180}
                                handleSliderChange={handleSliderChange}
                                handleClick={handleSliderBtnClick}
                                isEnabled={
                                    true
                                    // createActionState.allActions[selectedAction].data[
                                    //     servoId[0]
                                    // ].isEnabled
                                }
                                type="single"
                            /> */}

                            <SliderBox
                                isDisabledAction={
                                    false
                                    // display !== 'block' || isEnableReadServo
                                }
                                // isDisabledAction={true}
                                key={servoDetailsPopUpObj.servoId}
                                id={servoDetailsPopUpObj.servoId}
                                btnText={`Servo Motor ${servoDetailsPopUpObj.servoId}`}
                                sliderText="Value"
                                value={
                                    createActionState.allActions[
                                        servoDetailsPopUpObj.actionNumber
                                    ].data[servoDetailsPopUpObj.servoId].value
                                }
                                min={
                                    connectedDevice === 'Hexapod'
                                        ? handleMinMax(
                                              servoDetailsPopUpObj.servoId,
                                              'MIN',
                                              'HEXAPOD'
                                          )
                                        : isZingVersion12
                                        ? handleMinMax(
                                              servoDetailsPopUpObj.servoId,
                                              'MIN',
                                              'ZING'
                                          )
                                        : 0
                                }
                                max={
                                    connectedDevice === 'Hexapod'
                                        ? handleMinMax(
                                              servoDetailsPopUpObj.servoId,
                                              'MAX',
                                              'HEXAPOD'
                                          )
                                        : isZingVersion12
                                        ? handleMinMax(
                                              servoDetailsPopUpObj.servoId,
                                              'MAX',
                                              'ZING'
                                          )
                                        : 1000
                                }
                                handleSliderChange={handleSliderChange}
                                handleClick={(servoId) =>
                                    handleSliderBtnClick(
                                        servoId,
                                        servoDetailsPopUpObj.actionNumber
                                    )
                                }
                                isEnabled={
                                    createActionState.allActions[
                                        servoDetailsPopUpObj.actionNumber
                                    ].data[servoDetailsPopUpObj.servoId]
                                        .isEnabled
                                }
                                type="single"
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
export default ServoDetailsPopup
